<template>

    <form id="formRegister" v-if="!loaded" @submit="registerUser">

        <div class="alert-register" v-if="this.errorsForm.back">
            <span class="alert-register">{{this.errorsForm.back}}</span>
        </div>

        <h3 class="is-required"> Nombre </h3>
        <input v-model="formData.first_name" class="inputsRegister" type="text" name="first_name" id="first_name" autocomplete="off"/>

        <div class="alert-register" v-if="this.errorsForm.first_name">
            <span class="alert-register">{{ this.errorsForm.first_name }}</span>
        </div>

        <h3 class="is-required"> Apellidos </h3>
        <input v-model="formData.last_name" class="inputsRegister" type="text" name="last_name" id="last_name" autocomplete="off"/>

        <div class="alert-register" v-if="this.errorsForm.last_name">
            <span class="alert-register">{{ this.errorsForm.last_name }}</span>
        </div>

        <h3 class="is-required"> Email </h3>
        <input v-model="formData.email" class="inputsRegister" type="text" name="email" id="email" autocomplete="off"/>

        <div class="alert-register" v-if="this.errorsForm.email">
            <span class="alert-register">{{ this.errorsForm.email }}</span>
        </div>

        <h3 class="is-required"> Empresa </h3>
        <select class="minimal" v-model="formData.company" name="company" id="company" @change="onCompanyChange">
            <option v-for="com in this.companies" v-bind:key="com">{{com.company_name}}</option>
        </select>

        <div class="alert-register" v-if="this.errorsForm.company">
            <span class="alert-register">{{ this.errorsForm.company }}</span>
        </div>

        <h3 class="is-required"> Departamento </h3>
        <select class="minimal" v-model="formData.department" name="department" id="department" @change="onDepartmentChange">
            <option v-for="dep in this.departments" v-bind:key="dep">{{dep.dept_name}}</option>
        </select>

        <div class="alert-register" v-if="this.errorsForm.department">
            <span class="alert-register">{{ this.errorsForm.department }}</span>
        </div>

        <h3 class="is-required"> Responsables </h3>
        <SearcherComponent v-bind:dep-selected="depSelected" @save-managers="getManagersSelected" />

      <h3> ¿El usuario va a trabajar en remoto? </h3>

      <div class="advert-remote">
        <p>El usuario será asignado a: <strong style="color: #ed3f7c;">{{ this.isRemote ? 'REMOTO' : 'OFICINAS' }}</strong></p>
      </div>
      <div class="checks">
      <label class="container remote"> Remoto
        <input type="checkbox" id="isRemote" v-model="isRemote">
        <span class="checkmark"></span>
      </label>
      </div>

        <div class="alert-register" v-if="this.errorsForm.manager">
            <span class="alert-register">{{ this.errorsForm.manager }}</span>
        </div>

        <h3> Tipo de rol que va a tener el usuario </h3>

          <div class="checks">

            <label class="container"> Usuario
                <input type="checkbox" id="checkUser" value="check_user" checked="true" disabled="true">
                <span class="checkmark"></span>
            </label>

            <label class="container"> Responsable
                <input type="checkbox" id="checkManager" value="check_manager" @change="checkManager($event)">
                <span class="checkmark"></span>
            </label>

            <label class="container"> Administrador
                <input type="checkbox" id="checkAdmin" value="check_admin" @change="checkAdmin($event)">
                <span class="checkmark"></span>
            </label>

        </div>

        <div class="select-manager" v-if="selectingManager">

            <div class="form-inline">

                <div class="alert-register">
                    <span v-if="departmentEmpty" class="alert"> Vas a designar a <u>{{ this.formData.first_name === null || this.formData.first_name === "" ? 'X' : this.formData.first_name }}</u> como responsable de un departamento. </span>
                    <span v-if="!departmentEmpty" class="alert"> Este departamento tiene ya designado a 
                        <u>{{ this.depManagerName[0].name }}</u> 
                        como responsable. Elimina su rol en el <router-link class="listLink" to="/admin-panel/list-nodrizers">listado de NODRIZERS</router-link> 
                        y vuelve a intentarlo. 
                    </span>
                </div>

                <h3 class="is-required"> Empresa del responsable </h3>
                <select class="minimal" v-model="formData.company_manager" name="company_manager" id="company_manager" @change="onCompanyManagerChange">
                    <option v-for="com in this.companies_manager" v-bind:key="com">{{com.company_name}}</option>
                </select>

                <div class="alert-register" v-if="this.errorsForm.company_manager">
                    <span class="alert-register">{{ this.errorsForm.company_manager }}</span>
                </div>

                <h3 class="is-required"> Departamento del responsable </h3>
                <select class="minimal" v-model="formData.department_manager" name="department_manager" id="department_manager" @change="onDepartmentManagerChange">
                    <option v-for="dep in this.departments_manager" v-bind:key="dep">{{dep.dept_name}}</option>
                </select>

                <div class="alert-register" v-if="this.errorsForm.department_manager">
                    <span class="alert-register">{{ this.errorsForm.department_manager }}</span>
                </div>

                <div class="alert-register" v-if="this.errorsForm.not_empty_department">
                    <span class="alert-register">{{ this.errorsForm.not_empty_department }}</span>
                </div>

            </div>

        </div>

    </form>

</template>

<script>

    import axios from '../../services/storage/axios'
    import LoaderComponent from "@/components/LoadersComponents/LoaderComponent.vue"
    import SearcherComponent from "@/components/AdminComponents/SearcherComponent.vue"

    export default {
        props: {
            clicked: null
        },
        components: {
            LoaderComponent,
            SearcherComponent
        },
        emits: ['sendFormData'],
        watch: {
            clicked () {

                this.errorsForm.first_name = this.formData.first_name === null || this.formData.first_name === "" ? "Es obligatorio introducir un nombre" : ""
                this.errorsForm.last_name = this.formData.last_name === null || this.formData.last_name === "" ? "Es obligatorio introducir apellidos" : ""
                this.errorsForm.email = this.formData.email === null || this.formData.email === "" ? "Es obligatorio introducir un email" : ""
                this.errorsForm.company = this.formData.company === null ? "Es obligatorio asignar una empresa" : ""
                this.errorsForm.department = this.formData.department === null ? "Es obligatorio asignar un departamento" : ""
                this.errorsForm.manager = this.formData.managers === null || Object.values(this.formData.managers).length === 0 ? "Es obligatorio asignar mínimo un responsable" : ""
                this.errorsForm.not_empty_department = !this.departmentEmpty ? "No puedes registrar un responsable de un departamento que ya tiene asignado un responsable" : ""
                
                if (this.selectingManager) {

                    this.errorsForm.company_manager = this.formData.company_manager === null ? "Es obligatorio asignar una empresa" : "" 
                    this.errorsForm.department_manager = this.formData.department_manager === null ? "Es obligatorio asignar un departamento" : ""               
                }

                this.emitFormSend()
            }
        },
        data() {
            return {
                formData: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    managers: null,
                    company: null,
                    company_manager: null,
                    department: null,
                    department_manager: null,
                    isRemote: null,
                    roles: [
                        'ROLE_USER'
                    ]
                },
                errorsForm: {
                    'first_name': null,
                    'last_name': null,
                    'email': null,
                    'manager': null,
                    'company': null,
                    'department': null,
                    'company_manager': null,
                    'department_manager': null,
                    'back': null,
                    'not_empty_department': null,
                },
                isRemote: false,
                companies: null,
                companies_manager: null,
                departmentEmpty: true,
                all_departments: null,
                depSelected: null,
                all_managers: null,
                depManagerSelected: null,
                depManagerName: null,
                departments: null,
                departments_manager: null,
                loaded: false,
                selectingManager: false,
                registerCorrect: false
            }
        },
        methods: {

            emitFormSend() {

                this.formData.errors = this.errorsForm
                this.formData.selectingManager = this.selectingManager
                this.formData.isRemote = this.isRemote
            
                if (this.clicked)
                    this.$emit('sendFormData', this.formData)
            },

            async getCompanies() {

                const options = {
                    method: 'GET',
                    url: '/admin/admin-data',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

                axios(options)
                .then((response) => {

                    if (response.status == 200) {

                        this.companies = response.data.data.companies
                        this.companies_manager = response.data.data.companies
                        this.all_departments = response.data.data.departments
                        this.all_managers = response.data.data.managers
                    }

                }, (error) => {

                    console.log(error)
                })
            },

            onCompanyChange() {

                this.formData.department = null

                let companySelected = this.companies.filter(comp => comp.company_name === this.formData.company)
                this.getDepartments('standard', companySelected[0])
            },

            onCompanyManagerChange() {

                this.formData.department_manager = null

                let companySelected = this.companies.filter(comp => comp.company_name === this.formData.company_manager)
                this.getDepartments('manager', companySelected[0])
            },

            async getDepartments(mode, companySelected) {

                if (mode === 'standard')

                    this.departments = this.all_departments.filter(dep => dep.company.company_id === companySelected.company_id)

                else if (mode === 'manager')

                    this.departments_manager = this.all_departments.filter(dep => dep.company.company_id === companySelected.company_id)
            },

            onDepartmentChange() {

                this.depSelected = this.departments.filter(dep => dep.dept_name === this.formData.department)

                if (this.formData.department != null)
                    this.depSelected = this.depSelected[0]
            },

            onDepartmentManagerChange() {

                this.depManagerSelected = this.departments_manager.filter(dep => dep.dept_name === this.formData.department_manager)

                // check if manager of department is null

                if (this.depManagerSelected[0].dept_manager != null) {

                    this.departmentEmpty = false

                    this.depManagerName = this.all_managers.filter(mang => mang.id === this.depManagerSelected[0].dept_manager)
                }   
                    
                else 
                    this.departmentEmpty = true
            },

            checkManager(e) {

                if (e.target.checked) {

                    this.formData.roles.push('ROLE_MANAGER')
                    this.selectingManager = true

                } else {

                    this.formData.roles.splice(this.formData.roles.indexOf('ROLE_MANAGER'), 1)
                    this.formData.company_manager = null
                    this.formData.department_manager = null
                    this.departmentEmpty = true
                    this.selectingManager = false
                }
            },

            checkAdmin(e) {

                e.target.checked 
                ? this.formData.roles.push('ROLE_ADMIN') 
                : this.formData.roles.splice(this.formData.roles.indexOf('ROLE_ADMIN'), 1)
            },

            getManagersSelected(managers) {

                this.formData.managers = managers
            }
        },

        created() {

            this.getCompanies()
        }
    }
</script>

<style scoped>

    a.listLink {
        color: rgb(82, 0, 0);
        text-decoration: underline;
    }

    a.listLink:hover {
        text-decoration: none;
    }

    div.alert-register {
        color: rgb(255, 255, 255);
        background-color: #ff6c61;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 40px;
        margin-right: 40px;
        padding: 15px;
        text-align: left;
        width: 92%;
    }

    div.alert-registered {
        color: rgb(255, 255, 255);
        background-color: #ff3c80;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 40px;
        padding: 20px;
    }

    div.loaderRegister {
        position: relative;
        top: 55%;
        transform: translate(-67%, -50%) scale(1.5);
    }

    h3, label {
        margin: 5px 5px 10px 0;
        text-align: left;
        white-space: nowrap;
    }

    input.inputsRegister {
        border-radius: 5px;
        border-width: thin;
        cursor: default;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 400;
        height: 36px;
        margin-bottom: 15px;
        margin-top: 10px;
        outline: none;
        overflow-y: auto;
        overflow-x: hidden;
        padding-inline-start: 20px;
        text-overflow: ellipsis;
        width: 95%;
    }

    input.inputsRegister:hover {
        border-color: #ed3f7c;
        border-width: thin;
        cursor: text;
    }

    input.inputsRegister::selection {
        background-color: #fcc1db;
    }

    form#formRegister {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 35%;
        margin-right: 35%;
        width: 30%;
    }

    select.minimal {
        appearance: none;
        background-color: #FFFFFF;
        border-radius: 5px;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        height: 50px;
        margin-bottom: 25px;
        margin-top: 10px;
        outline: none;
        text-indent: 10px;
        width: 100%;
    }

    select:hover, input:hover, textarea:hover {
        border-color: #ED3F7C;
        cursor: pointer;
    }

    /*minimal*/

    select.minimal {
        background-image:
            linear-gradient(45deg, transparent 50%, #f92871 50%),
            linear-gradient(135deg, #f92871 51%, transparent 50%),
            linear-gradient(to right, #dfdedf, #dfdedf);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

    span.alert-register {
        font-size: 18px;
    }

    /* checkboxes */
    
    .checks {
        margin-left: 12px;
        margin-top: 30px;
    }

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        text-align: left;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: #ED3F7C;
    }

    .container input:disabled ~ .checkmark {
        background-color: #fde8f0;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }


    /*classes*/

    .is-required:after {
        color: #ff0000;
        content: "*";
        font-weight: bold;
        margin-left: 3px;
    }

    .select-manager {
        margin-top: 50px;
    }

    .advert-remote {
      background-color: #ffd8e6;
      border-radius: 5px;
      margin-top: 20px;
      margin-right: 20px;
      padding: 2px 5px 2px 5px;
    }

    .container.remote {
      margin-bottom: 40px;
    }
</style>