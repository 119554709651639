<template>

  <div class="body">
    <div class="message">

<!--      <LoaderComponent class="loader"/>-->


      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>

    <div class="motivater">
      <MotivaterShipComponent class="rocket"/>
    </div>

  </div>

</template>

<script>
import MotivaterShipComponent from "@/components/GeneralComponents/MotivaterShipComponent";
import LoaderComponent from "@/components/LoadersComponents/LoaderComponent";

export default {
  name: "NoPageFound",
  components: {
    LoaderComponent,
    MotivaterShipComponent,
  }
}
</script>

<style scoped>

.rocket {
  transform: scale(0.75);

}

.body {
  min-height: 90%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-75%, -75%) scale(4.0);
}

</style>