<template>

  <div class="container">

    <h4>Cultura de empresa</h4>

    <ul>
      <li class="thanks">
        <router-link to="/send?type=gracias">Ticket <strong>Gracias</strong></router-link>
      </li>
      <li class="sc1">
        <router-link to="/send?type=sc1">Ticket <strong>SC1</strong> (Tus <strong>aciertos</strong>)</router-link>
      </li>
      <li class="sc2">
        <router-link to="/send?type=sc2">Ticket <strong>SC2</strong> (Tus <strong>cagadas</strong>)</router-link>
      </li>
      <li class="imi">
        <router-link to="/send?type=pulpoIMI">Ticket <strong>Pulpo IMI</strong></router-link>
      </li>
      <li class="lean">
        <router-link to="/send?type=lean">Ticket <strong>Lean</strong></router-link>
      </li>
      <li class="sd">
        <router-link to="/send?type=smartDecision">Ticket <strong>Smart Decision</strong></router-link>
      </li>
      <li class="fourE">
        <router-link to="/send?type=4e">Ticket <strong>4E</strong></router-link>
      </li>
      <li class="form">
        <router-link to="/send?type=autoformacion">Ticket <strong>Autoformación</strong></router-link>
      </li>
      <li class="sug">
        <router-link to="/send?type=sugerencia">Ticket <strong>Sugerencia</strong></router-link>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "CulturaEmpresaComponent"
}
</script>

<style scoped lang="scss">

  @import '@/styles/HomepageStyles/ColumnsHomepage.scss';

  ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }

  li {
    padding-left: 2em;
    text-indent: -1.5em;
  }

  li.thanks:before{
    content: "\01F917" " ";
  }

  li.sc1:before{
    content: "\01F4AA" " ";
  }

  li.sc2:before{
    content: "\01F4A9" " ";
  }

  li.imi:before{
    content: "\01F419" " ";
  }

  li.lean:before{
    content: "\23F1 \FE0F" " ";
  }

  li.sd:before{
    content: "\01F4A1" " ";
  }

  li.fourE:before{
    content: "\01F918" " ";
  }

  li.form:before{
    content: "\01F4D6" " ";
  }

  li.sug:before {
    content: "\01F50E" " ";
  }

</style>