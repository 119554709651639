<template>
  <button v-bind:class="{ticket: this.render === 'ticket', order: this.render === 'order'}" class="redirect-button" tabindex="-1">
    <span class="default-btn">
      <span class="default-span">{{this.text}}</span>
    </span>
    <span class="hover-btn">
      <svg class="hover-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
    </span>
  </button>
</template>

<script>
export default {
  name: "RedirectButtonComponent",
  props: {
    text: String,
    render: String
  },
  data() {
    return {
      renders: ['ticket', 'order']
    }
  }
}
</script>

<style scoped>

.nodriza-table .vgt-table.polar-bear td span.default-btn {
  position: absolute;
  top:5px;
}
.nodriza-table .vgt-table.polar-bear td span.hover-btn {
  position: absolute;
}

.redirect-button {
  position: relative;
  overflow: hidden;
  outline: none;
  font-family:'Montserrat', sans-serif;
  color: #ffffff;
  border: none;
  letter-spacing: 0.05em;
  border-radius: 10px;
  width: 140px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  width: 120px;
  text-indent: 12px;
}

.redirect-button.ticket {
  background: rgba(238, 85, 139, 0.795);
}

.redirect-button.order {
  background: rgba(179, 147, 159, 0.872);
}

.default-btn, .hover-btn {
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  transition: all .5s ease;
  display: inline-flex;
  justify-content: center;
}

.hover-btn {
  align-items: center;
  inset: 0;
  transform: translate(0%,100%);
}

.default-btn .hover-btn span {
  color: #ffffff;
}

button:hover .default-btn {
  transform: translate(0%,-120%);
}

button:hover .hover-btn {
  transform: translate(0%,0%);
}

.svg{
  stroke-linejoin: round;
  stroke-linecap: round;
  fill: none;
  stroke-width: 3px;
  stroke: #ffffff;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-right: 0;
}

.hover-svg{
  fill: #ffffff;
  height: 20px;
  width: 25px;
}

@media (max-width: 1439px){
  .svg{
      width: 0;
  }
  button{
    width: 85px;
  }

  .default-btn{
   font-size: 12px;
    padding-right: 10px;
  }
}

@media( min-width: 1440px){
  button{
   width: 100px;
  }

  .svg{
    width: 0;
  }

  .default-btn{
    font-size: 14px;
    padding-right: 10px;
  }
}

@media( min-width: 1600px){
  button{
    width: 120px;
  }

  .svg{
    width: 16px;
  }

  .default-btn{
    font-size: 14px;
  }
}

</style>


