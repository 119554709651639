<template>

  <div class="container">

  <h4>Para ti, líder</h4>

  <ul class="colum-list">
    <li class="column-line" v-if="this.roles.includes('ROLE_MANAGER')">
      <router-link to="/leader/tickets?view=team">Consultar <strong>tickets de tu equipo</strong></router-link>
    </li>
    <li class="column-line" v-if="this.roles.includes('ROLE_MANAGER')">
      <router-link to="/leader/tickets?view=validate"><strong>Validación</strong> de tickets</router-link>
    </li>
    <li class="column-line" v-if="this.roles.includes('ROLE_ADMIN')">
      <router-link to="/admin/tickets">Consultar <strong>tickets de NODRIZERS</strong></router-link>
    </li>
    <li class="column-line" v-if="this.roles.includes('ROLE_ADMIN')">
      <router-link to="/admin-panel">Panel de <strong>Administrador</strong></router-link>
    </li>

  </ul>

  </div>

</template>

<script>

import LocalStorageService from "@/services/storage/LocalStorageService"

export default {

  name: "LiderComponent",
  data() {
    return {
      roles: LocalStorageService.getService().getUserRoles()
    }
  }
}
</script>

<style scoped lang="scss">

  @import '@/styles/HomepageStyles/ColumnsHomepage.scss';

</style>