<template>
    <div class="confirmation-button" @mouseenter="resetTimer" @mouseleave="cancelConfirmation">
        <transition name="fade"
                    mode="out-in"
                    v-if="showConfirmationBody === false">
            <slot name="button" :next="toggleConfirmation">
                <button class="btn--primary btn--small prevent-selected"
                        @click="toggleConfirmation">
                    {{ $t('buttons.delete') }}
                </button>
            </slot>
        </transition>
        <transition name="fade"
                    mode="out-in"
                    v-if="showConfirmationBody === true">
            <div class="confirmation-button__body">
                <span class="confirmation-button__body__element confirmation-button__body__element__msg">
                    {{ $t('common.areYouSure') }}
                </span>
                <button class="btn--default btn--small confirmation-button__body__element prevent-selected"
                        @click="toggleConfirmation">{{ $t('buttons.tableCancel') }}</button>
                <button class="btn--admin btn--small confirmation-button__body__element prevent-selected"
                        @click="sendConfirmation">{{ $t('buttons.tableConfirm') }}</button>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "NodrizaTableConfirmationButton",
    data() {
        return {
            showConfirmationBody: false,
            cancelTimer: false
        }
    },
    methods: {
        toggleConfirmation() {
            this.showConfirmationBody = !this.showConfirmationBody;
            this.$emit('showConfirmation', this.showConfirmationBody);
        },
        cancelConfirmation() {
            this.cancelTimer = setTimeout(() => {
                this.showConfirmationBody = false;
                this.$emit('showConfirmation', this.showConfirmationBody);
            }, 1000);
        },
        resetTimer() {
            if (this.cancelTimer) {
                clearTimeout(this.cancelTimer);
            }
        },
        sendConfirmation() {
            this.$emit('confirmed');
            this.toggleConfirmation();
        }
    }
}
</script>

<style lang="scss">
.nodriza-table {
    .confirmation-button {
        &__body {
            background-color: #f2f2f2;
            &__element {
                &__msg {
                    padding-right: 1em;
                }
            }
        }
    }
    .vgt-table.polar-bear {
        & td {
            .confirmation-button {
                span {
                    position: initial;
                }
            }
        }
    }
}
</style>
