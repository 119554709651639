<template>

    <router-link class="returnLink" to="/"> &lt; Volver </router-link>

    <div class="containerTitle">
      <h1 v-if="this.type === 'SC1'">Cultura de Empresa - Ticket {{ type }} &#128170; </h1>
      <h1 v-if="this.type === 'SC2'">Cultura de Empresa - Ticket {{ type }} 	&#128169; </h1>
      <h1 v-if="this.type === 'Pulpo IMI'">Cultura de Empresa - Ticket {{ type }} &#128025; </h1>
      <h1 v-if="this.type === 'Lean'">Cultura de Empresa - Ticket {{ type }} &#9201;&#65039; </h1>
      <h1 v-if="this.type === 'Smart Decision'">Cultura de Empresa - Ticket {{ type }} &#128161; </h1>
      <h1 v-if="this.type === '4E'">Cultura de Empresa - Ticket {{ type }} &#129304; </h1>
      <h1 v-if="this.type === 'Autoformación'">Cultura de Empresa - Ticket {{ type }} &#128214; </h1>
      <h1 v-if="this.type === 'Gracias'">Cultura de Empresa - Ticket {{ type }} &#129303; </h1>
      <h1 v-if="this.type === 'Ahorro de Costes'">Cultura de Empresa - Ticket {{ type }} &#128201; </h1>
      <h1 v-if="this.type === 'Aumento de Facturación'">Cultura de Empresa - Ticket Aumento de Facturación &#128200; </h1>
      <h1 v-if="this.type === 'Crosselling'">Cultura de Empresa - Ticket Cross-selling &#128279; </h1>
      <h1 v-if="this.type === 'Upselling'">Cultura de Empresa - Ticket Up-selling &#129513; </h1>
      <h1 v-if="this.type === 'Sugerencia'">Cultura de Empresa - Ticket Sugerencia &#128270; </h1>
    </div>

    <div class="loader" v-if="loaded">
      <LoaderComponent />
    </div>

    <div v-if="!loaded" id="section">

      <div class="alertSend" v-if="this.errors.back && this.cultura">
        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
        <strong>{{ this.errors.back }}</strong>
      </div>

      <div v-if="!this.errorSession" v-bind:class="{containerSend: this.cultura, containerThSug: !this.cultura}">

        <form v-bind:class="{colSend: this.cultura, colThSug: !this.cultura}" @submit="sendTicket">

          <div class="alertSendNoCult" v-if="this.errors.back && !this.cultura">
            <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
            <strong>{{ this.errors.back }}</strong>
          </div>

          <div id="formSend">

            <div v-if="gracias">
              <GraciasComponent @emailchanged="getDataGracias"/>
            </div>

            <div v-if="sugerencia">
              <SelectSugerencia @datachanged="getDataSubtype" />
            </div>

            <div v-if="sugerencia">
              <PremioSugerenciaComponent @datachanged="getDataSugerencia" />
            </div>

            <div class="error" id="err-comp" v-if="this.errors.company">
              <p>{{ this.errors.company }}</p>
            </div>

            <div class="error" v-if="this.errors.thanks_oblg">
              <p>{{ this.errors.thanks_oblg }}</p>
            </div>

            <div class="error" v-if="this.errors.thanks">
              <p>{{ this.errors.thanks }}</p>
            </div>

            <h3 class="is-required" v-bind:class="{cultura: this.cultura,'no-cultura': !this.cultura}">Título</h3>

            <input v-model="title" :disabled="!loaded_saved" v-bind:class="{cultura: this.cultura,'no-cultura': !this.cultura}" type="text" name="title" id="ticket_title" placeholder="Escribe aquí el título de tu ticket..."/>

            <div v-bind:class="{ error: this.cultura, 'error-sug': !this.cultura }" v-if="this.errors.title">
              <p>{{ this.errors.title }}</p>
            </div>

            <h3 class="is-required" v-bind:class="{cultura: this.cultura,'no-cultura': !this.cultura}">Descripción</h3>

            <div v-bind:class="{ error: this.cultura, 'error-sug': !this.cultura }" v-if="this.errors.desc">
              <p>{{ this.errors.desc }}</p>
            </div>

            <DescriptionComponent
              @text-change="setDescription"
              v-bind:placeholder="this.placeholder" 
              v-bind:loaded_saved="this.loaded_saved"/>

            <button type="submit" v-bind:class="{gracias: this.gracias || this.sugerencia}" tabindex="-1">
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" fill="currentColor"></path>
              </svg>
              <span>Enviar ticket</span>
            </button>
            
          </div>

        </form>

        <div v-bind:class="{colSend: this.cultura, colThSug: !this.cultura}">
          <div v-if="cultura" id="ticketTemplate">
            <p class="template">📌 <strong>Plantilla:</strong> ¿Cómo crear un {{ ticketType }} perfecto?</p>
            <div v-if="loadedEllipsis" class="loaderEllipsis">
              <LoaderEllipsisComponent />
            </div>
            <div class="template" id="bodyTemplate"/>
          </div>
        </div>
        
      </div>

    </div>
  
</template>

<script>
import LoaderComponent from "../../components/LoadersComponents/LoaderComponent.vue";
import LoaderEllipsisComponent from "../../components/LoadersComponents/LoaderEllipsisComponent.vue";
import PremioSugerenciaComponent from "../../components/SendTicketComponents/PremioSugerenciaComponent.vue";
import GraciasComponent from "../../components/SendTicketComponents/GraciasComponent.vue";
import LocalStorageService from "../../services/storage/LocalStorageService";
import DescriptionComponent from "../../components/SendTicketComponents/DescriptionComponent.vue";
import SelectSugerencia from "../../components/SendTicketComponents/SelectSugerencia.vue";

import axios from "../../services/storage/axios";
import { ticketTypesEnum } from '@/AppNT/enum/TicketTypesEnum';


export default {
  props: {
    ticketType: {
      type: String,
      required: true,
    },
  },
  components: {
    LoaderComponent,
    LoaderEllipsisComponent,
    PremioSugerenciaComponent,
    GraciasComponent,
    DescriptionComponent,
    SelectSugerencia
  },
  data() {
    return {
      errors: {
        title: "",
        desc: "",
        back: "",
        thanks: "",
        company: "",
        department: "",
        thanks_oblg: "",
      },
      errorSession: false,
      isMounted: false,
      placeholder: 'Escribe aquí la descripción de tu ticket...',
      email: "",
      title: "",
      description: "",
      template: "",
      notification: "",
      company: "",
      department: "",
      thanks_to: "",
      sugerencia: false,
      autoformacion: false,
      gracias: false,
      cultura: true,
      type: null,
      loaded: false,
      loadedEllipsis: false,
      localStorage: null,
      loaded_saved: false
    };
  },

  async created() {

    this.localStorage = LocalStorageService.getService();
    this.type = this.ticketType;

    if (this.ticketType === "notFound") {

      this.$router.push("/notFound");

    } else {

      switch (this.ticketType) {

        case 'Sugerencia':
          this.sugerencia = true;
          this.cultura = false;
          await this.getCompanies();
          break;

        case 'Autoformación': 
          this.autoformacion = true;
          this.cultura = false;
          break;

        case 'Gracias':
          this.gracias = true;
          this.cultura = false;
          break;
      }

      if (this.cultura) {
        await this.getTemplate();
      }
    }
  },

  mounted() {
    if (this.autoformacion || this.gracias) {
      setTimeout(() => {this.setSavedTicketData()}, 200);
    }
  },

  methods: {
    sendTicket(e) {

      this.errors.title = "";
      this.errors.desc = "";
      this.errors.back = "";
      this.errors.thanks = "";
      this.errors.company = "";
      this.errors.thanks_oblg = "";

      this.title = this.title.trim();
      this.description = this.description.trim();

      // Check possible errors:
      this.email = this.localStorage.getUserEmail();
      this.errors.title = this.title.length === 0 ? "Es obligatorio poner un título" : "";

      // Check empty description

      let empty = this.description.replace('<p></p>', "");
      
      this.errors.desc = empty.length === 0 ? "Es obligatorio poner una descripción" : "";
      this.errors.thanks = this.thanks_to === this.email ? "¡No puedes darte las gracias a ti mismo!" : "";
      this.errors.company = this.company === "" && this.sugerencia === true ? "Es obligatorio poner una empresa" : "";
      this.errors.department = this.department === "" && this.sugerencia === true ? "Es obligatorio poner un departamento" : "";
      this.errors.thanks_oblg = this.thanks_to === "" && this.gracias === true ? "Es obligatorio dar las gracias a un compañero" : "";

      if ( this.errors.title !== "" || this.errors.desc !== "" || this.errors.thanks !== "" || this.errors.company !== ""
            || this.errors.thanks_oblg !== "") {

        e.preventDefault();

      } else {

        e.preventDefault();
        this.sendReq();
      }

    },
    async sendReq() {

      this.description = this.description.replace(/(?:\r\n|\r|\n)/g, '<br/>')

      const options = {
        method: "POST",
        url: "/send",
        data: {
          type: this.type,
          title: this.title,
          description: this.description,
          thanks_to: this.thanks_to,
          company: this.company,
          department: this.department
        },
      }

      this.loaded = true;

      const response = await axios(options);

      if (!response.errorMessage) {

        this.notification = response.data.message;
        this.$router.push(`/received?type=${this.type}&notification=${this.notification}`);
        this.localStorage.clearOneTicketDraft(Object.keys(ticketTypesEnum).find(key => ticketTypesEnum[key] === this.type));

      } else {

        if (response.response.status != 401)
          this.errors.back = "Ha ocurrido un error al enviar tu ticket. Inténtalo más tarde.";
        else {
          this.errorSession = true;
          this.errors.back = response.errorMessage;
        }
      }

      this.loaded = false;
    },

    async getTemplate() {

      this.loadedEllipsis = true;

      const options = {
        method: "GET",
        url: `/template/${this.ticketType}`
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.setSavedTicketData();
        document.getElementById("bodyTemplate").innerHTML = "";
        this.template = response.data.template;
        this.loadedEllipsis = false;

      } else {
        this.template = response.errorMessage;
      }

      document.getElementById("bodyTemplate").innerHTML += this.template;
      this.loadedEllipsis = false;
    },

    setSavedTicketData() {
      const ticket_saved = this.localStorage.getOneTicketDraft(Object.keys(ticketTypesEnum).find(key => ticketTypesEnum[key] === this.type));

      if (ticket_saved != null) {
        this.title = ticket_saved.title;
        this.description = ticket_saved.description;

        document.getElementById("ticket_title").innerHTML = this.title;
        const description = document.getElementsByClassName("ProseMirror");

        [...description].forEach(item => {
          item.innerHTML = this.description;
        });
      }

      if (this.sugerencia && ticket_saved === null) {
        this.title = '';
        this.description = '';
        this.loaded_saved = true;
      }

      this.loaded_saved = true;
    },

    async getCompanies() {

      const options = {
        method: "GET",
        url: "/companies"
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.setSavedTicketData();
        this.companies = response.data.companies;

      } else {

        this.errors.back = response.errorMessage;
      }

    },

    async getDepartments() {

      const options = {
        method: "GET",
        url: `/departments/${this.company}`
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.departments = response.data.departments;

      } else {

        this.errors.back = response.errorMessage;
      }

    },

    getDataSugerencia(e) {
      if (e.company) {
        this.company = e.company
      } else if (e.department) {
        this.department = e.department
      }
    },

    getDataGracias(e) {
      this.thanks_to = e.email;
      this.email = this.localStorage.getUserEmail();

      if (this.thanks_to === this.email) {
        this.errors.thanks = "¡No puedes darte las gracias a ti mismo!";
      } else {
        this.errors.thanks = null;
      }
    },

    getDataSubtype(e) {
      this.type = e.subtype;
      this.setSavedTicketData();
    },
    
    setDescription(value) {
      this.description = value;
    },

    localStorageTicketData() {
      const ticket_data = {
        'ticket_type': this.type,
        'ticket_title': this.title,
        'ticket_description': this.description
      };

      this.localStorage.setOneTicketDraft(ticket_data);
    }
  },

  watch: {
    title (old_title, new_title) {
      this.localStorageTicketData();
    },

    description (old_description, new_description) {
      this.localStorageTicketData();
    }
  }
}
</script>

<style scoped>

  @import '@/styles/CommonStyles/BasicElementsStyle.scss';

  a {
    color: #ed3f7c;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    padding: 5px;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }

  /* body */

  template {
    padding: 10px;
  }

  /* buttons */

  button {
    background: #ed3f7c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 40px;
    margin-top: 10px;
    padding: 0.4em 1.3em 0.8em 0.9em;
    width: 100%;
  }

  button svg, button.gracias svg {
    margin-right: 20px;
    margin-top: 5px;
    transform: rotate(30deg);
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }

  button span, button.gracias span {
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }

  button:hover, button.gracias:hover {
    cursor: pointer;
  }

  button:hover svg, button.gracias:hover svg {
    transform: translateX(5px) rotate(90deg);
  }

  button:hover span, button.gracias:hover span {
    transform: translateX(7px);
  }

  button.gracias {
    margin-top: 30px;
    width: 100%;
  }

  div.template {
    padding: 60px 20px 10px 20px;
  }

  /* hs */

  h1 {
    color: #444444;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    margin-left: auto;
    padding-top: 0;
  }

  h3.cultura {
    color: #444444;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  h3.no-cultura {
    color: #444444;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  /* insert text */

  input.no-cultura,
  input.cultura {
    overflow-y: auto;
    overflow-x: hidden;
  }

  input.cultura {
    border-radius: 5px;
    border-width: thin;
    cursor: default;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 36px;
    margin-bottom: 15px;
    outline: none;
    padding-inline-start: 20px;
    text-overflow: ellipsis;
    width: 95%;
  }

  input:hover, input.no-cultura:hover {
    border-color: #ed3f7c;
    border-width: thin;
    cursor: text;
  }


  input.no-cultura {
    border-radius: 5px;
    border-width: thin;
    cursor: default;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 36px;
    outline: none;
    padding-left: 20px;
    text-overflow: ellipsis;
    width: calc(100% - 24px);
  }

  input::selection {
    background-color: #fcc1db;
  }

  /* ps */

  p:not(.template) {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 30px;
  }

  p.template {
    border-radius: 10px;
    float: left;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bolder;
    margin: 0;
    padding: 20px;
    width: 100%;
  }

  /* classes */

  .containerSend {
    display: grid;
    grid-template-columns: 370px;
    justify-content: center;
    text-align: left;
  }

  .containerTitle {
    display: grid;
    justify-content: center;
    padding: 20px;
  }

  .colThSug {
    margin: auto;
    max-width: 500px;
    width: auto;
  }

  @media (min-width: 320px) {
    .containerSend {
      grid-template-columns:275px;
    }
    .colThSug{
      width: 275px;
    }

  }

  @media (min-width: 425px) {
    .containerSend {
      grid-template-columns: 400px;
    }

    .colSend {
      padding-left: 30px;
      padding-right: 30px;
    }
    .colThSug{
      width: 365px;
    }

  }

  @media (min-width: 750px) {
    .containerSend {
      grid-template-columns: repeat(2, 365px);
    }
    .colThSug{
      width: 500px;
    }

  }

  @media (min-width: 1024px) {
    .containerSend {
      grid-template-columns: repeat(2, 480px);
    }
  }

  @media (min-width: 1440px) {
    .containerSend {
      grid-template-columns: repeat(2, 600px);
    }
  }

  @media (min-width: 2560px) {
    .containerSend {
      grid-template-columns: repeat(2, 800px);
    }
  }

  .alertSend {
    color: white;
    background-color: #ff6c61;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    margin-bottom: 50px;
    margin-left: 20%;
    margin-right: 22%;
    padding: 30px;
  }

  .alertSendNoCult {
    color: white;
    background-color: #ff6c61;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    margin-bottom: 40px;
    padding: 30px;
    max-width: 500px;
  }

  .body {
    min-height: 88%;
  }

  .closebtn {
    color: white;
    cursor: pointer;
    font-size: 35px;
    font-weight: bold;
    float: right;
    line-height: 20px;
    margin-left: 15px;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }

  .error {
    background-color: #ff6c61;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 50px;
    margin: 0;
    width: 100%;
  }

  .error-sug {
    background-color: #ff6c61;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 50px;
    width: 100%;
  }

  .error-sug {
    background-color: #ff6c61;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 50px;
    width: 100%;
  }

  .is-required:after {
    color: #ff0000;
    content: "*";
    font-weight: bold;
    margin-left: 3px;
  }

  .loaderEllipsis {
    margin-top: 120px;
  }

  .returnLink {
    margin-left: -50px;
  }

  /* ids */

  #bodyTemplate {
    font-size: 16px;
    font-weight: normal;
  }

  #desc {
    min-height: 200px;
    resize: none;
  }

  #formSend {
    background-color: #ffffff;
    width: auto;
  }

  #section {
    text-align: left;
  }

  #ticketTemplate {
    background-color: #eeeeee;
    border: #eeeeee 0.2em solid;
    border-radius: 0.4em;
    box-shadow: 0.3em 0.3em 0.7em #00000015;
    height: 425px;
    margin: 30px auto 50px auto;
    max-width: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: border 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  #ticketTemplate::-webkit-scrollbar,
  textarea.no-cultura::-webkit-scrollbar,
  textarea.cultura::-webkit-scrollbar{
    background-clip: padding-box;
    background-color: #eeeeee;
    border: 4px solid transparent;
    border-radius: 8px;
    width: 14px;
  }

  #ticketTemplate::-webkit-scrollbar-thumb,
  textarea.no-cultura::-webkit-scrollbar-thumb,
  textarea.cultura::-webkit-scrollbar-thumb{
    background-color: #ed3f7c;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 16px;
    width: 10px;
  }

</style>