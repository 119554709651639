import LocalStorageService from "./storage/LocalStorageService"
import axios from "./storage/axios"

function parseJWT (token) {

    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
    return JSON.parse(jsonPayload)
}

// Check if token is expired:
function checkExpireDate (token) {
    let jwt = parserJWT(token);
    let expDate = jwt.exp;

    return expDate < new Date()/1000;
}

async function updateBalance() {

    const localStorageService = LocalStorageService.getService()

    const options = {
        method: 'get',
        url: `${process.env.VUE_APP_BACK_HOST}/api/user-data`
    }

    const response = await axios(options);

    if (!response.errorMessage) {

        localStorageService.setUserBalance(response.data.balance)
    }
}

export let parserJWT = parseJWT;
export let expireDate = checkExpireDate;
export let upBalance = updateBalance;