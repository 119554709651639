<template>

  <div class="container">
    <button tabindex="-1" v-if="this.current < this.total -1"
            type="button"
            class="next"
            @click.prevent.stop="nextPage">
    <span class="nextCircle">
      <span class="nextArrow"></span>
    </span>
      <span class="nextText">Siguiente</span>
    </button>

    <button tabindex="-1" v-if="this.current !== 0"
            type="button"
            class="back"
            @click="previousPage">
    <span class="backCircle">
      <span class="backArrow"></span>
    </span>
      <span class="backText">Anterior</span>
    </button>

  </div>
</template>

<script>


export default {
  name: "PaginateComponent",

  emits: ['page-changed', 'per-page-changed'],
  props: {
    totalPages: null,
    currentPage: null

  },

  data () {
    return {
      current: null,
      perPage: 10,
      total: null
    }

  },
  created() {

    this.current = this.currentPage
    this.total = this.totalPages

  },

  methods: {
    nextPage() {

      if (this.nextIsPossible) {

        this.$emit('page-changed', ++this.current)
      }

    },

    nextIsPossible() {

      return this.current + 1 < this.total-1
    },

    previousPage() {
      if (this.previousIsPossible) {

        this.$emit('page-changed', --this.current)
      }

    },

    previousIsPossible() {
      return this.current === 1
    }
  }

}
</script>

<style scoped>

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: 0.05em;
}

button.next {
  width: 12rem;
  height: auto;
  float: right;

}

button.back {
  width: 12rem;
  height: auto;
  float: left;

}

button .nextCircle, .backCircle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  width: 3rem;
  height: 3rem;
  background: #ED3F7c;
  border-radius: 1.625rem;
}

button.next .nextCircle {

  float:left;
}

button.back .backCircle {

  float: right;
}

button .nextCircle .nextArrow, .backCircle .nextArrow, .backArrow{

  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;


  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.next .nextCircle .nextArrow {
  left: 0.625rem;
}

button.back .backCircle .backArrow {
  right: 0.625rem;

}


button .nextCircle .nextArrow::before, .backCircle .backArrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;

}


button.next .nextCircle .nextArrow::before{
  right: 0.0625rem;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.back .backCircle .backArrow::before {

  left: 0.0625rem;
  border-left: 0.125rem solid #fff;
  transform: rotate(-45deg);
}

button .nextText, .backText {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;

  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}


button.next .nextText {
  margin: 0 0 0 1.85rem;
}

button.back .backText{
  margin: 0 1.85rem 0 0;
}


button:hover .nextCircle {
  width: 100%;
}

button:hover .backCircle {
  width: 100%;
}

button:hover .nextCircle .nextArrow {
  background: #ffffff;
  transform: translate(100%);
}

button:hover .backCircle .backArrow {
  background: #ffffff;
  transform: translate(-100%);
}

button.next:hover .nextText {
  color: #ffffff;
}

/*.container{
  width: 90%;
  max-width: 150px;
}*/

button.back:hover .backText {
  color: #ffffff;
}



</style>