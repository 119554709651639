<template>

  <div class="container">
    <h4>NODRItools</h4>

    <ul class="colum-list">
        <li class="column-line">
          <a href="https://docs.google.com/document/d/1NxbNQ2l57rZemQ2UYtird-fFfImK-VC_Uz6tOpl9J_o/edit#heading=h.ce4wjlg2d59m" target="_blank">Guías de uso: <strong>Tribuk</strong> </a>
          /
          <a href="https://docs.google.com/document/d/1-YtxGT4gtl7Zs6qVfinVPNlw_IzrxPy4zhHlccmT2Fw/edit#heading=h.ce4wjlg2d59m" target="_blank"> <strong>Tribuk Store</strong></a>
        </li>
        <li class="column-line">
          <a href="https://nodrizatech.com/formulario-nodrizer/" target="_blank"><strong>Stakeholder</strong> recomendado</a>
        </li>
        <li class="column-line">
          <a href="https://nodrizatech.com/formulario-cliente-recomendado/" target="_blank"><strong>Cliente</strong> recomendado</a>
        </li>
        <li class="column-line">
          <a href="https://soporte.nodrizatech.com" target="_blank"><strong>Soporte Técnico</strong></a>
        </li>
        <li class="column-line">
          <a href="https://grupoarelux.atlassian.net/servicedesk/customer/portal/1/group/12/create/80" target="_blank"><strong>¿Necesitas ayuda? </strong>Soporte Tribuk</a>
        </li>
    </ul>
  </div>

</template>

<script>

    export default {
        name: "NODRItoolsComponent"
    }

</script>

<style lang="scss">

  @import '@/styles/HomepageStyles/ColumnsHomepage.scss';

</style>