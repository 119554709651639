import { statusIdToName } from "../idToNameConverter";
import LocalStorageService from "@/services/storage/LocalStorageService";
import { TransactionTypeEnum } from "@/AppNT/enum/TransactionTypeEnum";

export const isEmpty = (object) => {

    let isEmpty = true;

    for (let i in object) {
        isEmpty = false;
        break;
    }

    return isEmpty;
}

export const deleteNotActiveFilters = (columns, activeFilters) => {

    let oldDateCreatedAtFiltered;
    let oldDateValidatedAtFiltered;
    let oldDateUpdatedAtFiltered;

    // Ticket columns: 

    if (!columns.status && ('status' in activeFilters)) {

      delete activeFilters['status'];
    }

    if (!columns.status && ('transaction_type' in activeFilters)) {

      delete activeFilters['transaction_type'];
    }

    if (!columns.type && ('transaction_ticket_type' in activeFilters)) {

      delete activeFilters['transaction_ticket_type'];
    }

    if (!columns.type && ('type' in activeFilters)) {

      delete activeFilters['type'];
    }

    if (!columns.created_at && ('created_at' in activeFilters)) {

      delete activeFilters['created_at'];
      oldDateCreatedAtFiltered = [];
    }

    if (!columns.updated_at && ('updated_at' in activeFilters)) {

      delete activeFilters['updated_at'];
      oldDateUpdatedAtFiltered = [];
    }

    if (!columns.validated_at && ('validated_at' in activeFilters)) {

      delete activeFilters['validated_at'];
      oldDateValidatedAtFiltered = [];
    }

    if (!columns.company && ('company' in activeFilters)) {

      delete activeFilters['company'];
    }

    if (!columns.department && ('department' in activeFilters)) {

      delete activeFilters['department'];
    }

    if (!columns.managers && ('managers' in activeFilters)) {

      delete activeFilters['managers'];
    }

    if (!columns.first_name && ('first_name' in activeFilters)) {

      delete activeFilters['managers'];
    }

    return columns;
}

export const getUserTicketsRows = (userTickets, possibleStatus) => {

  let tickets = [];
  let individualTicket;
  let status;
  let validate;
  let amount;
  let type;

  const localStorage = LocalStorageService.getService();

  for (let i = 0; i < userTickets.length; i++) {

    individualTicket = userTickets.slice(i, i + 1);

    status = statusIdToName(individualTicket[0]['status'], possibleStatus);

    if ((+ new Date(individualTicket[0]['validated_at'])) / 1000 === 0) {
      validate = null;
    }

    else {

      validate = (+ new Date(individualTicket[0]['validated_at'])) / 1000;
    }

    if (individualTicket[0]['type'] === 'Gracias' && individualTicket[0]['thanks_from']['email'] === localStorage.getUserEmail()) {
      amount = null;
    }

    else {
      amount = individualTicket[0]['amount'];
    }

    if (individualTicket[0]['type'] === 'Crosselling') {

      type = 'Cross-selling';

    } else if (individualTicket[0]['type'] === 'Upselling') {

      type = 'Up selling';

    } else {

      type = individualTicket[0]['type'];
    }

    tickets.push({
      'id': individualTicket[0]['id'],
      'title': individualTicket[0]['title'],
      'type': type,
      'created_at': (+ new Date(individualTicket[0]['created_at'])) / 1000,
      'status': status,
      'statusId': individualTicket[0]['status'],
      'validated_at': validate,
      'amount': amount
    });

  }

  return tickets;
}

export const getUserTransactionsRows = (userTransactions) => {

  let transactions = [];
  let individualTransaction;

  for (let i = 0; i < userTransactions.length; i++) {

    individualTransaction = userTransactions.slice(i, i + 1);

    let elements = individualTransaction[0]['wc_quantity'] === 1
                    ? 'elemento'
                    : individualTransaction[0]['wc_quantity'] === 0 || individualTransaction[0]['wc_quantity'] > 1
                    ? 'elementos'
                    : '';

    let transaction_details = individualTransaction[0]['wc_status'] === null
                              ? individualTransaction[0]['transaction_ticket_title']
                              : individualTransaction[0]['wc_status'] != null && individualTransaction[0]['wc_quantity'] != null
                              ? `Tu pedido se encuentra en estado ${individualTransaction[0]['wc_status']} y contiene ${individualTransaction[0]['wc_quantity']} ${elements}`
                              : null;

    transactions.push({
      'id': individualTransaction[0]['id'],
      'transaction_type': individualTransaction[0]['transaction_type'],
      'balance_id': individualTransaction[0]['balance_id'],
      'author_id': individualTransaction[0]['author_id'],
      'source': individualTransaction[0]['source'],
      'transaction_ticket_title': transaction_details,
      'type': individualTransaction[0]['transaction_ticket_type'],
      'transaction_ticket_type': individualTransaction[0]['transaction_ticket_type'],
      'created_at': (+ new Date(individualTransaction[0]['created_at'])) / 1000,
      'amount': individualTransaction[0]['amount'],
      'order_id': individualTransaction[0]['order_id'],
      'wc_status': individualTransaction[0]['wc_status'],
      'wc_quantity': individualTransaction[0]['wc_quantity']
    });

  }

  return transactions;
}

export const constructURLDates = (url, activeFilters) => {

  if (activeFilters.created_at) {

    switch (activeFilters.created_at.interval) {

      case 'between':
        url += `&created_date=${activeFilters.created_at.from}-${activeFilters.created_at.to}&created_itv=${activeFilters.created_at.interval}`;
        break;

      case 'after':
      case 'before':
        url += `&created_date=${activeFilters.created_at.from}&created_itv=${activeFilters.created_at.interval}`;
        break;
    }
  }

  if (activeFilters.validated_at) {

    switch (activeFilters.validated_at.interval) {
      case 'between': 
        url += `&validated_date=${activeFilters.validated_at.from}-${activeFilters.validated_at.to}&validated_itv=${activeFilters.validated_at.interval}`;
        break;

      case 'after':
      case 'before':
        url += `&validated_date=${activeFilters.validated_at.from}&validated_itv=${activeFilters.validated_at.interval}`;

        break;
    }
  }

  if (activeFilters.status) {
    url += `&status=${activeFilters.status}`
  }
  if (activeFilters.type) {
    url += `&type=${activeFilters.type}`
  }
  if (activeFilters.transaction_ticket_type) {
    url += `&ticket_type=${activeFilters.transaction_ticket_type}`
  }
  if (activeFilters.author) {
    url += `&author=${activeFilters.author}`
  }
  if (activeFilters.department) {
    url += `&department=${activeFilters.department}`
  }
  if (activeFilters.transaction_type) {
    let type = "";

    console.log('construct url active filters transaction_type: ', activeFilters.transaction_type);

    // Get number for transaction_type from enum constant
    for (const [key, value] of Object.entries(TransactionTypeEnum)) {
      if (value === activeFilters.transaction_type) {  
        type = key;
      }
    }
    url += `&transaction_type=${type}`;
  }

  return url;
}

export const getLeaderTicketsRows = (leaderTickets, possibleAuthors, ticketAuthorsNames, possibleStatus) => {

  let tickets = [];

  let individualTicket;
  let status;
  let validate;
  let amount;
  let type;

  for (let i = 0; i < leaderTickets.length; i++) {

    individualTicket = leaderTickets.slice(i, i + 1)

    let author = {
      'author_id': individualTicket[0]['author_id'],
      'author_name': individualTicket[0]['author'],
    }

    //Get Authors name from the tickets for later filter

    let exist = false;

    for (let i = 0; i < possibleAuthors.length; i++) {
      if (possibleAuthors[i].author_id === author.author_id) {
        exist = true;
      }
    }
    if (!exist) {
      possibleAuthors.push(author);
      ticketAuthorsNames.push(author.author_name);
    }

    status = statusIdToName(individualTicket['0']['status'], possibleStatus);

    if ((+new Date(individualTicket[0]['validated_at'])) / 1000 === 0) {
      
      validate = null;

    } else {
      
      validate = (+new Date(individualTicket[0]['validated_at'])) / 1000;
    }

    if (individualTicket[0]['type'] === 'Crosselling') {

      type = 'Cross-selling';

    } else if (individualTicket[0]['type'] === 'Upselling') {

      type = 'Up-selling';

    } else {

      type = individualTicket[0]['type'];
    }

    tickets.push({
      'id': individualTicket[0]['id'],
      'author': individualTicket[0]['author'],
      'company': individualTicket[0]['company'],
      'department': individualTicket[0]['department'],
      'title': individualTicket[0]['title'],
      'type': type,
      'created_at': (+new Date(individualTicket[0]['created_at'])) / 1000,
      'status': status,
      'statusId': individualTicket[0]['status'],
      'validated_at': validate,
      'amount': individualTicket[0]['amount']
    })

  }

  return tickets;
}