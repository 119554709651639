export const statusNameToId = (statusName, possibleStatus) => {

    let status

    for (let i = 0; i < possibleStatus.length; i++) {

        if (possibleStatus[i].name === statusName) {

            status = possibleStatus[i].id
        }
    }

    return status

}

export const typeNameToId = (typeName, possibleTypes) => {

    let typeId

    for (let i = 0; i < possibleTypes.length; i++) {

        if (possibleTypes[i].name === typeName) {

            typeId = possibleTypes[i].id
        }
    }

    return typeId

}

export const  authorNameToId = (authorName, possibleAuthors) => {

    let authorId

    for (let i = 0; i < possibleAuthors.length; i++) {

        if (possibleAuthors[i].author_name === authorName) {

            authorId = possibleAuthors[i].author_id
        }
    }

    return authorId
}

export const  departmentNameToId = (departmentName, possibleDepartments) => {

    let depId

    for (let i = 0; i < possibleDepartments.length; i++) {

        if (possibleDepartments[i].dept_name === departmentName) {

            depId = possibleDepartments[i].dept_id
        }
    }

    return depId
}

export const  companyNameToId = (companyName, possibleCompanys) => {

    let depId

    for (let i = 0; i < possibleCompanys.length; i++) {

        if (possibleCompanys[i].company_name === companyName) {

            depId = possibleCompanys[i].company_id
        }
    }

    return depId
}

export const  managerNameToId = (managerName, possibleManagers) => {

    let mangId

    for (let i = 0; i < possibleManagers.length; i++) {

        if (possibleManagers[i].name === managerName) {

            mangId = possibleManagers[i].id
        }
    }

    return mangId
}
