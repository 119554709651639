<template>

  <div class="container-header">
    <div class="logo-home">
      <router-link to="/">
        <img class="logo-home" @click="this.$router.push(`/`)" src="../../img/logoHeader.png" alt="logoApp">
      </router-link>
    </div>

    <div class="amount">
      <button 
        class="store-button"
        :class="{ 'store-button': !this.loadingStore, 'store-button-loading': this.loadingStore }"
        @click="this.Autologin()">
        <span v-if="!this.loadingStore" class="store-button-text">Ir a Tribuk Store</span>
        <span v-if="this.loadingStore" class="store-loader"></span>
      </button>
      <p class="p-amount">{{this.balance}}</p>
      <img class="nc" src="../../img/nodricoin.svg" alt="Nodricoin Logo">
    </div>


    <div class="menu">
      <div class="user" v-on:click="isOpen = !isOpen">
        {{this.user}}
        <svg class="arrow-icon" viewBox="0 0 1030 638" width="10">
          <path class = "arrow-path" d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z"></path>
        </svg>
      </div>
  </div>


  </div>

  <div class="dropdown">
    <ul class="menu-ul" v-if="isOpen">
      <li>
        <div class="sub-menu-home">
          <div class="sub-menu-item home"  @click="this.$router.push(`/`)">
            <router-link class="link" to="/"><svg class="icon" viewBox="0 0 576 512">
              <path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5
                450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8
                511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352
                472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1
                 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512
                 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02
                  287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4
                  0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8
                  255.5L575.8 255.5z"/>
            </svg>
              Inicio</router-link>
            </div>
        </div>
      </li>
      <li>
        <div class="sub-menu-user">
          <div class="sub-menu-item" >
            <router-link class="link" to="/user/tickets">Consultar tickets enviados</router-link>
          </div>
          <div class="sub-menu-item">
            <router-link class="link" to="/user/transactions">Consultar mis transacciones</router-link></div>
        </div>
      </li>

      <li>
        <div v-if="this.roles.includes('ROLE_MANAGER')" class="sub-menu-leader">
          <div class="sub-menu-item"><a class="link" href="/leader/tickets?view=team">Consultar tickets del equipo</a></div>
          <div class="sub-menu-item"><a class="link" href="/leader/tickets?view=validate">Validación de tickets</a></div>
        </div>
      </li>

      <li>
        <div class="sub-menu-logout">
          <div class="sub-menu-item logout" @click="this.logout">
            <svg class="icon" viewBox="0 0 576 512">
              <path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67
                 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96
                  96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25
                   0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31
                    32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25
                    0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z"/>
            </svg>
            Salir</div>
        </div>
      </li>
    </ul>
  </div>


</template>

<script>

import LocalStorageService from "@/services/storage/LocalStorageService";
import axios from "@/services/storage/axios";

import { autologinStore } from "@/AppNT/utils/storeUtils";
import {expireDate} from "@/services/utils";

export default {
  name: "HeaderComponent",
  data (){
    return {
      isOpen: false,
      user: LocalStorageService.getService().getUserName(),
      roles: LocalStorageService.getService().getUserRoles(),
      balance: null,
      userGuideAdded: false,
      loadingStore: false
    }
  },

  methods: {
    async Autologin() {
      this.loadingStore = true;
      await autologinStore();
      this.loadingStore = false;
    },

    logout() {
      const localStorageService = LocalStorageService.getService();
      localStorageService.clearUserData();
      localStorageService.clearToken();
      location.reload();
    },

    onClick(e){
      if(((e.target.classList.contains('user') && e.target.classList.contains('arrow-icon') && e.target.classList.contains('arrow-path')) && this.isOpen)
      || (!e.target.classList.contains('user') && !e.target.classList.contains('arrow-icon') && !e.target.classList.contains('arrow-path') && this.isOpen)){
        
        this.isOpen = false;
      }
    }
  },

  watch: {
    '$route' (to, from, next) {

      if (LocalStorageService.getTicketsDraft() === null) {
        LocalStorageService.setTicketsDraft();
      }

      const token = LocalStorageService.getAccessToken()

      if (token && !expireDate(token)) {

        const options = {
          method: 'get',
          url: `${process.env.VUE_APP_BACK_HOST}/api/user-data`,
          headers: {
            'Content-Type': 'application/json'
          }
        }

        axios(options)
            .then((response) => {

              if (response.data) {

                if (response.data.balance !== LocalStorageService.getUserBalance()) {

                  LocalStorageService.setUserBalance(response.data.balance)

                  this.balance = LocalStorageService.getUserBalance()

                } else {

                  this.balance = LocalStorageService.getUserBalance()
                }
              }

            }, (error) => {

              console.log(error)

            })
      }
    }
  },

  created() {
    this.balance = LocalStorageService.getUserBalance();
    document.addEventListener('click', this.onClick);
  },
}
</script>

<style scoped lang="scss">

@import '@/styles/HeaderStyles/Header.scss';

img.logo-home{
  width: auto;
  max-width: 50px;
  float: left;
  cursor:pointer;
}

div.logo-home{
  margin: auto auto auto 20px;
}

div{
  user-select: none;
}


ul.menu-ul{
  list-style-type: none;
  box-shadow: 0.3em 0.3em 0.7em #00000015;
  padding: 0;
  margin: auto 15px auto auto;
}

li {
  margin:auto;
}


.menu{
  z-index: 9;
  margin:auto;
}

.sub-menu-home {
  height: max-content;
  border: 2px solid #eeeeee;
  background-color: #fff;
}

.sub-menu-user {
  border: 2px solid #eeeeee;
  border-top: transparent;
  background-color: #fff;
}


.sub-menu-leader {
  border: 2px solid #eeeeee;
  border-top: transparent;
  background-color: #fff;
}

.sub-menu-guide {
  border: 2px solid #eeeeee;
  border-top: transparent;
  background-color: #fff;
}


.sub-menu-logout {
  border: 2px solid #eeeeee;
  border-top: transparent;
  background-color: #fff;
}

.menu {
  user-select: none;
  float: right;
}

.user {
  color: #000;
  font-weight: 600;
  cursor:pointer;
  width: auto;
  max-width: 350px;
  font-size: 16px;
}

.dropdown{
  float: right;
  z-index: 10;
  position: absolute;
  right: 2%;
  cursor: pointer;
}


div.sub-menu-item {
  text-align: left;
  border: none;
  height: 28px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

div.sub-menu-item:hover{
  background-color: rgba(237, 63, 124, 0.2);
}

div.home:hover{
  background-color: rgba(237, 63, 124, 0.2);
}

div.logout:hover{
  background-color: rgba(237, 63, 124, 0.2);
}

svg.icon{
  fill: #ED3F7c;
  width: 15px;
  padding-right: 20px;

}

svg.arrow-icon{
  fill: #ED3F7c;
  padding-left: 10px;
  width: 15px;
}

div.disable{
  text-decoration: line-through;
  color: #444444;
  pointer-events: none;
  cursor: not-allowed;
}


img.nc{
  width: auto;
  max-width: 25px;
}

p.p-amount{
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  margin:auto;
  padding-right: 10px;
}

div.amount{
  display: flex;
  justify-self: flex-end;
}


.container-header{
  display: grid;
  grid-template-columns: auto auto auto;
  height: 75px;
  border-bottom: 1px solid #efefef;
}

.sub-menu-item .link {
  display: block;
}

.sub-menu-item .link:hover {
  color: #444444;
}


@media (min-width:320px){
  .container-header {
    grid-template-columns: auto auto 25%;
  }
  button.store-button {
    font-size: 10px;
    margin-right: 7px;
  }
}

@media (min-width:375px){
  .container-header{
    grid-template-columns: auto auto 30%;
  }

  button.store-button {
    font-size: 12px;
    margin-right: 15px;
  } 
}

@media (min-width:420px){
  button.store-button {
    font-size: 15px;
    margin-right: 15px;
  } 
}

@media (min-width:768px){
  .container-header{
    grid-template-columns: auto auto 15%;
  }
  ul.menu-ul{
    margin: auto 15px auto auto;
  }

  button.store-button {
    margin-right: 25px;
  } 
}

@media (min-width:1024px){
  .container-header{
    grid-template-columns: auto auto 15%;
  }

  button.store-button {
    margin-right: 35px;
  } 
}

@media (min-width:1440px){
  .container-header{
    grid-template-columns: auto auto 10%;
  }
}


</style>