<template>
    <div class="body-edit">

        <div class="close-btn-edit" @click="onCloseClick"></div>

        <h1 class="slide-edit"> Editando <span style="color: #ed3f7c">{{ this.selectedRows.length }}</span> NODRIZERS:
        </h1>

        <form>

            <div v-if="this.error">
                <ErrorDialogComponent v-bind:error="this.errorMessage" v-bind:closeOn="true" />
            </div>

            <h3 class="slide-edit">Activar / Desactivar</h3>
            <div class="select-options">
                <select v-model="changeData.changeActive.action" class="slide-edit" name="active-deactive"
                    id="active-deactiva" @change="keepValue('active')">
                    <option class="slide-edit" value="keep">Mantener valor actual</option>
                    <option class="slide-edit" value="change">Cambiar valor actual</option>
                </select>
                <select v-if="changeData.changeActive.action === 'change'" v-model="changeData.changeActive.active"
                    class="slide-edit" name="active-deactive" id="active-deactiva">
                    <option class="slide-edit" value="true">Activar NODRIZERS</option>
                    <option class="slide-edit" value="false">Desactivar NODRIZERS</option>
                </select>
            </div>

            <h3 class="slide-edit">Cambiar empresa / <br /> departamento</h3>
            <div class="select-options">
                <select v-model="changeData.changeCompany.action" class="slide-edit" name="active-deactive"
                    id="active-deactiva" @change="keepValue('company')">
                    <option class="slide-edit" value="keep">Mantener valor actual</option>
                    <option class="slide-edit" value="change">Cambiar valor actual</option>
                </select>
                <select v-model="changeData.changeCompany.company" v-if="changeData.changeCompany.action === 'change'"
                    class="slide-edit" name="company" id="company" placeholder="Nueva empresa"
                    @change="onCompanyChange">
                    <option class="slide-edit" v-for="com in this.adminData.companies" v-bind:key="com" :value="com">
                        {{ com.company_name }}</option>
                </select>
                <select v-model="changeData.changeDepartment"
                    v-if="changeData.changeCompany.action === 'change' && changeData.changeCompany.company != null"
                    class="slide-edit" name="department" id="department" placeholder="Nuevo departamento">
                    <option class="slide-edit" v-for="dep in this.departmentsByCompany" v-bind:key="dep" :value="dep">
                        {{ dep.dept_name }} </option>
                </select>
            </div>

            <hr class="edit-separator" />

            <div v-if="selectedRows.length === 1 && selectedRows[0].roles.includes('Responsable')">
                <h3 class="slide-edit">Añadir / Eliminar <br /> departamento al responsable</h3>
                <div class="select-options">
                    <select v-model="changeData.changeManagerDepartment.action" class="slide-edit"
                        name="active-deactive" id="active-deactiva" @change="keepValue('manager-departament')">
                        <option class="slide-edit" value="keep">Mantener valor actual</option>
                        <option class="slide-edit" value="change">Sustituir valor actual</option>
                        <option class="slide-edit" value="add">Añadir departamento a responsable</option>
                        <option class="slide-edit" value="delete">Eliminar departamento a responsable</option>
                    </select>
                    <select v-if="changeData.changeManagerDepartment.action != 'keep'"
                        v-model="changeData.changeManagerDepartment.valueDepartmentManager" class="slide-edit"
                        name="roles" id="roles">
                        <option value="" hidden>Departamento a modificar</option>
                        <option v-show="changeData.changeManagerDepartment.action === 'change'" class="slide-edit" v-for="depManager in this.managerDepartments" v-bind:key="depManager" :value="depManager"> {{ depManager.dept_name }}
                        </option>
                        <option v-show="changeData.changeManagerDepartment.action != 'change'" class="slide-edit" v-for="depCompManager in this.managerCompanyDepartments" v-bind:key="depCompManager" :value="depCompManager"> {{ depCompManager.dept_name }}
                        </option>
                    </select>
                    <select v-if="changeData.changeManagerDepartment.action === 'change'"
                        v-model="changeData.changeManagerDepartment.changeValueDepartmentManager" class="slide-edit"
                        name="roles" id="roles">
                        <option value="" hidden>Nuevo departamento</option>
                        <option class="slide-edit" v-for="depCompManager in this.managerCompanyDepartments" v-bind:key="depCompManager" :value="depCompManager"> {{ depCompManager.dept_name }}
                        </option>
                    </select>
                </div>
            </div>


            <h3 class="slide-edit"> Agregar / Eliminar <br /> responsable asociado</h3>
            <div class="select-options">
                <select v-model="changeData.changeManager.action" class="slide-edit" name="active-deactive"
                    id="active-deactiva" @change="keepValue('manager')">
                    <option class="slide-edit" value="keep">Mantener valor actual</option>
                    <option class="slide-edit" value="change">Sustituir valor actual</option>
                    <option class="slide-edit" value="add">Añadir responsable</option>
                    <option class="slide-edit" value="delete">Eliminar responsable</option>
                </select>
            </div>
            <div class="select-options">
                <select v-if="changeData.changeManager.action != 'keep'" class="slide-edit"
                    v-model="changeData.changeManager.valueManager" name="managers" id="managers">
                    <option value="" hidden>Responsable a modificar</option>
                    <option v-show="changeData.changeManager.action != 'change'" class="slide-edit"
                        v-for="mang in this.adminData.managers" v-bind:key="mang" :value="mang.id">{{ mang.name }}
                    </option>
                    <option v-show="changeData.changeManager.action == 'change'" class="slide-edit"
                        v-for="mang in this.managerCommonNames" v-bind:key="mang" :value="mang.id">{{ mang.name }}</option>
                </select>
                <select v-if="changeData.changeManager.action == 'change'" class="slide-edit"
                    v-model="changeData.changeManager.changeValueManager" name="managers" id="managers">
                    <option value="" hidden>Nuevo responsable</option>
                    <option v-show="this.managersInCommon.length > 0" class="slide-edit"
                        v-for="mang in this.adminData.managers" v-bind:key="mang" :value="mang.id">{{ mang.name }}
                    </option>
                </select>
            </div>

            <h3 class="slide-edit">Añadir / Eliminar rol</h3>
            <div class="select-options">
                <select v-model="changeData.changeRole.action" class="slide-edit" name="active-deactive"
                    id="active-deactiva" @change="keepValue('role')">
                    <option class="slide-edit" value="keep">Mantener valor actual</option>
                    <option class="slide-edit" value="add">Añadir rol</option>
                    <option class="slide-edit" value="delete">Eliminar rol</option>
                </select>
                <select v-if="changeData.changeRole.action != 'keep'" v-model="changeData.changeRole.valueRole"
                    class="slide-edit" name="roles" id="roles">
                    <option class="slide-edit" v-for="rol in this.rolesTranslated" v-bind:key="rol" :value="rol">
                        {{ rol }} </option>
                </select>
            </div>

            <!--<hr class="edit-separator" />

            <h3 class="slide-edit disabled">Insertar / Quitar Nodricoins</h3>
            <div class="select-options">
                <select v-model="changeData.changeNodricoins.action" class="slide-edit disabled"
                    name="actions-nodricoins" id="actions-nodricoins">
                    <option class="slide-edit" value="add">Insertar Nodricoins</option>
                    <option class="slide-edit" value="delete">Quitar Nodricoins</option>
                </select>
            </div>
            <div class="select-options">
                <input v-model="changeData.changeNodricoins.valueNodricoins" class="slide-edit disabled" type="number"
                    name="nodricoins" id="nodricoins" min="0" step="1"
                    placeholder="Nodricoins a insertar / quitar..." />
            </div>-->

            <button class="slide-edit" @click="getChanges" tabindex="-1">
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                        d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                        fill="currentColor"></path>
                </svg>
                <span>Editar NODRIZERS</span>
            </button>

        </form>

    </div>

</template>

<script>

import ErrorDialogComponent from '@/components/GeneralComponents/ErrorDialogComponent';
import { managerIdToName } from '@/AppNT/utils/idToNameConverter';

export default {

    props: {
        selectedRows: null,
        adminData: null,
        managerDepartments: null,
        managerCompanyDepartments: null,
        rolesTranslated: null
    },

    components: {
        ErrorDialogComponent
    },

    emits: ['sendChanges', 'edit-close'],

    watch: {
        selectedRows() {
            this.findCommonManagers();
        }
    },

    created() {
        this.findCommonManagers();
    },

    data() {
        return {
            changeData: {
                changeActive: {
                    action: 'keep',
                    active: null
                },
                changeManager: {
                    action: 'keep',
                    valueManager: '',
                    changeValueManager: ''
                },
                changeCompany: {
                    action: 'keep',
                    company: null,
                },
                changeDepartment: null,
                changeRole: {
                    action: 'keep',
                    valueRole: null
                },
                changeNodricoins: {
                    action: null,
                    valueNodricoins: null
                },
                changeManagerDepartment: {
                    action: 'keep',
                    valueDepartmentManager: '',
                    changeValueDepartmentManager: ''
                }
            },

            departmentsByCompany: null,

            error: false,
            errorMessage: "",
            closeError: true,

            managersInCommon: [],
            managerCommonNames: [],
        }
    },

    methods: {

        findNamesOfCommonManagers() {

            this.managerCommonNames = [];

            this.managersInCommon.forEach(managerId => {
                let nameManager = managerIdToName(managerId, this.adminData.managers);
                if (nameManager != null) {
                    this.managerCommonNames.push({id: managerId, name: nameManager})
                }
            });
        },

        findCommonManagers() {

            let commonManagers = [];

            for (let i = 0; i < this.selectedRows.length; i++) {

                const rowManagersIds = this.selectedRows[i]['managers_ids'];
                if (i === 0) {
                    commonManagers = rowManagersIds;
                    continue;
                }
                commonManagers = commonManagers.filter((n) => {
                    return rowManagersIds.indexOf(n) !== -1;
                });
                if (commonManagers.length === 0) {
                    break;
                }

            }

            this.managersInCommon = commonManagers;

            this.findNamesOfCommonManagers();
        },

        getChanges(e) {

            e.preventDefault();

            let companyCorrect = true
            let managerCorrect = true
            let roleCorrect = true
            let managerDeptCorrect = true

            // Check active on form

            if (this.changeData.changeActive.active != null) {

                if (this.changeData.changeActive.active === 'true')
                    this.changeData.changeActive = true

                else if (this.changeData.changeActive.active === 'false')
                    this.changeData.changeActive = false
            }

            // Check company / department

            companyCorrect = this.checkCompanyAndDepartment(companyCorrect)

            // Check manager

            managerCorrect = this.checkManager(managerCorrect)

            // Check role and translate to ROLE_MANAGER or ROLE_ADMIN

            roleCorrect = this.checkRoles(roleCorrect)


            // Check departament manager changes

            managerDeptCorrect = this.checkManagerDepartment(managerDeptCorrect)

            if (companyCorrect && managerDeptCorrect && managerCorrect && roleCorrect) {

                this.error = false
                this.errorMessage = ""
                this.$emit('sendChanges', this.changeData)
            }
        },

        onCompanyChange() {

            let company = this.changeData.changeCompany.company
            let departments = Object.values(this.adminData.departments)

            this.departmentsByCompany = departments.filter(dep => dep.company.company_id === company.company_id)

            this.changeData.changeDepartment = null
        },

        // Checking methods:

        checkRoles(formCorrect) {

            if (this.changeData.changeRole.action != 'keep') {

                if (this.changeData.changeRole.valueRole != null) {

                    if (this.changeData.changeRole.valueRole === 'Responsable')
                        this.changeData.changeRole.valueRole = 'ROLE_MANAGER'

                    else if (this.changeData.changeRole.valueRole === 'Administrador')
                        this.changeData.changeRole.valueRole = 'ROLE_ADMIN'

                } else {

                    this.error = true
                    this.errorMessage = 'Debes incluir un rol'
                    formCorrect = false
                }

            } else if (this.changeData.changeRole.action === 'keep') {

                if (this.changeData.changeRole.valueRole != null) {

                    this.error = true
                    this.errorMessage = 'Acción para cambiar el rol requerida'
                    formCorrect = false
                }
            }

            return formCorrect
        },

        checkManager(formCorrect) {

            if (this.changeData.changeManager.action != 'keep') {

                if (this.changeData.changeManager.action == 'change'
                    && (this.changeData.changeManager.changeValueManager == null
                        || this.changeData.changeManager.changeValueManager == ''
                        || this.changeData.changeManager.valueManager == null
                        || this.changeData.changeManager.valueManager == '')) {

                    this.error = true
                    this.errorMessage = 'Debes incluir un responsable y un responsable sustituto'
                    formCorrect = false
                }

                else if (this.changeData.changeManager.action != 'change'
                    && (this.changeData.changeManager.valueManager == null
                        || this.changeData.changeManager.valueManager == '')) {

                    this.error = true
                    this.errorMessage = 'Debes incluir un responsable'
                    formCorrect = false
                }

            }

            return formCorrect
        },

        checkCompanyAndDepartment(formCorrect) {

            if (this.changeData.changeCompany.company != null) {

                if (this.changeData.changeDepartment == null) {

                    this.error = true
                    this.errorMessage = 'Debes incluir un departamento'
                    formCorrect = false
                }

            } else if (this.changeData.changeDepartment == null) {

                if (this.changeData.changeCompany.company != null) {

                    this.error = true
                    this.errorMessage = 'Debes incluir una empresa'
                    formCorrect = false
                }
            }

            return formCorrect
        },

        checkManagerDepartment(formCorrect) {

            if (this.changeData.changeManagerDepartment.action != 'keep') {

                if (this.changeData.changeManagerDepartment.action == 'change'
                    && (this.changeData.changeManagerDepartment.valueDepartmentManager == null
                        || this.changeData.changeManagerDepartment.valueDepartmentManager == ''
                        || this.changeData.changeManagerDepartment.changeValueDepartmentManager == null
                        || this.changeData.changeManagerDepartment.changeValueDepartmentManager == '')) {

                    this.error = true
                    this.errorMessage = 'Debes incluir un departamento y un departamento sustituto para el responsable'
                    formCorrect = false
                }

                else if (this.changeData.changeManagerDepartment.action != 'change'
                    && (this.changeData.changeManagerDepartment.valueDepartmentManager == null
                        || this.changeData.changeManagerDepartment.valueDepartmentManager == '')) {

                    this.error = true
                    this.errorMessage = 'Debes incluir un responsable'
                    formCorrect = false
                }

            }

            return formCorrect
        },

        onCloseClick() {
            this.$emit('edit-close');
        },

        keepValue(section) {

            switch (section) {

                case 'active':

                    if (this.changeData.changeActive.action === 'keep')
                        this.changeData.changeActive.active = null;

                    break;

                case 'company':

                    if (this.changeData.changeCompany.action === 'keep') {

                        this.changeData.changeCompany.company = null;
                        this.changeData.changeDepartment = null;
                    }

                    break;

                case 'manager':

                    if (this.changeData.changeManager.action === 'keep') {

                        this.changeData.changeManager.valueManager = null;
                    }

                    break;

                case 'role':

                    if (this.changeData.changeRole.action === 'keep') {

                        this.changeData.changeRole.valueRole = null;
                    }

                    break;

                case 'manager-departament':

                    if (this.changeData.changeManagerDepartment.action === 'keep') {

                        this.changeData.changeManagerDepartment.valueDepartmentManager = null;
                    }

                    break;
            }
        }
    },

};
</script>

<style scoped>
.body-edit {
    padding-bottom: 25%;
}

button.slide-edit {
    background: #ed3f7c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-left: 16%;
    margin-right: 15%;
    margin-top: 80px;
    padding: 0.4em 1.3em 0.8em 0.9em;
    width: 70%;
}

button.slide-edit svg {
    margin-right: 20px;
    margin-top: 5px;
    transform: rotate(30deg);
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

button.slide-edit span {
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

button.slide-edit:hover {
    cursor: pointer;
}

button.slide-edit:hover svg {
    transform: translateX(5px) rotate(90deg);
}

button.slide-edit:hover span {
    transform: translateX(7px);
}

h1.slide-edit {
    font-size: 24px;
    margin-left: 40px;
    margin-top: 60px;
    text-align: left;
}

h3.slide-edit {
    text-align: left;
    margin-left: 90px;
    margin-top: 50px;
}

div.close-btn:after {
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
}

input.slide-edit {
    border-radius: 5px;
    border-width: thin;
    cursor: default;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 30px;
    margin-bottom: 15px;
    margin-top: 15px;
    outline: none;
    overflow-y: auto;
    overflow-x: hidden;
    padding-inline-start: 20px;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 55%;
}

input.slide-edit:hover {
    border-color: #ed3f7c;
    border-width: thin;
    cursor: text;
}

input.slide-edit::selection {
    background-color: #fcc1db;
}

select.slide-edit {
    appearance: none;
    background-color: #ffffff;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    height: 30px;
    margin-top: 10px;
    outline: none;
    text-indent: 10px;
    text-overflow: ellipsis;
    width: 60%;
}

select.slide-edit:hover {
    border-color: #ed3f7c;
    cursor: pointer;
}

/*minimal*/

select.slide-edit {
    background-image: linear-gradient(45deg, transparent 50%, #f92871 50%),
        linear-gradient(135deg, #f92871 51%, transparent 50%),
        linear-gradient(to right, #dfdedf, #dfdedf);
    background-position: calc(100% - 20px) calc(1em + -5px),
        calc(100% - 15px) calc(1em + -5px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}

select.slide-edit:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

/*CLASSES*/

.close-btn-edit {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 25px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}

.close-btn-edit:hover {
    opacity: 1;
}

.close-btn-edit:before,
.close-btn-edit:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 27px;
    width: 2px;
    background-color: rgb(41, 40, 40);
}

.close-btn-edit:hover:before,
.close-btn-edit:hover:after {
    background-color: #ED3F7C;
}

.close-btn-edit:before {
    transform: rotate(45deg);
}

.close-btn-edit:after {
    transform: rotate(-45deg);
}

.edit-separator {
    margin-top: 50px;
    width: 100%;
}

.select-options {
    padding-left: 0px;
}


/* DISABLED */

h3.disabled {
    text-decoration: line-through;
    color: #444444;
    pointer-events: none;
    cursor: not-allowed;
}

select.disabled {
    background-color: #8b8a8a;
    text-decoration: line-through;
    color: #444444;
    pointer-events: none;
    cursor: not-allowed;
}

input.disabled {
    background-color: #8b8a8a;
    text-decoration: line-through;
    color: #444444;
    pointer-events: none;
    cursor: not-allowed;
}
</style>