export const  departmentsFromCompany = (companyId, managerDepartments) => {

    let departmentsNames = []

    for (let i = 0; i < managerDepartments.length; i++) {

        if (managerDepartments[i].company_id === companyId) {

            departmentsNames.push(managerDepartments[i].dept_name)
        }
    }

    return departmentsNames
}
