<template>

    <div class="menu-wrapper">
        <nav class="nav-editor" v-if="editor">
            <ul class="menu-buttons">
                <li class="menu-li bold">
                    <button type="button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 12px" viewBox="0 0 384 512"><path d="M321.1 242.4C340.1 220.1 352 191.6 352 160c0-70.59-57.42-128-128-128L32 32.01c-17.67 0-32 14.31-32 32s14.33 32 32 32h16v320H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h224c70.58 0 128-57.41 128-128C384 305.3 358.6 264.8 321.1 242.4zM112 96.01H224c35.3 0 64 28.72 64 64s-28.7 64-64 64H112V96.01zM256 416H112v-128H256c35.3 0 64 28.71 64 63.1S291.3 416 256 416z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 12px" viewBox="0 0 384 512"><path d="M384 64.01c0 17.69-14.31 32-32 32h-58.67l-133.3 320H224c17.69 0 32 14.31 32 32s-14.31 32-32 32H32c-17.69 0-32-14.31-32-32s14.31-32 32-32h58.67l133.3-320H160c-17.69 0-32-14.31-32-32s14.31-32 32-32h192C369.7 32.01 384 46.33 384 64.01z"/></svg>
                    </button> 
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 13px; margin-top: 2px" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M416 448H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h384c17.69 0 32-14.31 32-32S433.7 448 416 448zM48 64.01H64v160c0 88.22 71.78 159.1 160 159.1s160-71.78 160-159.1v-160h16c17.69 0 32-14.32 32-32s-14.31-31.1-32-31.1l-96-.0049c-17.69 0-32 14.32-32 32s14.31 32 32 32H320v160c0 52.94-43.06 95.1-96 95.1S128 276.1 128 224v-160h16c17.69 0 32-14.31 32-32s-14.31-32-32-32l-96 .0049c-17.69 0-32 14.31-32 31.1S30.31 64.01 48 64.01z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 17px" viewBox="0 0 512 512"><path d="M332.2 319.9c17.22 12.17 22.33 26.51 18.61 48.21c-3.031 17.59-10.88 29.34-24.72 36.99c-35.44 19.75-108.5 11.96-186-19.68c-16.34-6.686-35.03 1.156-41.72 17.53s1.188 35.05 17.53 41.71c31.75 12.93 95.69 35.37 157.6 35.37c29.62 0 58.81-5.156 83.72-18.96c30.81-17.09 50.44-45.46 56.72-82.11c3.998-23.27 2.168-42.58-3.488-59.05H332.2zM488 239.9l-176.5-.0309c-15.85-5.613-31.83-10.34-46.7-14.62c-85.47-24.62-110.9-39.05-103.7-81.33c2.5-14.53 10.16-25.96 22.72-34.03c20.47-13.15 64.06-23.84 155.4 .3438c17.09 4.531 34.59-5.654 39.13-22.74c4.531-17.09-5.656-34.59-22.75-39.12c-91.31-24.18-160.7-21.62-206.3 7.654C121.8 73.72 103.6 101.1 98.09 133.1C89.26 184.5 107.9 217.3 137.2 239.9L24 239.9c-13.25 0-24 10.75-24 23.1c0 13.25 10.75 23.1 24 23.1h464c13.25 0 24-10.75 24-23.1C512 250.7 501.3 239.9 488 239.9z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 20px" viewBox="0 0 640 512"><path d="M414.8 40.79L286.8 488.8C281.9 505.8 264.2 515.6 247.2 510.8C230.2 505.9 220.4 488.2 225.2 471.2L353.2 23.21C358.1 6.216 375.8-3.624 392.8 1.232C409.8 6.087 419.6 23.8 414.8 40.79H414.8zM518.6 121.4L630.6 233.4C643.1 245.9 643.1 266.1 630.6 278.6L518.6 390.6C506.1 403.1 485.9 403.1 473.4 390.6C460.9 378.1 460.9 357.9 473.4 345.4L562.7 256L473.4 166.6C460.9 154.1 460.9 133.9 473.4 121.4C485.9 108.9 506.1 108.9 518.6 121.4V121.4zM166.6 166.6L77.25 256L166.6 345.4C179.1 357.9 179.1 378.1 166.6 390.6C154.1 403.1 133.9 403.1 121.4 390.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4L121.4 121.4C133.9 108.9 154.1 108.9 166.6 121.4C179.1 133.9 179.1 154.1 166.6 166.6V166.6z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                        <span class="heading-list">H1</span>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                        <span class="heading-list">H2</span>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                        <span class="heading-list">H3</span>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144C37.49 144 16 122.5 16 96zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 416C16 389.5 37.49 368 64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416zM112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208C90.51 208 112 229.5 112 256z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 20px" viewBox="0 0 576 512"><path d="M55.1 56.04C55.1 42.78 66.74 32.04 79.1 32.04H111.1C125.3 32.04 135.1 42.78 135.1 56.04V176H151.1C165.3 176 175.1 186.8 175.1 200C175.1 213.3 165.3 224 151.1 224H71.1C58.74 224 47.1 213.3 47.1 200C47.1 186.8 58.74 176 71.1 176H87.1V80.04H79.1C66.74 80.04 55.1 69.29 55.1 56.04V56.04zM118.7 341.2C112.1 333.8 100.4 334.3 94.65 342.4L83.53 357.9C75.83 368.7 60.84 371.2 50.05 363.5C39.26 355.8 36.77 340.8 44.47 330.1L55.59 314.5C79.33 281.2 127.9 278.8 154.8 309.6C176.1 333.1 175.6 370.5 153.7 394.3L118.8 432H152C165.3 432 176 442.7 176 456C176 469.3 165.3 480 152 480H64C54.47 480 45.84 474.4 42.02 465.6C38.19 456.9 39.9 446.7 46.36 439.7L118.4 361.7C123.7 355.9 123.8 347.1 118.7 341.2L118.7 341.2zM512 64C529.7 64 544 78.33 544 96C544 113.7 529.7 128 512 128H256C238.3 128 224 113.7 224 96C224 78.33 238.3 64 256 64H512zM512 224C529.7 224 544 238.3 544 256C544 273.7 529.7 288 512 288H256C238.3 288 224 273.7 224 256C224 238.3 238.3 224 256 224H512zM512 384C529.7 384 544 398.3 544 416C544 433.7 529.7 448 512 448H256C238.3 448 224 433.7 224 416C224 398.3 238.3 384 256 384H512z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 15px" viewBox="0 0 384 512"><path d="M224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM154.1 353.8c7.812 7.812 7.812 20.5 0 28.31C150.2 386.1 145.1 388 140 388s-10.23-1.938-14.14-5.844l-48-48c-7.812-7.812-7.812-20.5 0-28.31l48-48c7.812-7.812 20.47-7.812 28.28 0s7.812 20.5 0 28.31L120.3 320L154.1 353.8zM306.1 305.8c7.812 7.812 7.812 20.5 0 28.31l-48 48C254.2 386.1 249.1 388 244 388s-10.23-1.938-14.14-5.844c-7.812-7.812-7.812-20.5 0-28.31L263.7 320l-33.86-33.84c-7.812-7.812-7.812-20.5 0-28.31s20.47-7.812 28.28 0L306.1 305.8zM256 0v128h128L256 0z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 96C42.98 96 0 138.1 0 192s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192C192 138.1 149 96 96 96zM448 192c0-53.02-42.98-96-96-96s-96 42.98-96 96s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192z"/></svg>
                    </button>
                </li>
                <li class="menu-li">
                    <button type="button" @click="editor.chain().focus().setHorizontalRule().run()">
                        <svg class="menu-svg" xmlns="http://www.w3.org/2000/svg" style="width: 20px" viewBox="0 0 640 512"><path d="M0 176C0 149.5 21.49 128 48 128H112V208C112 216.8 119.2 224 128 224C136.8 224 144 216.8 144 208V128H208V208C208 216.8 215.2 224 224 224C232.8 224 240 216.8 240 208V128H304V208C304 216.8 311.2 224 320 224C328.8 224 336 216.8 336 208V128H400V208C400 216.8 407.2 224 416 224C424.8 224 432 216.8 432 208V128H496V208C496 216.8 503.2 224 512 224C520.8 224 528 216.8 528 208V128H592C618.5 128 640 149.5 640 176V336C640 362.5 618.5 384 592 384H48C21.49 384 0 362.5 0 336V176z"/></svg>
                    </button>
                </li>
            </ul>
        </nav>

        <editor-content v-model="description" class="editor" :editor="editor" />
    </div>

</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'

export default {
    props: {
        placeholder: null,
        loaded_saved: false
    },
    components: {
        EditorContent,
    },
    data() {
        return {
            editor: null,
            description: null
        }
    },
    watch: {
        description (value) {
            this.$emit('text-change', value);
        },
        loaded_saved(value) {
            this.editor.setOptions({editable: value});
        }
    },
    mounted() {
        this.editor = new Editor({
            content: this.description,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: this.placeholder,
                }),
                Underline
            ],
            editable: false,
            onUpdate: () => {
                this.description = this.editor.getHTML();
            }
        });
    },
    beforeUnmount() {
        this.editor.destroy();
    },
}
</script>

<style lang="scss">

    .heading-list {
        color: #4c4c4c;
        font-size: 15px;
        font-weight: bold;
    }

    /* Basic editor styles */

    .ProseMirror {
        border: 1px solid;
        border-radius: 5px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-width: thin;
        color: #000000;
        cursor: default;
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 400;
        height: 225px;
        margin-bottom: 20px;
        outline: none;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 5px;
        width: 100%;

        > * + * {
            margin-top: 0.75em;
        }

        p {
          margin-right: 1.0em;
        }

        *::selection {
            background-color: #fcc1db;
        }

        ul,
        ol {
            margin-left: 10px;
            margin-right: 1.0em;
        }

        h1,
        h2,
        h3 {
            line-height: 1.1;
            padding-inline-start: 20px;
            margin-right: 1.0em;
        }

        code {
            background-color: rgba(#616161, 0.1);
            color: #616161;
            margin-right: 1.0em;
        }

        pre {
            background: #262525;
            color: #FFF;
            font-family: 'JetBrainsMono', monospace;
            padding: 0.75rem 1rem;
            margin-left: 20px;
            margin-right: 20px;
            border-radius: 0.5rem;
            code {
                color: inherit;
                margin-left: 10px;
                background: none;
                font-size: 16px;
                margin-right: 1.0em;
            }
        }

        img {
            max-width: 100%;
            height: auto;
            margin-right: 1.0em;
        }

        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#262626, 0.1);
            margin-right: 1.0em;
        }

        hr {
            border: none;
            border-top: 2px solid rgba(#0D0D0D, 0.1);
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }

    .menu-wrapper:hover {

        .ProseMirror {
            border-color: #ed3f7c;
            border-width: thin;
            cursor: text;
        }

        & nav > ul.menu-buttons {
            border-color: #ed3f7c;
            border-width: thin;
            cursor: text;
        }
    }

    

    .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 400;
        color: #808181;
        pointer-events: none;
        height: 0;
    }   

    .ProseMirror::-webkit-scrollbar{
        background-clip: padding-box;
        background-color: #eeeeee;
        border: 4px solid transparent;
        border-radius: 8px;
        width: 14px;
    }

    .ProseMirror::-webkit-scrollbar-thumb{
        background-color: #ed3f7c;
        background-clip: padding-box;
        border: 4px solid transparent;
        border-radius: 16px;
        width: 10px;
    }

    .ProseMirror p {
        padding-inline-start: 20px;
    }

    .menu-wrapper {
        width: 100%;
    }

    .menu-buttons button {
        align-content: center;
        background-color: transparent;
        border: none;
        padding-left: 5px;
    }

    .is-active {
        .menu-svg {
            fill: #ed3f7c;
        }

        .heading-list {
            color: #ed3f7c;
        }
    }

    .menu-buttons button:hover {
        border-radius: 20px;
        cursor: pointer;
    }

    .nav-editor ul.menu-buttons {
        border: 1px solid;
        background-color: #f6f6f6;
        border-bottom: none;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-width: thin;
        height: 32px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        width: 100%;
    }

    ul li.menu-li {
        float: left;
        padding-top: 3px;
        padding-right: 3px;
    }

    li.menu-li button {
        text-align: center;
        padding: 5px;
        text-decoration: none;
    }

    li.menu-li button:hover {
        background-color: #edebeb;
    }

    svg.menu-svg {
        fill: #4c4c4c;
        width: 20px;
    }

</style>