<template>
    <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
        <div class="dot3"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .spinner {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dot1, .dot2, .dot3 {
        width: 7px;
        height: 7px;
        border: double;
        border-color: transparent;
        border-radius: 50%;
        margin: 10px;
    }

    .dot1 {
        animation: jump765 1.4s -0.32s linear infinite;
        background: #ed3f7c;
    }

    .dot2 {
        animation: jump765 1.4s -0.16s linear infinite;
        background: #ed3f7c;
    }

    .dot3 {
        animation: jump765 1.4s linear infinite;
        background: #ed3f7c;
    }

    @keyframes jump765 {
        0%, 80%, 100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        40% {
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
        }
    }
</style>