<template>
    <div class="filter-input"
         @click="showFilter($event)">
        <input type="text"
               class="vgt-input"
               disabled="disabled"
               :value="getFilterLabel()"/>
    </div>
    <svg class="filter-img"
         :class="{active: isActive()}"
         viewBox="0 0 64 64"
         @click="clearFilter"
         v-if="!iconDate">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M43,48 L43,56 L21,56 L21,48 L43,48 Z M53,28 L53,36 L12,36 L12,28 L53,28 Z M64,8 L64,16 L0,16 L0,8 L64,8 Z"
                fill="currentColor"></path>
        </g>
    </svg>
    <svg class="filter-img filter-img-date"
         :class="{active: isActive()}"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
         @click="clearFilter"
         v-if="iconDate">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288
    14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448
    112V160H0V112C0 85.49 21.49 64 48 64H96V32zM448 464C448 490.5 426.5 512 400
    512H48C21.49 512 0 490.5 0 464V192H448V464z"
                fill="currentColor"/>
        </g>
    </svg>
</template>

<script>
import {useI18n} from "vue-i18n/index";

export default {
    name: 'FilterDate',
    props: ['columnName', 'filterSelect', 'filterInput', 'iconDate'],
    emits: ['show-filter', 'clear-filter'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const selectFilterTranslator = {
            'eq': i18n.t('nodrizaTable.eq'),
            'aT': i18n.t('nodrizaTable.aT'),
            'bT': i18n.t('nodrizaTable.bT'),
            'empty': i18n.t('nodrizaTable.empty'),
            'between': i18n.t('nodrizaTable.between')
        };
        const getFilterLabel = () => {
            const select = props.filterSelect;
            const input = props.filterInput;
            if (!select && (!input || ((!input.from || !input.from.length) && (!input.to || !input.to.length)))) {
                return i18n.t('nodrizaTable.filter');
            }
            if (select === 'between') {
                let textToShow = '';
                if (input && input.from) {
                    textToShow += `${i18n.t('nodrizaTable.from')}: ${input.from}`;
                }
                if (input && input.to) {
                    if (textToShow && textToShow.length) {
                        textToShow += ' ';
                    }
                    textToShow += `${i18n.t('nodrizaTable.to')}: ${input.to}`;
                }
                return textToShow;
            }
            const selectLabel = select && select.length ? selectFilterTranslator[(select)] : '';
            const inputLabel = (input && input.from) ? `: ${input.from}` : '';
            return `${selectLabel}${inputLabel}`;
        };
        const isActive = () => {
            return props.filterSelect && props.filterSelect.length;
        };
        const showFilter = ($event) => emit('show-filter', {event: $event, columnName: props.columnName});
        const clearFilter = () => {
            if (!isActive()) {
                return;
            }
            emit('clear-filter', props.columnName);
        };
        return {
            getFilterLabel,
            showFilter,
            clearFilter,
            isActive
        };
    }
}
</script>
