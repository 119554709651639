<template>

    <div class="body">

        <router-link class="returnLink" to="/">&lt; Volver</router-link>
        
        <h1 class="ticketReceived" v-if="this.ticketType === 'SC1'">¡Gracias por tu ticket &#128170; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'SC2'">¡Gracias por tu ticket &#128169; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Pulpo IMI'">¡Gracias por tu ticket &#128025; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Lean'">¡Gracias por tu ticket 	&#9201;&#65039; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Smart Decision'">¡Gracias por tu ticket &#128161; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === '4E'">¡Gracias por tu ticket &#129304; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Autoformación'">¡Gracias por tu ticket &#128214; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Gracias'">¡Gracias por tu ticket &#129303; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Ahorro de Costes'">¡Gracias por tu ticket &#128201; {{this.ticketType}}, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Aumento de Facturación'">¡Gracias por tu ticket &#128200; Aumento de Facturación, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Crosselling'">¡Gracias por tu ticket &#128279; Cross-selling, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Upselling'">¡Gracias por tu ticket &#129513; Up-selling, {{userName}}!</h1>
        <h1 class="ticketReceived" v-if="this.ticketType === 'Sugerencia'">¡Gracias por tu ticket &#128270; Sugerencia, {{userName}}!</h1>

        <div>

            <h2 class="ticketReceived">{{this.notification}}</h2>

            <h3 class="ticketReceived">Puedes <router-link class="ticketReceived" to="/user/tickets">consultar tus tickets enviados</router-link> en cualquier momento.</h3>

        </div>

    </div>

  
</template>

<script>


import LocalStorageService from "@/services/storage/LocalStorageService";

export default {
    props: {
        ticketType: {
            type: String,
            required: true,
            default: ''
        },
        notification: {
            type: String,
            required: true,
            default: 'Notificación recibida'
        }
    },
    data() {
        return {
            userName: ''
        }
    },
    created() {

        this.userName = LocalStorageService.getService().getUserName()
    }
}

</script>

<style scoped>

    /* labels */

    a {
        color: #ED3F7C;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
    }

    a:link.returnLink {
        text-decoration: none;
    }

    a:visited.returnLink {
        text-decoration: none;
    }

    a:hover:not(.returnLink) {
        color: #ff669c;
    }

    a:hover.returnLink {
        text-decoration: underline;
    }

    a:active.returnLink {
        text-decoration: underline;
    }

    /* hs */

    h1.ticketReceived {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 35px;
        font-weight: bolder;
        margin-top: 75px;
        padding: 20px;
    }

    h2.ticketReceived {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: 300;
        padding: 20px;
    }

    h3.ticketReceived {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 500;
        padding: 50px;
    }


    /* ids */

    .returnLink {
        margin-left: -50px;
    }

    .body {
      min-height: 90%;
    }

</style>