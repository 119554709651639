const FilterValidateUrlStorage = (function(){

    var _service;

    function _getService() {
        if(!_service) {
          _service = this;
          localStorage.setItem('url', '');
          return _service
      }
      return _service
    }

    function _setStorageFilterUrl(url) {
        localStorage.setItem('url', url);
    }

    function _getStorageFilterUrl() {
      return localStorage.getItem('url');
    }

    function _clearStorageFilterUrl() {
        localStorage.setItem('url', '');
      }

   return {
        getService: _getService,
        setStorageFilterUrl : _setStorageFilterUrl,
        getStorageFilterUrl : _getStorageFilterUrl,
        clearStorageFilterUrl : _clearStorageFilterUrl
    }

})();

export default FilterValidateUrlStorage;