import axios from 'axios';
import LocalStorageService from './LocalStorageService';

// LocalstorageService
const localStorageService = LocalStorageService.getService()

// axios instance

const customAxios = axios.create({
    baseURL: `${process.env.VUE_APP_BACK_HOST}/api`,
    timeout: 10000, 
    headers: { 
        "Content-Type": "application/json"
    }
})

// Add a request handler

const requestHandler = request => {

    if (!request.url.includes(process.env.VUE_APP_STORE)) {
        request.headers['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
    }
        
  
    return request;
}

// Add a response handler

const responseHandler = (response) => {

    switch (response.status) {
        case 200:
            return response;
            
        default:
            response.errorMessage = 'Ha ocurrido un error inesperado. Inténtalo de nuevo.';
            break;
    }
}

const errorResponseHandler = (error) => {

    switch (error.response.status) {
        case 401:
            error.errorMessage = 'Tu sesión ha caducado. Vuelve a iniciar sesión para continuar.';
            break;

        case 403: 
            error.errorMessage = 'Permiso denegado.';
            break;

        case 404:
            error.errorMessage = 'No encontrado.';
            break;

        default:
            error.errorMessage = 'Ha ocurrido un error inesperado. Inténtalo de nuevo.';
            break;
    }

    return error;
}



// interceptor for request and response

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
)

 customAxios.interceptors.response.use(
    response => responseHandler(response),
    error => errorResponseHandler(error));

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios