<template>

  <div class="loader loader-pink">
    <div class="loader-spined">
      <div class="loader--icon">
        <div class="offset-100deg">
          <div class="rocket">
            <div class="rocket-body">
              <div class="body"></div>
              <div class="fin fin-left"></div>
              <div class="fin fin-right"></div>
              <div class="window"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pufs">
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
    </div>
    <div class="particles">
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
      <i></i><i></i><i></i>
    </div>
  </div>

</template>

<script>
export default {
  name: "LoaderComponent"
}
</script>

<style lang="scss" scoped>

// Mixins
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {@content}
  @-moz-keyframes #{$name} {@content}
  @-ms-keyframes #{$name} {@content}
  @keyframes #{$name} {@content}
}

// Variables
$debug: 0;
$animationTime: 3s;
$pufSize: 7px;
$pufCount: 45;
$intervalDegree: 360 / $pufCount;
$smokeRatioDisapear: 60%;
$particleMap: (
    _1: particle,
    _2: particle-o
);

//Colors
$pink: #ED3F7c;

$black: #000000;
$blue: #7ecbd3;



div:not(.pufs, .particles) {
  @if ($debug == 1) {
    border: 1px solid $blue;
  }
}

/*---------------------------*/
/*-- Rocket Spinner Styles --*/
/*---------------------------*/
.loader {
  width: 100px;
  height: 100px;

}

//Rocket offset
.offset-100deg {
  transform: translateY(-49%) rotate(100deg);
  padding-left: 50px;
  padding-bottom: 75px;

}

// Modifier (on pink)
.loader-white {
  .pufs {
    > i {
      &:after {
        animation-name: puf-pink;
      }
    }
  }
}

// Animate the loader main icon (spin/orbit)
.loader-spined {
  top: 0;
  right:0;
  left:0;
  bottom: 0;
  z-index: 100;
  position: absolute;
  display: block;
  animation: orbit $animationTime linear infinite;
}

// Rocket body

.rocket {
  position: relative;
  width: 80px;
  left: calc(50% - 60px);
  transform: scale(0.15 );

  .rocket-body {
    width: 80px;
    left: calc(50% - 50px);

    .body {
      background-color: $pink;
      height: 180px;
      left: calc(50% - 50px);
      border-radius: 100% 100% 50% 50%;
      border-top: 5px solid $black;
    }

    &:before {
      content: '';
      position: absolute;
      left: calc(50% - 24px);
      width: 48px;
      height: 13px;
      background-color: $black;
      bottom: -13px;
      border-bottom-right-radius: 60%;
      border-bottom-left-radius: 60%;
    }
  }

  .window {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $blue;
    left: calc(50% - 25px);
    top: 40px;
    border: 5px solid $black;
  }

  .fin {
    position: absolute;
    z-index: -100;
    height: 55px;
    width: 50px;
    background-color: $blue;
  }

  .fin-left {
    left: -30px;
    top: calc(100% - 55px);
    border-top-left-radius: 80%;
    border-bottom-left-radius: 20%;
  }

  .fin-right {
    right: -30px;
    top: calc(100% - 55px);
    border-top-right-radius: 80%;
    border-bottom-right-radius: 20%;
  }

}


@include keyframes(orbit) {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

// Loader icon styles
.loader--icon {
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 26px;
  color: darken($blue, 40%);

}

// Smoke
.pufs {
  top: 0;
  right:0;
  left:0;
  bottom: 0;
  display: block;
  position: absolute;

  > i {
    // Use specific fontawesome icon
    font: normal normal normal 14px/1 FontAwesome;
    display: block;
    top: 0;
    right:0;
    left:0;
    bottom: 0;
    position: absolute;

    &:after {
      content: "\f111";
      height: $pufSize;
      width: $pufSize;
      position: relative;
      border-radius: 100%;
      display: block;
      margin: 0 auto;
      top: $pufSize;
      font-size: 9px;
      opacity: 0;

      animation-name: puf;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      animation-duration: $animationTime;
    }

    @for $i from 1 through $pufCount {
      $pufRatio: $i * 100 / $pufCount;
      $animationTimeDelay: $animationTime * $pufRatio / 100;
      &:nth-child(#{$i}) {
        transform: rotate(#{$i * $intervalDegree}deg);
      }
      &:nth-child(#{$i}):after {
        animation-delay: $animationTimeDelay;
        @if $i % 2 == 0 {
          margin-top: 1px;
        } @else {
          margin-top: -1px;
        }
      }
    }
  }
}



.particles {
  position: absolute;
  display: block;
  top: 0;
  right:0;
  left:0;
  bottom: 0;

  > i {
    font: normal normal normal 14px/1 FontAwesome;
    display: block;
    top: 0;
    right:0;
    left:0;
    bottom: 0;
    position: absolute;

    &:after {
      content: "\f111";
      height: $pufSize;
      width: $pufSize;
      position: relative;
      border-radius: 100%;
      display: block;
      margin: 0 auto;
      top: $pufSize;
      font-size: 2px;
      opacity: 0; // INITIAL STATE

      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      animation-duration: $animationTime;
    }

    @for $i from 1 through $pufCount {
      $pufRatio: $i * 100 / $pufCount;
      $animationTimeDelay: $animationTime * $pufRatio / 100;
      &:nth-child(#{$i}) {
        transform: rotate(#{$i * $intervalDegree}deg);
      }
      &:nth-child(#{$i}):after {
        animation-delay: $animationTimeDelay;
        //@if $i % 3 == 0 {
        @if $i % 3 == 0 {
          animation-name: map-get($particleMap, _1);
        } @else {
          animation-name: map-get($particleMap, _2);
        }
      }
    }
  }
}

// Animation for smoke (on blue)
@include keyframes(puf) {
  0% {
    opacity: 1;
    color: darken($pink, 10%);
    transform: scale(1);
  }
  10% {
    color: $pink;
    transform: scale(1.5);
  }
  #{$smokeRatioDisapear}: 100% {
    opacity: 0;
    color: $pink;
    transform: scale(0.4);
  }
}

// Animation for smoke (on pink)
@include keyframes(puf-pink) {
  0% {
    opacity: 1;
    color: rgba(126,203,211, 0.75);
    transform: scale(1);
  }
  10% {
    color: rgba(126,203,211, 0.9);
    transform: scale(1.5);
  }
  #{$smokeRatioDisapear}: 100% {
    opacity: 0;
    color: rgba(255,255,255,0.3);
    transform: scale(0.4);
  }

}

// Animation for particles (going down)
@include keyframes(particle) {
  0% {
    opacity: 1;
    color: black;
    margin-top: 0;
  }
  10% {
    margin-top: 15px;
  }
  75% {
    opacity: 0.5;
    margin-top: 5px;
  }
  100% {
    opacity: 0;
    margin-top: 0;
  }
}

// Animation for particles (going up)
@include keyframes(particle-o) {
  0% {
    opacity: 1;
    color: black;
    margin-top: 0;
  }
  10% {
    margin-top: -7px;
  }
  75% {
    opacity: 0.5;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 0;
  }
}

</style>