<template>

    <router-link class="returnLink" to="/">
        &lt; Volver
    </router-link>

    <div v-if="this.loaded && this.adminTickets.length >= 0">
        <h1>Consulta de tickets de NODRIZERS</h1>
        <p>Aquí puedes consultar todo sobre los tickets que los NODRIZERS han enviado</p>
    </div>

    <div v-show="this.loadingTickets && this.loaded">
        <SkeletonTable />
    </div>

    <div class="table" v-show="!this.loadingTickets">
        <NodrizaTable v-if="this.loaded && (this.adminTickets.length >= 0)" 
            ref="table" 
            :mode="'local'"
            :columns="columns" 
            :rows="rows" 
            :totalRows="rows.length" 
            :perPage="10" 
            :selectable="false"
            :paginationOptions="{ dropdownAllowAll: false, perPage: 10, perPageDropdown: [10, 20, 50, 100, 200, 500] }"
            :filterDialogOptions="{ dialogFilterDate: { aT: true, bT: true, between: true } }" 
            @requestData="getRows"
            @on-row-click="goToTicketRow">
            <template v-slot:change-field="props">
                <RedirectButtonComponent class="button" text="Ver ticket" render="ticket"
                    v-if="props.props.column.field === 'button' && props.props.row.id !== null"
                    @click="goToTicket(props.props.row.id)" />
                <p v-if="props.props.column.field === 'type'" class="pink_mark">
                    {{ props.props.row.type }}
                </p>    
                <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 1" class="orange_mark">
                    {{ props.props.row.status }}
                </p>
                <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 2" class="green_mark">
                    {{ props.props.row.status }}
                </p>
                <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 3" class="red_mark">
                    {{ props.props.row.status }}
                </p>
            </template>
        </NodrizaTable>
    </div>

    <div class="loader" v-if="!this.loaded && !this.error">
        <LoaderComponent />
    </div>

</template>

<script>

    import axios from '@/services/storage/axios';
    import LoaderComponent from '@/components/LoadersComponents/LoaderComponent'
    import NodrizaTable from '@/components/NodrizaTable/nodriza/table/NodrizaTable';
    import SkeletonTable from '@/components/NodrizaTable/skeletons/SkeletonTable';
    import RedirectButtonComponent from '@/components/PersonalizedTableElements/RedirectButtonComponent';
    import LocalStorageService from '@/services/storage/LocalStorageService';

    import { getPossibleFilters } from "@/AppNT/endpointsCalls/getPossibleFilters";
    import { dateStringDDMMYYYYToTimestamp } from "@/AppNT/utils/dateConverter";
    import { isEmpty, constructURLDates, getLeaderTicketsRows } from '@/AppNT/utils/tableUtils';
    import { departmentsFromCompany } from "@/AppNT/utils/departmentsFromCompany";
    import { arraysAreEqual } from "@/AppNT/utils/compareArrays";
    import { statusNameToId, authorNameToId, typeNameToId, departmentNameToId, companyNameToId } from "@/AppNT/utils/nameToIdConverter";
    import { statusIdToName, authorIdToName, typeIdToName, departmentIdToName, companyIdToName } from "@/AppNT/utils/idToNameConverter";

    export default {

        name: "AdminTicketsView",

        components: {
            RedirectButtonComponent,
            NodrizaTable,
            LoaderComponent,
            SkeletonTable
        },

        data() {
            return {
                loaded: false, 
                loadingTickets: false,
                adminTickets: [],
                localStorage: null,
                filtersPreset: {},
                activeFilters: [],
                filteredTickets: [],
                oldColumnFiltered: [],
                error: false,
                errorMessage: null,
                date: null,
                loadingFilter: false,
                companyFiltered: null,

                authorsNames: [],
                possibleAuthors: [],
                oldAuthorFiltered: null,

                companiesNames: [],
                possibleCompanies: [],
                oldCompanyFiltered: null,

                departmentNames: [],
                possibleDepartments: [],
                oldDepartmentFiltered: null,

                ticketTypesNames: [],
                possibleTypes: [],
                oldTypeFiltered: null,

                ticketStatusNames: [],
                possibleStatus: [],
                oldStatusFiltered: null,

                oldDateToCreated: null,
                oldDateFromCreated: null,
                oldDateAfterCreated: null,
                oldDateBeforeCreated: null,
                oldIntervalCreated: null,

                oldDateToValidated: null,
                oldDateFromValidated: null,
                oldDateAfterValidated: null,
                oldDateBeforeValidated: null,
                oldIntervalValidated: null,

                oldDateCreatedAtFiltered: null,
                oldDateValidatedAtFiltered: null,

                columns: [
                ],
                rows: [
                ],
            }
        },

        async created() {

            this.localStorage = LocalStorageService.getService();

            await this.getFilters();
            await this.getRows();

            if (this.localStorage.getActiveFilters() != 'null' && this.localStorage.getActiveFilters() != null) {

                this.loadingTickets = true;
                const activeFilters = JSON.parse(this.localStorage.getActiveFilters());

                this.filtersPreset.status = statusIdToName(activeFilters.status, this.possibleStatus);
                this.filtersPreset.author = authorIdToName(activeFilters.author, this.possibleAuthors);
                this.filtersPreset.company = companyIdToName(activeFilters.company, this.possibleCompanies);
                this.filtersPreset.department = departmentIdToName(activeFilters.department, this.possibleDepartments);
                this.filtersPreset.type = typeIdToName(activeFilters.type, this.possibleTypes);

                this.localStorage.clearActiveFilters();
            }

            if (this.checkNullsFiltersPreset()) {
                this.filtersPreset = {};
            }

            this.pushColumns();

            this.loaded = true;
        },

        methods: {

            async getRows(filters) {

                try {

                    if (filters && filters.columns) {

                        filters.columns = this.deleteNotActiveFilters(filters.columns, this.activeFilters);
                    }

                    if (!filters) {

                        this.rows = await this.getTickets();
                        this.loadingTickets = false;
                    }

                    else if (Object.values(filters.columns).length > 0
                            && Object.keys(this.filtersPreset).length === 0
                            && arraysAreEqual(this.oldColumnFiltered, Object.values(filters.columns)) === false) {

                        this.oldColumnFiltered = [...Object.values(filters.columns)];

                        this.loadingFilter = true;
                        this.filtered = true;

                        this.rows = Object.values(await this.callFilters(filters.columns));

                        this.loadingFilter = false;
                        this.loadingTickets = false;
                    }

                    else if (Object.values(filters.columns).length > 0
                        && this.compareFiltersPresetAndColumns(filters)
                        && arraysAreEqual(this.oldColumnFiltered, Object.values(filters.columns)) === false) {

                        this.oldColumnFiltered = [...Object.values(filters.columns)];

                        this.loadingFilter = true;
                        this.filtered = true;

                        this.rows = Object.values(await this.callFilters(filters.columns));

                        this.loadingFilter = false;
                        this.loadingTickets = false;

                        this.filtersPreset = {};
                    }

                    else if (isEmpty(filters.columns) && this.filtered) {

                        this.oldColumnFiltered = [];
                        this.rows = await this.getTickets();
                        let emptyObject = {};

                        if (this.rows.length < 10) {
                            for (let i = this.rows.length; i < 10; i++) {
                                this.rows.push(emptyObject);
                            }
                        }

                        this.filtered = false;
                        this.loadingTickets = false;
                    }
                }
                catch (e) {

                    console.error('Filter request error ', e);
                }
            },

            async getFilters() {

                let possibleFiltersData = await getPossibleFilters('admin');

                this.authorsNames = possibleFiltersData.authors_data.authors_names;
                this.possibleAuthors = possibleFiltersData.authors_data.authors;

                this.companiesNames = possibleFiltersData.company_data.companies_names;
                this.possibleCompanies = possibleFiltersData.company_data.companies;

                this.departmentNames = [];
                this.possibleDepartments = possibleFiltersData.department_data.departments;

                this.ticketTypesNames = possibleFiltersData.types_data.types_names;
                this.possibleTypes = possibleFiltersData.types_data.types;

                this.ticketStatusNames = possibleFiltersData.status_data.status_names;
                this.possibleStatus = possibleFiltersData.status_data.status;

            },

            async callFilters(columns) {

                //Si la columna status existe y la key 'status' no esta entre los filtros activos
                //                            O
                //Si la columna status existe, la key 'status' esta entre los filtros activos y el
                //status del primer ticket es distinto al valor de la columna status

                if (columns.status && !('status' in this.activeFilters)
                    ||
                    (columns.status && ('status' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.status && 'status' in this.activeFilters
                        &&
                        this.oldStatusFiltered !== columns.status.value)) {

                    this.oldStatusFiltered = columns.status.value;

                    if (this.isValidating && !this.validateStatusChanged) {
                        this.validateStatusChanged = true;
                    }

                    this.activeFilters['status'] = statusNameToId(this.oldStatusFiltered, this.possibleStatus);

                }

                //Si la columna type existe y la key 'type' no esta entre los filtros activos
                //                            O
                //Si la columna type existe, la key 'type' esta entre los filtros activos y el
                //tipo del primer ticket es distinto al valor de la columna type

                if (columns.type && !('type' in this.activeFilters)
                    ||
                    (columns.type && ('type' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.type && 'type' in this.activeFilters
                        &&
                        this.oldTypeFiltered !== columns.type.value)) {

                    this.oldTypeFiltered = columns.type.value;
                    this.activeFilters['type'] = typeNameToId(this.oldTypeFiltered, this.possibleTypes);
                }

                if (columns.author && !('author' in this.activeFilters)
                    ||
                    (columns.author && ('author' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.author && 'author' in this.activeFilters
                        &&
                        this.oldAuthorFiltered !== columns.author.value)) {

                    this.oldAuthorFiltered = columns.author.value;
                    this.activeFilters['author'] = authorNameToId(this.oldAuthorFiltered, this.possibleAuthors);

                }

                if (columns.company && !('company' in this.activeFilters)
                    ||
                    (columns.company && ('company' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.company && 'company' in this.activeFilters
                        &&
                        this.oldCompanyFiltered !== columns.company.value)) {

                    const departmentsTmp = [];
                    const columnDepartment = this.columns.find((column) => column.field === 'department');
                    this.companyFiltered = columns.company.value;

                    this.possibleDepartments.forEach(dep => {
                        if (dep.company_name === this.companyFiltered) {
                            departmentsTmp.push(dep.dept_name)
                        }
                    });

                    columnDepartment.filterOptions.filterDropdownItems = departmentsTmp;

                    if (this.filtersPreset.department === undefined || this.filtersPreset.department === null) {

                        columnDepartment.select = '';
                        columnDepartment.input = null;

                        delete columns.department;
                    }

                    columns = this.deleteNotActiveFilters(columns, this.activeFilters);


                    this.oldCompanyFiltered = columns.company.value;
                    this.validateFilter = true;
                    this.leaderDepartmentsNames = departmentsFromCompany(this.oldCompanyFiltered, this.possibleDepartments);
                    this.activeFilters['company'] = companyNameToId(this.oldCompanyFiltered, this.possibleCompanies);

                }


                if (columns.department && !('department' in this.activeFilters)
                    ||
                    (columns.department && ('department' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.department && 'department' in this.activeFilters
                        &&
                        this.oldDepartmentFiltered !== columns.department.value)) {


                    this.oldDepartmentFiltered = columns.department.value;
                    this.validateFilter = true;
                    this.activeFilters['department'] = departmentNameToId(this.oldDepartmentFiltered, this.possibleDepartments);

                }

                //Si la columna status created_at y la key 'created_at' no esta entre los filtros activos
                //                            O
                //Si la columna created_at existe, la key 'created_at' esta entre los filtros activos y el
                //created_at del primer ticket es distinto al valor de la columna created_at

                if (columns.created_at && !('created_at' in this.activeFilters)
                    ||
                    (columns.created_at && ('created_at' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.created_at && 'created_at' in this.activeFilters
                        &&
                        this.oldDateCreatedAtFiltered !== [...Object.values(columns.created_at)])) {

                    if (columns.created_at.type === 'between'
                        && (this.oldDateFromCreated !== columns.created_at.value[0] || this.oldDateToCreated !== columns.created_at.value[1])) {

                        this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)]

                        //if (dateStringDDMMYYYYToTimestamp(columns.created_at.value[0]) < dateStringDDMMYYYYToTimestamp(columns.created_at.value[1])) {
                        this.oldDateFromCreated = columns.created_at.value[0];
                        this.oldDateToCreated = columns.created_at.value[1];
                        this.oldIntervalCreated = columns.created_at.type;


                        this.activeFilters['created_at'] = {
                            from: dateStringDDMMYYYYToTimestamp(this.oldDateFromCreated),
                            to: dateStringDDMMYYYYToTimestamp(this.oldDateToCreated),
                            interval: this.oldIntervalCreated,
                        }

                    } else if (columns.created_at.type === 'LT'
                        && (this.oldIntervalCreated !== columns.created_at.type
                            || this.oldDateBeforeCreated !== columns.created_at.value)) {

                        this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
                        this.oldDateBeforeCreated = columns.created_at.value;
                        this.oldIntervalCreated = columns.created_at.type;

                        this.activeFilters['created_at'] = {
                            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeCreated),
                            interval: 'before',
                        };


                    } else if (columns.created_at.type === 'GT'
                        && (this.oldIntervalCreated !== columns.created_at.type
                            || this.oldDateAfterCreated !== columns.created_at.value)) {

                        this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
                        this.oldDateAfterCreated = columns.created_at.value;
                        this.oldIntervalCreated = columns.created_at.type;

                        this.activeFilters['created_at'] = {
                            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterCreated),
                            interval: 'after',
                        };
                    }
                }

                //Si la columna status existe y la key 'status' no esta entre los filtros activos
                //                            O
                //Si la columna status existe, la key 'status' esta entre los filtros activos y el
                //status del primer ticket es distinto al valor de la columna status

                if (columns.validated_at && !('validated_at' in this.activeFilters)
                    ||
                    (columns.validated_at && ('validated_at' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.validated_at && 'validated_at' in this.activeFilters
                        &&
                        this.oldDateValidatedAtFiltered !== [...Object.values(columns.validated_at)])) {

                    if (columns.validated_at.type === 'between'
                        && (this.oldDateFromValidated !== columns.validated_at.value[0] || this.oldDateToValidated !== columns.validated_at.value[1])) {

                        this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];

                        if (dateStringDDMMYYYYToTimestamp(columns.validated_at.value[0]) < dateStringDDMMYYYYToTimestamp(columns.validated_at.value[1])) {

                            this.oldDateFromValidated = columns.validated_at.value[0];
                            this.oldDateToValidated = columns.validated_at.value[1];
                            this.oldIntervalValidated = columns.validated_at.type;

                            this.activeFilters['validated_at'] = {
                                from: dateStringDDMMYYYYToTimestamp(this.oldDateFromValidated),
                                to: dateStringDDMMYYYYToTimestamp(this.oldDateToValidated),
                                interval: this.oldIntervalValidated,
                            };
                        }

                    } else if (columns.validated_at.type === 'LT'
                        && (this.oldIntervalValidated !== columns.validated_at.type
                            || this.oldDateBeforeValidated !== columns.validated_at.value)) {

                        this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];
                        this.oldDateBeforeValidated = columns.validated_at.value;
                        this.oldIntervalValidated = columns.validated_at.type;

                        this.activeFilters['validated_at'] = {
                            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeValidated),
                            interval: 'before',
                        };


                    } else if (columns.validated_at.type === 'GT'
                        && (this.oldIntervalValidated !== columns.validated_at.type
                            || this.oldDateAfterValidated !== columns.validated_at.value)) {

                        this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];
                        this.oldDateAfterValidated = columns.validated_at.value;
                        this.oldIntervalValidated = columns.validated_at.type;

                        this.activeFilters['validated_at'] = {
                            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterValidated),
                            interval: 'after',
                        };

                    }

                }

                this.filteredTickets = await this.getFilteredTicketsAdmin();


                return this.filteredTickets;
            },

            compareFiltersPresetAndColumns(filters) {

                // filters preset

                let comparingArrayPresets = [];
                let comparingArrayColumns = [];
                let equals = true;

                for (let preset in this.filtersPreset) {

                    if (this.filtersPreset[preset] != null) {

                        comparingArrayPresets[preset] = this.filtersPreset[preset];
                    }
                }

                for (let filter in filters.columns) {

                    comparingArrayColumns[filter] = filters.columns[filter].value;
                }

                if (arraysAreEqual(Object.keys(comparingArrayColumns).sort(), Object.keys(comparingArrayPresets).sort())) {

                    for (let field in this.filtersPreset) {

                        if (comparingArrayPresets[field] != comparingArrayColumns[field]) {

                            equals = false;
                            break;
                        }
                    }

                } else {

                    equals = false;
                }

                return equals;
            },

            deleteNotActiveFilters(columns) {

                if (!columns.status && ('status' in this.activeFilters)) {
                    delete this.activeFilters['status'];
                }

                if (!columns.type && ('type' in this.activeFilters)) {
                    delete this.activeFilters['type'];
                }

                if (!columns.author && ('author' in this.activeFilters)) {
                    delete this.activeFilters['author'];
                }

                if (!columns.department && ('department' in this.activeFilters)) {
                    delete this.activeFilters['department'];
                }

                if (!columns.company && ('company' in this.activeFilters)) {

                    delete this.activeFilters['company'];
                    delete this.activeFilters['department'];

                    const columnDepartment = this.columns.find((column) => column.field === 'department');
                    columnDepartment.filterOptions.filterDropdownItems = [];
                    columnDepartment.select = '';
                    columnDepartment.input = null;

                    delete columns.department;
                }

                if (!columns.created_at && ('created_at' in this.activeFilters)) {
                    delete this.activeFilters['created_at'];
                    this.oldDateCreatedAtFiltered = [];
                }

                if (!columns.validated_at && ('validated_at' in this.activeFilters)) {
                    delete this.activeFilters['validated_at'];
                    this.oldDateValidatedAtFiltered = [];
                }

                return columns;
            },

            checkNullsFiltersPreset() {

                let allNull = true;

                for (let field in this.filtersPreset) {

                    if (this.filtersPreset[field] != null) {
                        allNull = false;
                        break;
                    }
                }

                return allNull;
            },

            async getTickets() {

                let adminTickets = [];

                const options = {
                    method: 'GET',
                    url: '/admin/tickets'
                }

                const response = await axios(options);

                if (!response.errorMessage) {

                    this.rows = [];
                    this.adminTickets = Object.values(response.data['tickets']);
                    this.adminTickets = this.adminTickets.sort((a, b) => parseInt(b.id) - parseInt(a.id));

                    adminTickets = getLeaderTicketsRows(this.adminTickets, this.possibleAuthors, this.authorsNames, this.possibleStatus);

                } else {

                    this.error = true
                    this.errorMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo.";
                }

                this.loaded = true;

                let ticketsLength = Object.keys(adminTickets).length;

                for (let i = ticketsLength; i < 10; i++) {
                    adminTickets.push({ id: null });
                }

                return adminTickets;
            },

            async getFilteredTicketsAdmin() {

                let ticketFiltered = [];
                let retrievedTicket = [];
                let url = '/filter-admin/query?';

                url = constructURLDates(url, this.activeFilters);

                if (this.activeFilters.company) {

                    url += `&company=${this.activeFilters.company}`;
                    this.departmentNames = departmentsFromCompany(this.activeFilters.company, this.possibleDepartments);
                }

                url = url.replace('?&', '?');

                const options = {
                    method: 'GET',
                    url: url
                }

                const response = await axios(options);

                if (!response.errorMessage) {

                    this.rows = [];
                    retrievedTicket = Object.values(response.data['tickets']);
                    retrievedTicket = retrievedTicket.sort((a, b) => parseInt(b.id) - parseInt(a.id));

                    ticketFiltered = getLeaderTicketsRows(retrievedTicket, this.possibleAuthors, this.authorsNames, this.possibleStatus);

                } else {

                    this.error = true;
                    this.errorMessage = "Ha ocurrido un error en el filtrado";
                }

                this.loaded = true;

                let arrayLength = Object.keys(ticketFiltered).length;

                for (let i = arrayLength; i < 10; i++) {
                    ticketFiltered.push({ id: null })
                }

                return ticketFiltered;

            },

            pushColumns() {

                const authorSize = '10%';
                const titleSize = '20%';
                const typeSize = '10%';
                const createdSize = '7%';
                const statusSize = '10%';
                const validatedSize = '7%';
                const departmentSize = '10%';
                const companySize = '10%';

                const columns = [{

                    label: 'Autor',
                    field: 'author',
                    width: authorSize,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        single: true,
                        searchable: true,
                        customFilterSelect: true,
                        filterDropdownItems: this.authorsNames,
                        preset: this.filtersPreset.author
                    },
                    select: '',
                    input: null,
                },
                {
                    label: 'Empresa',
                    field: 'company',
                    width: companySize,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        single: true,
                        searchable: true,
                        customFilterSelect: true,
                        filterDropdownItems: this.companiesNames,
                        preset: this.filtersPreset.company
                    },
                    select: '',
                    input: null,
                },
                {
                    label: 'Departamento',
                    field: 'department',
                    width: departmentSize,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        single: true,
                        searchable: true,
                        customFilterSelect: true,
                        filterDropdownItems: this.departmentNames,
                        preset: this.filtersPreset.department
                    },
                    select: '',
                    input: null,
                },
                {
                    label: 'Título',
                    field: 'title',
                    width: titleSize,
                    filterable: false,
                    filterOptions: {},
                },
                {
                    label: 'Tipo',
                    field: 'type',
                    width: typeSize,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        single: true,
                        searchable: true,
                        customFilterSelect: true,
                        filterDropdownItems: this.ticketTypesNames,
                        preset: this.filtersPreset.type
                    },
                    select: '',
                    input: null,
                },
                {
                    label: 'Enviado',
                    field: 'created_at',
                    type: 'date',
                    width: createdSize,
                    dateInputFormat: 't',
                    dateOutputFormat: 'dd/MM/yyyy',
                    filterable: false,
                    filterOptions: {
                        enabled: true,
                        customFilterDate: true,
                        iconDate: true
                    },
                    select: '',
                    input: null
                },

                {
                    label: 'Estado',
                    field: 'status',
                    tdClass: 'center-column',
                    width: statusSize,
                    sortable: true,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        single: true,
                        customFilterSelect: true,
                        filterDropdownItems: this.ticketStatusNames,
                        preset: this.filtersPreset.status
                    },
                    select: '',
                    input: null,

                },
                {
                    label: 'Validado',
                    field: 'validated_at',
                    type: 'date',
                    dateInputFormat: 't',
                    dateOutputFormat: 'dd/MM/yyyy',
                    width: validatedSize,
                    filterable: false,
                    filterOptions: {
                        enabled: true,
                        customFilterDate: true,
                        iconDate: true
                    },
                    select: '',
                    input: null
                },
                {
                    label: '',
                    labelHTML: '<img class="nodricoin-svg" src=""/>',
                    tdClass: 'center-column',
                    field: 'amount',
                    type: 'number',
                    filterable: false,
                    filterOptions: {},
                },
                {
                    label: '',
                    field: 'button',
                    type: "button",
                    sortable: false,
                    width: '10%',
                    tdClass: 'center-column',
                    filterable: false,
                    filterOptions: {},
                },
                {
                    label: '',
                    field: 'actions',
                    width: '1%',
                    filterable: false,
                    clearFilter: true,
                    filterOptions: {},
                    sortable: false,
                    type: 'button',
                    input: null
                }];

                this.columns = [...columns];
            },

            goToTicket(id) {
                this.localStorage.setActiveFilters({ ...this.activeFilters });
                this.$router.push(`/admin/ticket?id=${id}`);
            },

            goToTicketRow(params) {
                this.goToTicket(params.row.id);
            },
        }
    }

</script>

<style lang="scss" scoped>

    @import '@/styles/TableStyles/TableNTStyles.scss';

    h1 {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        padding-top: 30px;
        margin: 0 0 20px;
    }
    
    p {
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    
    .rocket {
        transform: scale(0.75);
        margin-top: 5%;
    }
    
    .motivater {
        height: 500px;
        margin: auto;
    }
    
    .table {
        font-family: 'Montserrat', sans-serif;
        margin-top: 30px;
        margin-left: 5%;
        margin-right: 5%;
        user-select: none
    }

</style>