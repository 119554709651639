<template>
    <div class="body">
        <router-link class="return_link" to="/">
            &lt; Volver
        </router-link>

        <div v-if="this.loaded">
            <h1 class="page_title">Consulta de transacciones</h1>
            <p class="page_detail">Aquí puedes consultar todos tus ingresos de NODRICOINS y tus pedidos en Tribuk Store</p>
        </div>

        <div id="nt-table" class="table" v-if="this.loaded && !this.error">
            <NodrizaTable ref="table" :mode="'local'" :columns="columns" :rows="rows" :totalRows="rows.length"
                :perPage="10"
                :paginationOptions="{dropdownAllowAll: false, perPage: 10, perPageDropdown: [10,20,50,100,200,500]}"
                :filterDialogOptions="{dialogFilterDate: {aT: true, bT: true, between: true}}" 
                @requestData="getRows"
                @on-row-click="goToDetailRow">
                <template v-slot:change-field="props">
                    <RedirectButtonComponent class="button" text="Ver ticket" render="ticket"
                        v-if="props.props.column.field === 'button' && props.props.row.source !== null && props.props.row.transaction_type === 1"
                        @click="goToTicket(props.props.row.source)" />
                    <RedirectButtonComponent class="button" text="Ver pedido" render="order"
                        v-if="props.props.column.field === 'button' && props.props.row.source !== null && props.props.row.transaction_type === 0"
                        @click="goToOrder(props.props.row.source)" />
                    <span
                        v-if="props.props.column.field === 'transaction_ticket_title' && props.props.row.transaction_type === 0">
                            <span v-for="(word, index) in props.props.row.transaction_ticket_title.split(/\s+/)" :key="index">
                                <span v-if="this.wcStatus.indexOf(word) > -1" style="font-weight: bold; color: #ed3f7c;">{{ word }}&nbsp;</span>
                                <span v-else>{{ word }}&nbsp;</span>
                            </span>
                    </span>
                    <p v-if="props.props.column.field === 'transaction_type' && props.props.row.transaction_type === 1" class="green_mark">
                        {{ this.transactionEnum[props.props.row.transaction_type] }}
                    </p>
                    <p v-if="props.props.column.field === 'transaction_type' && props.props.row.transaction_type === 0" class="red_mark">
                        {{ this.transactionEnum[props.props.row.transaction_type] }}
                    </p>
                    <p v-if="props.props.column.field === 'transaction_ticket_type'" class="pink_mark">
                        {{ props.props.row.transaction_ticket_type }}
                    </p>
                </template>
            </NodrizaTable>
        </div>

        <div class="loader" v-if="!this.loaded && !this.error">
            <LoaderComponent />
        </div>

    </div>
</template>

<script>
import axios from '@/services/storage/axios';
import NodrizaTable from '@/components/NodrizaTable/nodriza/table/NodrizaTable.vue';
import LoaderComponent from '@/components/LoadersComponents/LoaderComponent.vue';
import RedirectButtonComponent from '@/components/PersonalizedTableElements/RedirectButtonComponent.vue';

import { autologinStore } from "@/AppNT/utils/storeUtils";
import { typeNameToId } from "@/AppNT/utils/nameToIdConverter";
import { getPossibleFilters } from "@/AppNT/endpointsCalls/getPossibleFilters";
import { arraysAreEqual } from "@/AppNT/utils/compareArrays";
import { dateStringDDMMYYYYToTimestamp } from "@/AppNT/utils/dateConverter";
import { deleteNotActiveFilters, getUserTransactionsRows, constructURLDates } from '@/AppNT/utils/tableUtils/util';
import { OrderStatusWCEnum } from "@/AppNT/enum/OrderStatusWCEnum.js";
import {TransactionTypeEnum} from "@/AppNT/enum/TransactionTypeEnum";

export default {

    name: "UserTransactions",

    components: {
        NodrizaTable,
        LoaderComponent,
        RedirectButtonComponent
    },

    data() {
        return {
            activeFilters: [],
            columns: [],
            error: false,
            filtered: false,
            filteredTransactions: [],
            loaded: false,
            loadingFilter: false,

            oldColumnFiltered: null,
            oldTransactionTypeFiltered: null,
            oldTypeFiltered: null,
            oldDateCreatedAtFiltered: null,
            oldDateFromCreated: null,
            oldDateToCreated: null,
            oldIntervalCreated: null,

            possibleTypes: [],
            rows: [],
            ticketTypesNames: [],
            user_transactions: [],

            wcStatus: Object.values(OrderStatusWCEnum),
            transactionEnum: Object.values(TransactionTypeEnum)
        }
    },

    async created() {
        await this.getFilters();
        await this.getRows();
    },

    methods: {
        async getFilters() {

            let possibleFiltersData = await getPossibleFilters('user-manager');

            this.ticketTypesNames = possibleFiltersData.types_data.types_names;
            this.possibleTypes = possibleFiltersData.types_data.types;
        },

        async getUserTransactions() {

            const options = {
                method: 'GET',
                url: '/user/transactions'
            }

            const response = await axios(options);

            if (!response.errorMessage) {

                this.rows = [];
                this.user_transactions = Object.values(response.data['user_transactions']);

                this.user_transactions.forEach(transaction => {
                    if ((+ new Date(transaction['created_at'])) / 1000 === 0) {
                        transaction['created_at'] = null;
                    } else {
                        transaction['created_at'] = (+ new Date(transaction['created_at'])) / 1000;
                    }

                    let elements = transaction['wc_quantity'] === 1
                        ? 'elemento'
                        : transaction['wc_quantity'] === 0 || transaction['wc_quantity'] > 1
                            ? 'elementos'
                            : '';

                    let transaction_details = transaction['wc_status'] === null
                        ? transaction['transaction_ticket_title']
                        : transaction['wc_status'] != null && transaction['wc_quantity'] != null
                            ? `Tu pedido se encuentra en estado ${this.wcStatus[transaction['wc_status']]} y contiene ${transaction['wc_quantity']} ${elements}`
                            : null;

                    transaction['transaction_ticket_title'] = transaction_details;
                })

                this.pushColumns();

            } else {

                this.error = true;
                this.errorMessage = response.errorMessage;
            }

            this.loaded = true;

            return this.user_transactions;
        },

        async getRows(filters) {

            try {

                if (!filters) {

                    this.rows = await this.getUserTransactions();
                }

                else if (Object.values(filters.columns).length > 0
                    && !arraysAreEqual(this.oldColumnFiltered, Object.values(filters.columns))) {

                    this.oldColumnFiltered = [...Object.values(filters.columns)];

                    this.loadingFilter = true;
                    this.filtered = true;

                    await deleteNotActiveFilters(filters.columns, this.activeFilters);
                    this.rows = Object.values(await this.callFilters(filters.columns));
                    this.loadingFilter = false;
                }

                else if (Object.entries(filters.columns).length === 0 && this.filtered) {

                    this.oldColumnFiltered = [];
                    await deleteNotActiveFilters(filters.columns, this.activeFilters);
                    this.rows = await this.getUserTransactions();
                    this.filtered = false;

                    let emptyObject = {}

                    if (this.rows.length < 10) {
                        for (let i = this.rows.length; i < 10; i++) {
                            this.rows.push(emptyObject)
                        }
                    }
                }

            } catch (e) {
                console.error('Filter request error ', e);

            }
        },

        pushColumns() {

            this.columns = [];
            const createdSize = '6%';
            const operationSize = '6%';
            const titleSize = '50%';
            const typeSize = '10%';
            const orderSize = '8%';
            const amountSize = '5%';
            const buttonSize = '10%';

            this.columns.push(
                {
                    label: 'Fecha',
                    field: 'created_at',
                    type: 'date',
                    width: createdSize,
                    dateInputFormat: 't',
                    dateOutputFormat: 'dd/MM/yyyy',
                    filterable: false,
                    filterOptions: {
                        enabled: true,
                        customFilterDate: true,
                        iconDate: true
                    },
                },
                {
                    label: 'Operación',
                    field: 'transaction_type',
                    tdClass: 'center-column',
                    width: operationSize,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        multiple: false,
                        searchable: true,
                        customFilterSelect: true,
                        filterDropdownItems: ['INGRESO', 'PEDIDO']
                    },
                },
                {
                    label: 'Detalles',
                    field: 'transaction_ticket_title',
                    width: titleSize,
                    filterable: true,
                    filterOptions: {},
                },
                {
                    label: 'Tipo',
                    field: 'transaction_ticket_type',
                    width: typeSize,
                    filterable: true,
                    filterOptions: {
                        enabled: true,
                        multiple: false,
                        searchable: true,
                        customFilterSelect: true,
                        filterDropdownItems: this.ticketTypesNames
                    },
                    select: '',
                    input: null,
                },
                {
                    label: 'Pedido en tienda',
                    field: 'order_id',
                    type: 'number',
                    width: orderSize,
                    filterable: true,
                    filterOptions: {},
                },
                {
                    label: '',
                    labelHTML: '<img class="nodricoin-svg" src=""/>',
                    tdClass: 'center-column',
                    field: 'amount',
                    type: 'number',
                    width: amountSize,
                    filterable: false,
                    filterOptions: {},
                },
                {
                    label: '',
                    field: 'button',
                    type: 'button',
                    sortable: false,
                    width: buttonSize,
                    tdClass: 'center-column',
                    filterable: false,
                    filterOptions: {},
                },
                {
                    label: '',
                    width: '1%',
                    field: 'actions',
                    filterable: false,
                    clearFilter: true,
                    filterOptions: {},
                    sortable: false,
                    type: 'button',
                    input: null
                }
            );

        },

        async callFilters(columns) {

            if (columns.transaction_type && !('transaction_type' in this.activeFilters)
                ||
                (columns.transaction_type && ('transaction_type' in this.activeFilters) && this.rows.length === 0)
                ||
                (columns.transaction_type && 'transaction_type' in this.activeFilters
                    &&
                    this.oldTransactionTypeFiltered !== columns.transaction_type.value)) {

                this.oldTransactionTypeFiltered = columns.transaction_type.value;
                this.activeFilters['transaction_type'] = this.oldTransactionTypeFiltered;
            }

            if (columns.transaction_ticket_type && !('transaction_ticket_type' in this.activeFilters)
                ||
                (columns.transaction_ticket_type && ('transaction_ticket_type' in this.activeFilters) && this.rows.length === 0)
                ||
                (columns.transaction_ticket_type && 'transaction_ticket_type' in this.activeFilters
                    &&
                    this.oldTypeFiltered !== columns.transaction_ticket_type.value)) {

                this.oldTypeFiltered = columns.transaction_ticket_type.value;
                this.activeFilters['transaction_ticket_type'] = typeNameToId(this.oldTypeFiltered, this.possibleTypes);
            }

            if (columns.created_at && !('created_at' in this.activeFilters)
                ||
                (columns.created_at && ('created_at' in this.activeFilters) && this.rows.length === 0)
                ||
                (columns.created_at && 'created_at' in this.activeFilters
                    &&
                    this.oldDateCreatedAtFiltered !== [...Object.values(columns.created_at)])) {

                if (columns.created_at.type === 'between'
                    && (this.oldDateFromCreated !== columns.created_at.value[0] || this.oldDateToCreated !== columns.created_at.value[1])) {

                    this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
                    this.oldDateFromCreated = columns.created_at.value[0];
                    this.oldDateToCreated = columns.created_at.value[1];
                    this.oldIntervalCreated = columns.created_at.type;

                    this.activeFilters['created_at'] = {
                        from: dateStringDDMMYYYYToTimestamp(this.oldDateFromCreated),
                        to: dateStringDDMMYYYYToTimestamp(this.oldDateToCreated),
                        interval: this.oldIntervalCreated,
                    };


                } else if (columns.created_at.type === 'LT'
                    && (this.oldIntervalCreated !== columns.created_at.type
                        || this.oldDateBeforeCreated !== columns.created_at.value)) {

                    this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
                    this.oldDateBeforeCreated = columns.created_at.value;
                    this.oldIntervalCreated = columns.created_at.type;

                    this.activeFilters['created_at'] = {
                        from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeCreated),
                        interval: 'before',
                    };


                } else if (columns.created_at.type === 'GT'
                    && (this.oldIntervalCreated !== columns.created_at.type
                        || this.oldDateAfterCreated !== columns.created_at.value)) {

                    this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
                    this.oldDateAfterCreated = columns.created_at.value;
                    this.oldIntervalCreated = columns.created_at.type;

                    this.activeFilters['created_at'] = {
                        from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterCreated),
                        interval: 'after',
                    };
                }
            }

            this.filteredTransactions = await this.getFilteredTransactions();

            return this.filteredTransactions;
        },

        async getFilteredTransactions() {

            let transactiontFiltered = [];

            let url = "/user/filter-transactions/query?";

            url = constructURLDates(url, this.activeFilters);

            url = url.replace('?&', '?');

            let filteredUserTransactions = [];

            const options = {
                method: 'GET',
                url: url,
            }

            const response = await axios(options);

            this.rows = [];

            if (!response.errorMessage) {

                filteredUserTransactions = Object.values(response.data['filtered_transactions']);

                transactiontFiltered = getUserTransactionsRows(filteredUserTransactions);

            } else {

                this.error = true;
                this.errorMessage = 'Ha ocurrido un error en el filtrado';
            }

            this.loaded = true;

            let arrayLength = Object.keys(transactiontFiltered).length;

            for (let i = arrayLength; i < 10; i++) {
                transactiontFiltered['transaction_' + i] = { id: null };
            }

            return transactiontFiltered;

        },

        goToTicket(id) {
            this.$router.push(`/user/transactions/ticket?id=${id}`);
        },

        async goToOrder(id) {
            this.loaded = false;
            await autologinStore(id);
            this.loaded = true;
        },

        goToDetailRow(params) {
            switch (params.row.transaction_type) {
                case 0:
                    this.goToOrder(params.row.source);
                    break;    
                case 1:
                    this.goToTicket(params.row.source);
                    break;    
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/styles/CommonStyles/BasicElementsStyle.scss';
    @import '@/styles/TableStyles/TableNTStyles.scss';
</style>