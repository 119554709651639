<template>
    <div class="nodriza-table">
        <VueGoodTable theme="polar-bear"
                      styleClass="vgt-table striped"
                      :mode="mode"
                      ref="table"
                      :columns="visibleColumns"
                      :allColumns="allColumns"
                      :rows="rows"
                      :expanded-rows="expandedRows"
                      :totalRows="totalRows"
                      :perPage="perPage"
                      :trackBy="trackId"
                      :sortOptions="{enabled: true, initialSortBy: initialSort}"
                      :search-options="{enabled: false,
                                        placeholder: $t('nodrizaTable.search')}"
                      :select-options="{enabled: selectable,
                                        enabledSort: selectableSort,
                                        selectAllByPage: selectAllByPage,
                                        selectOnCheckboxOnly: selectOnCheckboxOnly,
                                        selectionText: 'filas seleccionadas',
                                        disableSelectInfo: true,
                                        clearSelectionText: 'Limpiar'}"
                      :pagination-options="{enabled: true,
                                            position: 'bottom',
                                            dropdownAllowAll: paginationOptions.dropdownAllowAll,
                                            perPage: paginationOptions.perPage,
                                            perPageDropdown: paginationOptions.perPageDropdown,
                                            nextLabel: $t('nodrizaTable.nextLabel'),
                                            prevLabel: $t('nodrizaTable.prevLabel'),
                                            rowsPerPageLabel: $t('nodrizaTable.rowsPerPageLabel'),
                                            ofLabel: $t('nodrizaTable.ofLabel'),
                                            allLabel: $t('nodrizaTable.allLabel')}"
                      @on-select-all="onSelectAll"
                      @on-selected-rows-change="onSelectionChange"
                      @on-row-click="onRowClick"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-per-page-change="onPerPageChange"
                      @on-search="onSearch"
                      @on-clear-filters="clearFilters">
            <template v-slot:table-column="props">
                <span v-if="props.column.labelHTML" v-html="props.column.labelHTML" style="display: block; text-align: center;">
                </span>
                <span v-else>
                    {{props.column.label}}
                </span>
            </template>
            <template v-slot:selected-row-actions>
                <a href="#" @click="$emit('select-all')">Select all</a>
            </template>
            <template v-slot:column-filter="props">
                <div class="filter-th-wrapper"
                     v-if="props.column.filterOptions.customFilterText">
                    <FilterText :columnName="props.column.field"
                                :filterSelect="props.column.select"
                                :filterInput="props.column.input"
                                @show-filter="(params) => showFilter('Text', params)"
                                @clear-filter="clearOneFilter"/>
                </div>
                <div class="filter-th-wrapper"
                     v-if="props.column.filterOptions.customFilterNumber">
                    <FilterNumber :columnName="props.column.field"
                                  :filterSelect="props.column.select"
                                  :filterInput="props.column.input"
                                  @show-filter="(params) => showFilter('Number', params)"
                                  @clear-filter="clearOneFilter"/>
                </div>
                <div class="filter-th-wrapper"
                     v-if="props.column.filterOptions.customFilterDate">
                    <FilterDate :columnName="props.column.field"
                                :filterSelect="props.column.select"
                                :filterInput="props.column.input"
                                :iconDate="props.column.filterOptions.iconDate"
                                @show-filter="(params) => showFilter('Date', params)"
                                @clear-filter="clearOneFilter"/>
                </div>
                <div class="filter-th-wrapper"
                     v-if="props.column.filterOptions.customFilterSelect">
                    <FilterSelect :columnName="props.column.field"
                                  :filterDropdownItems="props.column.filterOptions.filterDropdownItems"
                                  :filterInput="props.column.input"
                                  :searchable="props.column.filterOptions.searchable || false"
                                  :multiple="props.column.filterOptions.multiple"
                                  :preset="props.column.filterOptions.preset"
                                  @save-filter="(params) => saveFilter('Select', params)"
                                  @clear-filter="clearOneFilter"/>
                </div>
            </template>
            <template v-slot:expanded="slotProps" v-if="!!$slots['expandedRow']">
                <div class="expanded-field">
                    <slot name="expandedRow" :row="slotProps.row"></slot>
                </div>
            </template>
            <template v-slot:columns="slotProps">
                <div class="expanded-field" v-for="column in expandedColumns" :key="column">
                    <strong>{{ slotProps.columns.find(col => col.field === column)?.label }}:</strong>
                    <p>{{ Array.isArray(slotProps.row[column]) ? slotProps.row[column].join(', '): slotProps.row[column] }}</p>
                </div>
            </template>
            <template v-slot:table-row="props">
                <slot name="change-field" :props="props"></slot>
                <div class="actions" v-if="props.column.field === 'actions'">
                    <div class="actions__not-show">
                      <div class="icons">
                        ...
                      </div>
                    </div>
                    <div class="actions__show">
                      <div :class="{'btn--outline': true,
                                    icons: true,
                                    rotate: !expandedRows.includes(props.row[trackId]),
                                    'prevent-selected': true }"
                           v-if="!!$slots['expandedRow'] || expandedColumns"
                           @click.prevent="expandRow(props.row[trackId])">
                        <i class="icon-oniad icon-keyboard_arrow_up prevent-selected"/>
                      </div>
                      <template v-for="(action, key) in actions"
                        :key="key">
                          <template  v-if="!action.depends || props.row[action.depends.field] === action.depends.condition">
                              <div class="btn--outline icons prevent-selected"
                                v-if="!action.confirm"
                                @click.prevent="$emit(action.event, {id: props.row[trackId], ...action.eventPayload})">
                                <i :class="action.iconClass" :title="action.tooltip" />
                              </div>
                              <div class="btn--outline icons prevent-selected"
                              v-else>
                              <nodriza-table-confirmation-button @confirmed="$emit(action.event, props.row[trackId])">
                                <template v-slot:button="slotProps">
                                    <i class="prevent-selected"
                                       :class="action.iconClass"
                                       :title="action.tooltip"
                                       @click="slotProps.next"/>
                                </template>
                              </nodriza-table-confirmation-button>
                              </div>
                          </template>
                      </template>
                    </div>
                </div>
            </template>
            <template v-slot:emptystate>
                {{ $t('nodrizaTable.noResults') }}
            </template>
        </VueGoodTable>
        <DialogFilterText :dialogSelect="filters.filterDialogSelect"
                          :dialogInput="filters.filterDialogInput"
                          :dialogOptions="filterDialogOptions.dialogFilterText"
                          :currentX="filters.currentX"
                          :currentY="filters.currentY"
                          @save-filter="(params) => saveFilter('Text', params)"
                          @cancel-filter="(params) => cancelFilter('Text', params)"
                          @clickout="cancelFilter('Text')"
                          v-if="filters.showDialogFilterText"/>
        <DialogFilterNumber :dialogSelect="filters.filterDialogSelect"
                            :dialogInput="filters.filterDialogInput"
                            :dialogOptions="filterDialogOptions.dialogFilterNumber"
                            :currentX="filters.currentX"
                            :currentY="filters.currentY"
                            @save-filter="(params) => saveFilter('Number', params)"
                            @cancel-filter="(params) => cancelFilter('Number', params)"
                            @clickout="cancelFilter('Number')"
                            v-if="filters.showDialogFilterNumber"/>
        <DialogFilterDate :dialogSelect="filters.filterDialogSelect"
                          :dialogInput="filters.filterDialogInput"
                          :dialogOptions="filterDialogOptions.dialogFilterDate"
                          :currentX="filters.currentX"
                          :currentY="filters.currentY"
                          @save-filter="(params) => saveFilter('Date', params)"
                          @cancel-filter="(params) => cancelFilter('Date', params)"
                          @clickout="cancelFilter('Date')"
                          v-if="filters.showDialogFilterDate"/>
    </div>
</template>

<script>
import VueGoodTable                   from "./vue3-good-table/Table";
import FilterText                     from "./filter/FilterText";
import FilterNumber                   from "./filter/FilterNumber";
import FilterDate                     from "./filter/FilterDate";
import FilterSelect                   from "./filter/FilterSelect";
import DialogFilterText               from "./dialog/DialogFilterText";
import DialogFilterNumber             from "./dialog/DialogFilterNumber";
import DialogFilterDate               from "./dialog/DialogFilterDate";
import NodrizaTableConfirmationButton from "./button/NodrizaTableConfirmationButton";
import {reactive, ref, toRef}         from "vue";

export default {
    name: "NodrizaTable",
    components: {
        VueGoodTable,
        FilterText,
        FilterNumber,
        FilterDate,
        FilterSelect,
        DialogFilterText,
        DialogFilterNumber,
        DialogFilterDate,
        NodrizaTableConfirmationButton
    },
    props: {
        columns: {},
        rows: {},
        totalRows: {},
        perPage: {},
        actions: {},
        trackBy: {},
        expandedColumns: {},
        allColumns: {},
        selectable: {},
        selectableSort: {},
        selectAllByPage: {},
        selectOnCheckboxOnly: {
            default: false
        },
        initialSort: {},
        mode: {
            default: 'remote'
        },
        filterDialogOptions: {},
        paginationOptions: {
            default: {
                dropdownAllowAll: true,
                perPage: 10,
                perPageDropdown: [10, 20, 30, 40, 50]
            }
        }
    },
    setup(props, {emit}) {
        const mapMediumFinder = {
            'none': '',
            'eq': 'EQ',
            'gT': 'GT',
            'lT': 'LT',
            'empty': 'EMP',
            'between': '',
            'aT': 'GT',
            'bT': 'LT',
            'notEq': 'NEQ',
            'contains': 'LK',
            'notContains': 'NLK',
            'begins': 'EBY',
            'ends': 'SBY'
        };

        const table = ref(null);

        const trackId = props.trackBy || 'id';

        let visibleColumns = toRef(props, 'columns');

        const expandedRows = reactive([]);
        const expandRow = async (rowId) => {
            const index = expandedRows.indexOf(rowId);
            if (index >= 0) {
                expandedRows.splice(index, 1);
            } else {
                expandedRows.push(rowId);
            }
        }
        const prepareFilterParam = (e, select, input, isCustomFilter) => {
            if (!isCustomFilter) {
                return;
            }
            if (filters.typeFilter === 'Select') {
                if (!!input && !!input.length) {
                    filters.columns[filters.currentField] = {type: null, value: input};
                    return;
                }
                delete filters.columns[filters.currentField];
                return;
            }
            if (filters.typeFilter === 'Date') {
                if (!!select && !!select.from && !!select.from.length && (!select.to || !select.to.length)) {
                    filters.columns[filters.currentField] = {type: mapMediumFinder[input], value: select.from};
                    return;
                }
                if (!!select && !!select.from && !!select.from.length && !!select.to && !!select.to.length) {
                    let selectDate = [];
                    selectDate.push(select.from);
                    selectDate.push(select.to);
                    filters.columns[filters.currentField] = {type: input, value: selectDate};
                    return;
                }
                delete filters.columns[filters.currentField];
                return;
            }
            if (!!select || !!input) {
                filters.columns[filters.currentField] = {type: mapMediumFinder[input], value: select};
                return;
            }
            if (!filters.currentField) {
                return;
            }
            delete filters.columns[filters.currentField];
        };
        const preparePageAndLimit = (e) => {
            filters.page = (e && e.currentPage) ? e.currentPage : filters.page;
            filters.itemsPerPage = (e && e.currentPerPage) ? e.currentPerPage : filters.itemsPerPage;
        };
        const requestData = (e, select, input, isCustomFilter) => {
            prepareFilterParam(e, select, input, isCustomFilter);
            preparePageAndLimit(e);
            emit('request-data', filters);
        };
        const onPageChange = (e) => requestData(e);
        const onSortChange = (e) => { //{0.{field: "column.field", type: asc|desc}}
            if (!e || !Array.isArray(e) || !e[0] || !e[0].type || e[0].type === 'none') {
                filters.sort = null;
                filters.order = null;
                requestData(e);
                return;
            }
            filters.sort = e[0].field;
            filters.order = e[0].type;
            requestData(e);
        };
        const onPerPageChange = (e) => {
            if (e.currentPerPage !== props.perPage) {
                requestData(e); //{currentPage, currentPerPage, total}
            }
        }
        const onSearch = (e) => requestData(e); //{searchTerm, rowCount}
        const onSelectAll = (payload) => {
            emit('select-all', payload);
        };
        const onSelectionChange = (payload) => {
            emit('select-change', payload.selectedRows);
        };
        const onRowClick = (payload) => {
            emit('on-row-click', payload);
        };
        let clicked = ref('');
        const deleteRow = (rowId) => {
            emit('delete-data', rowId);
        };
        const filters = reactive({
            showDialogFilterText: false,
            showDialogFilterNumber: false,
            showDialogFilterDate: false,
            currentField: null,
            typeFilter: null,
            currentX: 0,
            currentY: 0,
            filterDialogSelect: '',
            filterDialogInput: null,
            itemsPerPage: props.perPage || 20,
            page: 1,
            sort: null,
            order: null,
            columns: {}
        });
        const getColumnSelectByField = (field) => {
            return props.columns.find((column) => column.field === field).select;
        };
        const getColumnInputByField = (field) => {
            return props.columns.find((column) => column.field === field).input;
        };
        const setColumnSelectByField = (field, select) => {
            return props.columns.find((column) => {
                if (column.field !== field) {
                    return;
                }
                column.select = select;
            });
        };
        const setColumnInputByField = (field, input) => {
            return props.columns.find((column) => {
                if (column.field !== field) {
                    return;
                }
                column.input = input;
            });
        };
        const hideAllFilters = () => {
            filters.showDialogFilterText = false;
            filters.showDialogFilterNumber = false;
            filters.showDialogFilterDate = false;
        };
        const showFilter = (typeFilter, {event, columnName}) => {
            filters.currentField = columnName;
            filters.currentX = event.pageX;
            filters.currentY = event.pageY;
            filters.filterDialogSelect = getColumnSelectByField(columnName);
            filters.filterDialogInput = getColumnInputByField(columnName);
            hideAllFilters();
            filters[`showDialogFilter${typeFilter}`] = true;
        };
        const clearOneFilter = (key) => {
            if (!props.columns) {
                return;
            }
            for (let i = 0; i < props.columns.length; i++) {
                const column = props.columns[i];
                if (!column || column.field !== key) {
                    continue;
                }
                column.select = '';
                column.input = null;
                break;
            }
            delete filters.columns[key];
            requestData();
        };
        const clearFilters = () => {
            props.columns.forEach(column => {
                column.select = '';
                column.input = null;
            });
            filters.columns = {};
            requestData();
        };
        const saveFilter = (typeFilter, {select, input, columnName}) => {

            filters.typeFilter = typeFilter;
            if (typeFilter === 'Select') {
                filters.currentField = columnName;
                setColumnInputByField(filters.currentField, input);
                requestData({}, null, input, true);
                return;
            }
            if (select !== '' && select !== 'empty' && !input && !input.length) {
                setColumnSelectByField(filters.currentField, '');
                setColumnInputByField(filters.currentField, null);
            } else {
                setColumnSelectByField(filters.currentField, select);
                setColumnInputByField(filters.currentField, input);
            }
            filters[`showDialogFilter${typeFilter}`] = false;
            requestData({}, input, select, true);
        };
        const cancelFilter = (typeFilter) => {
            filters[`showDialogFilter${typeFilter}`] = false;
        };
        return {
            visibleColumns,
            onPageChange,
            onSortChange,
            onPerPageChange,
            onSearch,
            onSelectAll,
            onSelectionChange,
            onRowClick,
            clearOneFilter,
            clearFilters,
            showFilter,
            saveFilter,
            cancelFilter,
            getColumnSelectByField,
            getColumnInputByField,
            deleteRow,
            filters,
            clicked,
            expandRow,
            expandedRows,
            trackId,
            table
        };
    }
}
</script>

<style lang="scss">
.nodriza-table {
    .vgt-table {
        .filter-th {
            .filter-th-wrapper {
                display: flex;
                flex-direction: row;
                min-width: 90px;

                .vgt-input {
                    cursor: pointer;
                }
                .filter-input {
                    width: 95%;
                    margin-right: 0.5em;
                }
                .filter-img {
                    width: 14px;
                    cursor: pointer;

                    &.active {
                        color: #5e72e4;
                    }
                }
                .filterDate {
                    height: 2.4em;
                }
            }
        }
    }
    .dialog-filter {
        position: absolute;
        padding: 1.5em;
        background: #f7fafc;
        border: 1px solid #e3e8ee;
        border-radius: 5px;
        box-shadow: 0 0 15px -5px;
        width: 245px;

        .dialog-filter-item:not(:last-child) {
            margin-bottom: 0.5em;
        }

        .dialog-filter-buttons {
            display: flex;
            justify-content: space-between;
        }
    }
    .actions {
        display: flex;
        flex-direction: column;
        .icons {
            display: flex;
            height: 25px;
            align-items: center;
            justify-content: center;
            z-index: 1;
            margin-right: 5px;
            &:hover:after {
                content: "";
                position: absolute;
                top: 0;
                display: block;
                width: 25px;
                height: 25px;
                padding: 1em;
                background-color: #d0d0d0;
                border-radius: 50%;
                z-index: -1;
                opacity: 0.2;
            }
            &.rotate {
                transform: rotate(180deg);
            }
        }
        &__show {
            display: none;
        }
    }
    & tr.clickable {
        height: 35px;
        &:hover{
            td {
                position: relative;
                span {
                    //color: $baseTextLightColor;
                }
                &:nth-last-child(1) {
                    overflow: visible;
                }
            }
            .actions {
                //position: relative;
                &__show {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: baseline;
                    position: absolute;
                    background-color: #f2f2f2;
                    top: 3px;
                    right: 0;
                    min-width: 120px;
                    padding: 0.15em;
                    z-index: 1;
                }
            }
        }
    }
    .expanded-container {
        padding: 1em 4em;
        white-space: inherit;
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
    }
    .expanded-field {
        flex: 1 0 200px;
        flex-wrap: wrap;
        white-space: initial;
        strong, p {
            font-size: 0.8rem;
            color: #525f7f;
        }
    }
    .vgt-table.polar-bear {
        & td {
            padding: .25em .5em .25em .5em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 250px;
            span {
                position: relative;
                top: 5px;
            }
        }
    }
    .footer__row-count__label,
    .footer__row-count__select,
    .footer__navigation__page-info div,
    .footer__navigation__page-btn span {
        font-size: 0.8rem;
    }
    .vgt-wrap__footer {
        .footer__navigation__page-btn {
            .chevron {
                width: 20px;
                height: 20px;
            }
        }
    }

    /*Hey listen! Import your SCSS here*/

}

@import '@/components/PersonalizedTableElements/nodriza-app/nodriza-app.scss';

</style>
