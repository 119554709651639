export const howManyOptionsAreAvailable = (dialogConfigOptions) => {
    let count = 0;
    if (!dialogConfigOptions) {
        return count;
    }
    for (const key in dialogConfigOptions) {
        if (!dialogConfigOptions[key]) {
            continue;
        }
        ++count;
    }
    return count;
};
export const getOptionAvailable = (dialogConfigOptions) => {
    let optionAvailable = '';
    for (const key in dialogConfigOptions) {
        const optionValue = dialogConfigOptions[key];
        if (optionValue) {
            optionAvailable = key;
            break;
        }
    }
    return optionAvailable;
};
export const isEnabledOption = (optionToEvaluate, dialogConfigOptions) => {
    if (!optionToEvaluate || !dialogConfigOptions || !dialogConfigOptions[optionToEvaluate]) {
        return false;
    }
    return dialogConfigOptions[optionToEvaluate];
};
