<template>
    <div class="filter-input">
        <multiselect v-model="optionsSelected"
                     :options="filterDropdownItems"
                     :multiple="multiple"
                     :close-on-select="true"
                     :placeholder="$t('nodrizaTable.filter')"
                     :searchable="searchable"
                     :selectLabel="''"
                     :selectedLabel="''"
                     :deselectLabel="''"
                     :open-direction="'bottom'"
                     @update:model-value="saveFilter">
            <template v-slot:noOptions>
                {{ $t('nodrizaTable.loading') }}...
            </template>
            <template v-slot:noResult>
                {{ $t('nodrizaTable.noResult') }}
            </template>
        </multiselect>
    </div>
    <svg class="filter-img"
         :class="{active: isActive()}"
         viewBox="0 0 64 64"
         @click="clearFilter">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M43,48 L43,56 L21,56 L21,48 L43,48 Z M53,28 L53,36 L12,36 L12,28 L53,28 Z M64,8 L64,16 L0,16 L0,8 L64,8 Z"
                fill="currentColor"></path>
        </g>
    </svg>
</template>

<script>
import Multiselect                   from 'vue-multiselect';
import {ref, onMounted, watchEffect} from "vue";

export default {
    name: 'FilterSelect',
    components: {
        Multiselect
    },
    props: ['columnName', 'filterDropdownItems', 'filterInput', 'searchable', 'multiple', 'preset'],
    emits: ['save-filter', 'clear-filter'],
    setup(props, {emit}) {
        let optionsSelected = ref([]);
        watchEffect(() => {
            optionsSelected.value = props.filterInput;
        });
        onMounted(() => {
            if (!props.preset) {
                return;
            }
            optionsSelected.value = props.preset;
            saveFilter();
        });
        const isActive = () => {
            return optionsSelected.value && optionsSelected.value.length;
        };
        const saveFilter = () => emit('save-filter', {columnName: props.columnName, input: optionsSelected.value});
        const clearFilter = () => {
            if (!isActive()) {
                return;
            }
            emit('clear-filter', props.columnName);
        };
        return {
            saveFilter,
            optionsSelected,
            clearFilter,
            isActive
        };
    }
}
</script>

<style lang="scss">
.nodriza-table {
    .multiselect {
        min-width: 100px;
    }
    .multiselect__tag {
        margin-right: 2px;
        margin-bottom: 2px;
    }
    .multiselect__tags {
        padding-right: 8px;
        height: 2em;
        border: 1px solid #E4EBF3;
        background-color: #fff;
        font-size: 0.8rem;
        font-weight: normal;
        cursor: pointer;
        opacity: 0.5;
    }
    .multiselect__option--highlight,
    .multiselect__option--selected.multiselect__option--highlight {
        background: #161f3e;
    }
    .multiselect__tag {
        background: #001660;
    }
    .multiselect__option--highlight.multiselect__option--selected.multiselect__option {
        color: #fff;
    }
    .multiselect__option--selected {
        color: #667b94;
        background: #161F3E1B;
    }
    .multiselect__tag-icon:after {
        color: #fff;
    }
    .multiselect__tags-wrap {
        display: flex;
        flex-direction: row;
        margin-right: 25px;
    }
    .checkbox-label {
        display: block;
    }
    .multiselect__placeholder {
        color: #606266;
    }
    .multiselect__single {
        font-size: 0.8rem;
        margin-bottom: 5px;
        margin-top: 3px;
    }
}
</style>
