<template>

    <div class="body-register">
        <router-link class="returnLink" to="/admin-panel">
            &lt; Volver
        </router-link>

        <div class="alert-registered" v-if="!this.loaded && this.registerCorrect">
                <span class="alert-registered">¡Usuario registrado correctamente!</span>
        </div>

        <h1 class="register-list"> Registrar NODRIZERS &#x1F464; </h1>
        <p class="register-list">Aquí puedes registrar nuevos NODRIZERS</p>

        <div v-if="this.loaded" class="loader">
            <LoaderComponent />
        </div>

        <div v-if="!this.loaded">

            <div class="register-error" v-if="this.error">
                <ErrorDialogComponent v-bind:error="this.errorMessage"  v-bind:closeOn="true"/>
            </div>

            <div class="form-register-container">
                <RegisterUserComponentVue v-bind:clicked="this.clicked" @sendFormData="getFormData" />
            </div>
            
        
            <button class="btnRegister" @click="onRegisterClick" tabindex="-1">
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" fill="currentColor"></path>
                </svg>
                <span>Registrar NODRIZER</span>
            </button>
        </div>

    </div>

</template>

<script>

import axios from '../../services/storage/axios'
import LoaderComponent from '@/components/LoadersComponents/LoaderComponent.vue'
import RegisterUserComponentVue from '@/components/AdminComponents/RegisterUserComponent.vue'
import ErrorDialogComponent from '@/components/GeneralComponents/ErrorDialogComponent';

export default {
    components: {
        ErrorDialogComponent,
        LoaderComponent,
        RegisterUserComponentVue
    },
    data() {
        return {
            clicked: null,
            error: false,
            errorMessage: null,
            formData: null,
            loaded: false,
            registerCorrect: null
        }
    },
    methods: {
        onRegisterClick() {

            this.clicked = true
        },
        getFormData(formData) {

            this.formData = formData

            const anyError = Object.values(this.formData.errors).some(x => x)

            anyError ? "Errors in form" : this.endpointRegister()

            this.clicked = false
        },
        async endpointRegister() {

                let dataUser = {
                    first_name: this.formData.first_name,
                    last_name: this.formData.last_name,
                    email: this.formData.email,
                    managers: this.formData.managers,
                    company: this.formData.company,
                    department: this.formData.department,
                    is_remote: this.formData.isRemote,
                    roles: this.formData.roles
                }

                if (this.formData.selectingManager) {

                    dataUser['company_manager'] = this.formData.company_manager
                    dataUser['department_manager'] = this.formData.department_manager
                }

                this.loaded = true

                const options = {
                    method: "POST",
                    url: "/admin/register",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    data: dataUser,
                }

                await axios(options).then(

                    (response) => {

                        if (response.status) {

                        switch (response.status) {

                            case 200:

                                this.registerCorrect = true
                                this.error = false
                                this.loaded = false

                                break

                            case 401:

                                this.$router.push("/login")
                                break

                            default:

                                this.error = true
                                this.errorMessage = "Ha ocurrido un error al registrar al usuario"
                                this.formData.errors.back = "Ha ocurrido un error al registrar al usuario"
                                this.loaded = false

                                break
                        }

                        } else if (response.response.status) {

                            switch (response.response.status) {

                                case 401:

                                    this.$router.push("/login")
                                    break

                                default:

                                    this.error = true
                                    this.errorMessage = "Ha ocurrido un error al registrar al usuario"
                                    this.formData.errors.back = "Ha ocurrido un error al registrar al usuario"
                                    this.loaded = false

                                    break
                            }
                        }

                        this.loaded = false

                    },
                    (error) => {

                        console.log(error)
                    }
                )

                if (this.registerCorrect)
                    document.getElementById('formRegister').reset()

        },
    }
}
</script>

<style scoped>

    @import '@/styles/CommonStyles/BasicElementsStyle.scss';

    button.btnRegister {
        background: #ed3f7c;
        color: white;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.05em;
      margin: 60px 25% 50px;
      padding: 0.4em 1.3em 0.8em 0.9em;
        width: 30%;
    }

    button.btnRegister svg {
        margin-right: 20px;
        margin-top: 5px;
        transform: rotate(30deg);
        transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    }

    button.btnRegister span {
        transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    }

    button.btnRegister:hover {
        cursor: pointer;
    }

    button.btnRegister:hover svg {
        transform: translateX(5px) rotate(90deg);
    }

    button.btnRegister:hover span {
        transform: translateX(7px);
    }

    div.alert-registered {
        color: #548441;
        border-radius: 7px;
        font-family: "Montserrat", sans-serif;
        font-size: 28px;
        font-weight: bolder;
        margin-top: 40px;
        margin-left: 37%;
        margin-right: 37%;
        padding: 20px;
    }

    h1.register-list {
        font-size: 26px;
        margin-top: 30px;
        margin-left: 38%;
        padding-bottom: 7px;
        width: 25%;
    }

    p.register-list {
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

</style>