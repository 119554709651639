<template>
   <div class="body-searcher">
        <Multiselect v-model="managersSelected"
                    class="search-managers"
                    :options="possibleManagers"
                    :multiple="true"
                    :close-on-select="true"
                    :placeholder="'Buscar responsable por nombre...'"
                    :searchable="true"
                    :selectLabel="''"
                    :selectedLabel="''"
                    :deselectLabel="''"
                    :open-direction="'bottom'"
                    :hide-selected="true"
                    @update:model-value="saveManagers">
            <template v-slot:noResult>
                {{ $t('nodrizaTable.noResult') }}
            </template>
        </Multiselect>
   </div>
</template>

<script>

    import { Multiselect } from 'vue-multiselect'
    import axios from '@/services/storage/axios'

    import { managerNameToId } from '@/AppNT/utils/nameToIdConverter'
    
    export default {

        props: {
            depSelected: null
        },

        components: {
            Multiselect
        }, 

        emits: ['save-managers'],

        data() {
            return {
                adminData: null,
                managersSelected: [],
                possibleManagers: []
            }
        },

        methods: {

            saveManagers() {

                let managerIds = []

                Object.values(this.managersSelected).forEach(element => {

                    let managerId = managerNameToId(element, Object.values(this.adminData.managers)) 

                    managerIds.push(managerId)
                });

                this.$emit('save-managers', managerIds)
            },
            
            async getManagersFromAdminData() {

                const options = {
                    method: 'GET',
                    url: '/admin/admin-data',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }

                 await axios(options)
                .then((response) => {

                    if (response.status) {

                        switch (response.status) {

                            case 200:

                                this.adminData = response.data.data

                                for (let i = 0; i < Object.values(this.adminData.managers).length; i++) {

                                    this.possibleManagers.push(this.adminData.managers[i].name)
                                }

                                break

                            case 401:

                                this.$router.push('/login')
                                break

                            case 404:

                                this.error = true
                                this.errorMessage = "Error: No encontrado"
                                break

                            default:

                                this.error = true
                                this.errorMessage = "Ha ocurrido un error inesperado"
                                break
                        }
                    }

                    else if (response.response) {

                        switch (response.response.status) {

                            case 403:
                                this.error = true
                                this.closeError = false
                                this.errorMessage = "Error. No tienes ningun departamento asociado"
                                break

                            case 404:
                                this.error = true
                                this.errorMessage = "Error. No encontrado"
                                break

                            default:

                                this.error = true
                                this.errorMessage = "Ha ocurrido un error inesperado"
                                break
                        }
                    }

                })
            }

        },

        watch: {

            depSelected(value) {

                let managerName = this.adminData.managers.filter(mang => mang.id === value.dept_manager)

                this.managersSelected = []

                if (managerName.length > 0) {

                    this.managersSelected[0] = managerName[0].name
                }
                
                this.saveManagers()
            }
        },

        created() {

            this.getManagersFromAdminData()
        }
    }
</script>

<style lang="scss" src="@/styles/AdminStyles/SearcherManagersStyles.scss"></style>