<template>

    <div class="body-admin">

        <router-link class="returnLink" to="/">
            &lt; Volver
        </router-link>

        <h1 class="panel"> Panel de Administrador &#9881;&#65039; </h1>

        <ul class="admin-options">
            <li class="admin-option"><router-link class="options-link" to="/admin-panel/register">&#x1F464; Registrar <strong>NODRIZERS</strong></router-link></li>
            <li class="admin-option"><router-link class="options-link" to="/admin-panel/list-nodrizers">&#x1F4DD; Lista <strong>NODRIZERS</strong></router-link></li>
        </ul>

    </div>

</template>

<script>
export default {

}
</script>

<style scoped>

    a.options-link {
        color: #444444;
        text-decoration: none;
    }

    a.options-link:hover {
        color: #ED3F7C;
    }

    div.body-admin {
        text-align: center;
    }

    h1.panel {
        border-bottom: 1px solid #f5c9d9;
        font-size: 30px;
        margin-top: 50px;
        margin-left: 38%;
        padding-bottom: 7px;
        width: 25%;
    }

    ul.admin-options {
        list-style-type: none;
        margin-top: 50px;
        padding: 0;
    }

    ul.admin-options, li.admin-option, a.options-link {
        font-size: 20px;
        margin-left: -15px;
    }

    li.admin-option {
        margin:4px 0;
    }

    li.admin-option {
        padding-left: 2em;
        text-indent: -1.5em;
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

</style>