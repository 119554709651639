import axios from "@/services/storage/axios";

export const getPossibleFilters = async (mode) => {

    let possibleFilters = {};

    let possibleAuthors = [];
    let adminAuthorsNames = [];

    let possibleDepartments = [];
    let adminDepartmentsNames = [];

    let possibleTypes = [];
    let ticketTypesNames = [];

    let possibleStatus = [];
    let ticketStatusNames = [];

    let possibleCompanies = [];
    let adminCompaniesNames = [];

    let url = "";

    switch (mode) {

        case 'admin': 
            url = `/admin/filter-names`;
            break;

        case 'user-manager':
            url = `/filter-names`;
            break;
    }

    const options = {
        method: 'GET',
        url: url
    }
    
    const response = await axios(options);

      if (!response.errorMessage) {

        let companies;
        let departments;
        let types;
        let status;
        let authors;

        if (mode === 'admin' && response.data.filter_fields.authors) {
            authors = response.data.filter_fields.authors;
        }

        if (response.data.filter_fields.departments) {
            departments = response.data.filter_fields.departments;
        }

        if(response.data.filter_fields.companies) {
            companies = Object.values(response.data.filter_fields.companies);
        }

        if(response.data.filter_fields.types) {
            types = Object.values(response.data.filter_fields.types);
        }

        if(response.data.filter_fields.validating_status) {
            status = Object.values(response.data.filter_fields.validating_status);
        }

        if (mode === 'admin' && authors) {

            for (let i = 0; i - authors.length; i++) {

                adminAuthorsNames.push(authors[i].author_name)
                possibleAuthors.push(authors[i])
            }

            possibleFilters.authors_data = {
                authors: possibleAuthors,
                authors_names: adminAuthorsNames
            }
        }

        if (companies) {
            for (let i = 0; i - companies.length; i++) {

                adminCompaniesNames.push(companies[i].company_name)
                possibleCompanies.push(companies[i])
            }

            possibleFilters.company_data = {
                'companies': possibleCompanies,
                'companies_names': adminCompaniesNames
            }
        }

        if (departments) {

            for (let i = 0; i - departments.length; i++) {

                adminDepartmentsNames.push(departments[i].dept_name)
                possibleDepartments.push(departments[i])
            }

            possibleFilters.department_data = {
                'departments': possibleDepartments,
                'departments_names': adminDepartmentsNames
            }
        }

        if (types) {

            for (let i = 0; i < types.length; i++) {
                if (types[i].subtype) {

                        ticketTypesNames.push(types[i].subtype.name)
                        possibleTypes.push(types[i].subtype)

                } else {
                    ticketTypesNames.push(types[i].name)
                    possibleTypes.push(types[i])
                }
            }

            possibleFilters.types_data = {
                'types': possibleTypes,
                'types_names': ticketTypesNames
            }
        }

        if (status) {

            for (let i = 0; i < status.length; i++) {
                ticketStatusNames.push(status[i].name)
                possibleStatus.push(status[i])
            }

            possibleFilters.status_data = {
                'status': possibleStatus,
                'status_names': ticketStatusNames,
            }
        }

        possibleFilters.succesfull = true;
    }
      else{
          possibleFilters.succesfull = false;
      }

    return possibleFilters;
}