<template>
  <router-link class="returnLink" to="/">
    &lt; Volver
  </router-link>

  <div v-if="this.loaded && (this.leaderTickets.length >= 0 && !this.isValidating && this.view === 'team')">
    <h1>Consulta de tickets de tu equipo</h1>
    <p>Aquí puedes consultar todo sobre los tickets que tu equipo ha enviado</p>
  </div>

  <div class="alert-validated"
    v-if="this.loaded && (this.leaderTickets.length >= 0 && this.isValidating && this.view === 'validate' && this.validatedStatus)">
    <span class="alert-validated">{{ this.notificationMessage }}</span>
  </div>

  <div v-if="this.loaded && (this.leaderTickets.length >= 0 && this.isValidating && this.view === 'validate')">
    <h1>Validación de tickets</h1>
    <p>Aquí tienes los tickets que puedes validar como responsable</p>
  </div>

  <div v-if="this.loaded && this.leaderTickets.length === 0 && !this.filtered && !this.error && !this.isValidating">
    <h1> ¡Vaya! ¡Tu equipo todavía no ha introducido ningún ticket!</h1>
  </div>

  <div v-if="this.loaded && this.leaderTickets.length === 0 && !this.filtered && !this.error && this.isValidating">
    <h1> ¡Vaya! ¡No tienes tickets pendientes de validar!</h1>
  </div>

  <div class="error" v-if="this.error">
    <ErrorDialogComponent v-bind:error="this.errorMessage" v-bind:closeOn="closeError" />
  </div>

  <div class="motivater" v-if="this.loaded && this.error">
    <MotivaterShipComponent class="rocket" />
  </div>

  <div v-show="this.loadingPending && this.loaded">
    <SkeletonTable />
  </div>

  <div class="table" v-show="!this.loadingPending">
    <NodrizaTable v-if="this.loaded && (this.leaderTickets.length > 0)" ref="table" :mode="'local'" :columns="columns"
      :rows="rows" :totalRows="rows.length" :perPage="10" :selectable="false"
      :paginationOptions="{ dropdownAllowAll: false, perPage: 10, perPageDropdown: [10, 20, 50, 100, 200, 500] }"
      :filterDialogOptions="{ dialogFilterDate: { aT: true, bT: true, between: true } }" @requestData="getRows" @on-row-click="goToTicketRow">
      <template v-slot:change-field="props">
        <RedirectButtonComponent class="button" text="Ver ticket" render="ticket"
          v-if="props.props.column.field === 'button' && props.props.row.id !== null"
          @click="goToTicket(props.props.row.id)" />
        <p v-if="props.props.column.field === 'type'" class="pink_mark">
          {{ props.props.row.type }}
        </p>  
        <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 1" class="orange_mark">
          {{ props.props.row.status }}
        </p>
        <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 2" class="green_mark">
          {{ props.props.row.status }}
        </p>
        <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 3" class="red_mark">
          {{ props.props.row.status }}
        </p>
      </template>
    </NodrizaTable>
  </div>

  <div class="loader" v-if="!this.loaded && !this.error">
    <LoaderComponent />
  </div>

</template>

<script>
import LoaderComponent from '@/components/LoadersComponents/LoaderComponent'
import ErrorDialogComponent from "@/components/GeneralComponents/ErrorDialogComponent";
import MotivaterShipComponent from "@/components/GeneralComponents/MotivaterShipComponent";
import axios from '@/services/storage/axios'
import RedirectButtonComponent from "@/components/PersonalizedTableElements/RedirectButtonComponent";
import NodrizaTable from '@/components/NodrizaTable/nodriza/table/NodrizaTable';
import LocalStorageService from "@/services/storage/LocalStorageService";
import FilterValidateUrlStorage from "@/services/storage/FilterValidateUrlStorage";
import SkeletonTable from '@/components/NodrizaTable/skeletons/SkeletonTable';

import { statusNameToId, authorNameToId, typeNameToId, departmentNameToId, companyNameToId } from "@/AppNT/utils/nameToIdConverter";
import { statusIdToName, authorIdToName, typeIdToName, departmentIdToName, companyIdToName } from "@/AppNT/utils/idToNameConverter";
import { arraysAreEqual } from "@/AppNT/utils/compareArrays";
import { getPossibleFilters } from "@/AppNT/endpointsCalls/getPossibleFilters";
import { departmentsFromCompany } from "@/AppNT/utils/departmentsFromCompany";

import { dateStringDDMMYYYYToTimestamp } from "@/AppNT/utils/dateConverter";
import { isEmpty, constructURLDates, getLeaderTicketsRows } from '@/AppNT/utils/tableUtils';

export default {
  name: "LeaderTicketsView",
  components: {
    RedirectButtonComponent,
    LoaderComponent,
    ErrorDialogComponent,
    MotivaterShipComponent,
    NodrizaTable,
    SkeletonTable
  },

  props: {
    view: null
  },

  data() {
    return {

      notificationMessage: null,
      validatedStatus: false,

      closeError: true,

      leaderTickets: [],

      ticketAuthorsNames: [],
      possibleAuthors: [],

      ticketTypesNames: [],
      possibleTypes: [],
      oldTypeFiltered: null,

      ticketStatusNames: [],
      possibleStatus: [],
      validateStatusChanged: false,
      oldStatusFiltered: null,

      oldColumnFiltered: [],

      leaderDepartmentsNames: [],
      possibleDepartments: [],
      oldDepartmentFiltered: null,

      leaderCompanysNames: [],
      possibleCompanys: [],
      oldCompanyFiltered: null,


      oldAuthorFiltered: null,

      oldDateToCreated: null,
      oldDateFromCreated: null,
      oldDateAfterCreated: null,
      oldDateBeforeCreated: null,
      oldIntervalCreated: null,

      oldDateToValidated: null,
      oldDateFromValidated: null,
      oldDateAfterValidated: null,
      oldDateBeforeValidated: null,
      oldIntervalValidated: null,

      oldDateCreatedAtFiltered: null,
      oldDateValidatedAtFiltered: null,

      activeFilters: [],
      filteredTickets: [],
      filtersPreset: {},

      isValidating: false,
      error: false,
      errorMessage: null,
      loaded: false,
      loadingPending: false,
      date: null,
      localStorage: null,
      filterUrlStorage: null,
      loadingFilter: false,
      filtered: false,

      companyFiltered: null,

      validateFilter: false,

      columns: [
      ],

      rows: [
      ],
    }
  },

  async created() {

    this.isValidating = this.view === 'validate';

    this.localStorage = LocalStorageService.getService();
    this.filterUrlStorage = FilterValidateUrlStorage.getService();

    await this.getFilters();

    if(!this.error) {
      await this.getRows();

      if (this.view === 'team') {
        this.localStorage.clearActiveFilters();
      }

      if (this.localStorage.getActiveFilters() !== 'null' && this.view === 'validate' && this.localStorage.getActiveFilters() != null) {

        this.loadingPending = true;
        const activeFilters = JSON.parse(this.localStorage.getActiveFilters());

        this.filtersPreset.status = statusIdToName(activeFilters.status, this.possibleStatus);
        this.filtersPreset.author = authorIdToName(activeFilters.author, this.possibleAuthors);
        this.filtersPreset.company = companyIdToName(activeFilters.company, this.possibleCompanys);
        this.filtersPreset.department = departmentIdToName(activeFilters.department, this.possibleDepartments);
        this.filtersPreset.type = typeIdToName(activeFilters.type, this.possibleTypes);

        this.localStorage.clearActiveFilters();
      }

      if (this.checkNullsFiltersPreset()) {
        this.filtersPreset = {};
      }

      this.pushColumns();

      /* get notification info */

      if (this.localStorage.getValidated() === 'true') {

        this.validatedStatus = true;
        this.localStorage.setValidated('false');

        this.notificationMessage = this.localStorage.getNotificationText();

        setTimeout(() => this.validatedStatus = false, 2000);
      }
    }

    this.loaded = true;
  },

  methods: {

    async getFilters() {

      const possibleFiltersData = await getPossibleFilters('user-manager');

      if(possibleFiltersData.succesfull){

        this.leaderCompanysNames = possibleFiltersData.company_data.companies_names;
        this.possibleCompanys = possibleFiltersData.company_data.companies;

        this.leaderDepartmentsNames = [];
        this.possibleDepartments = possibleFiltersData.department_data.departments;

        this.ticketTypesNames = possibleFiltersData.types_data.types_names;
        this.possibleTypes = possibleFiltersData.types_data.types;

        this.ticketStatusNames = possibleFiltersData.status_data.status_names;
        this.possibleStatus = possibleFiltersData.status_data.status;
      }
      else {

          this.error = true
          this.errorMessage = "Parece que no tienes personas asociadas. Contacta con tu responsable";
        }

    },

    async getRows(filters) {

      try {

        if (filters && filters.columns) {

          filters.columns = this.deleteNotActiveFilters(filters.columns, this.activeFilters);
        }

        if (!filters && !this.isValidating) {

          this.rows = await this.getTickets();
        }

        else if (!filters && this.isValidating) {

          this.rows = await this.getPendingTickets();
        }

        else if (Object.values(filters.columns).length > 0
                  && arraysAreEqual(this.oldColumnFiltered, Object.values(filters.columns)) === false 
                  && Object.keys(this.filtersPreset).length === 0) {

          this.oldColumnFiltered = [...Object.values(filters.columns)];

          this.loadingFilter = true;
          this.filtered = true;

          this.rows = Object.values(await this.callFilters(filters.columns));

          this.loadingFilter = false;
          this.loadingPending = false;
        }

        else if (Object.values(filters.columns).length > 0 
                  && this.compareFiltersPresetAndColumns(filters)
                  && arraysAreEqual(this.oldColumnFiltered, Object.values(filters.columns)) === false) {
          
          this.oldColumnFiltered = [...Object.values(filters.columns)];

          this.loadingFilter = true;
          this.filtered = true;

          this.rows = Object.values(await this.callFilters(filters.columns));

          this.loadingFilter = false;
          this.loadingPending = false;

          this.filtersPreset = {};
        }

        else if (isEmpty(filters.columns) && this.filtered && !this.isValidating) {

          this.oldColumnFiltered = []

          this.rows = await this.getTickets()

          let emptyObject = {}

          if (this.rows.length < 10) {
            for (let i = this.rows.length; i < 10; i++) {
              this.rows.push(emptyObject)
            }
          }

          this.filtered = false;

        }

        else if (isEmpty(filters.columns) && this.filtered && this.isValidating) {

          this.oldColumnFiltered = [];
          this.rows = await this.getPendingTickets();
          this.filtered = false;
        }

      }
      catch (e) {
        
        console.error('Filter request error ', e);
      }
    },

    async getTickets() {

      let teamTickets = [];

      const options = {
        method: 'GET',
        url: '/team/tickets'
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.rows = [];
        this.leaderTickets = Object.values(response.data['team_tickets']);
        this.leaderTickets = this.leaderTickets.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        teamTickets = getLeaderTicketsRows(this.leaderTickets, this.possibleAuthors, this.ticketAuthorsNames, this.possibleStatus);

      } else {

        this.error = true
        this.errorMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo.";
      }

      this.loaded = true;

      let ticketsLength = Object.keys(teamTickets).length;

      for (let i = ticketsLength; i < 10; i++) {
        teamTickets.push({ id: null });
      }

      return teamTickets;
    },

    async getPendingTickets() {

      let pendingTickets = []

      const options = {
        method: 'GET',
        url: '/validate/tickets'
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.rows = [];
        this.leaderTickets = Object.values(response.data['tickets']);
        this.leaderTickets = this.leaderTickets.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        pendingTickets = getLeaderTicketsRows(this.leaderTickets, this.possibleAuthors, this.ticketAuthorsNames, this.possibleStatus);

      } else {

        this.error = true
        this.errorMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo.";
      }

      let ticketsLength = pendingTickets.length;

      for (let i = ticketsLength; i < 10; i++) {
        pendingTickets.push({ id: null });
      }

      return pendingTickets;
    },

    async callFilters(columns) {

      //Si la columna status existe y la key 'status' no esta entre los filtros activos
      //                            O
      //Si la columna status existe, la key 'status' esta entre los filtros activos y el
      //status del primer ticket es distinto al valor de la columna status

      if (columns.status && !('status' in this.activeFilters)
        ||
        (columns.status && ('status' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.status && 'status' in this.activeFilters
          &&
          this.oldStatusFiltered !== columns.status.value)) {

        this.oldStatusFiltered = columns.status.value;

        if (this.isValidating && !this.validateStatusChanged) {
          this.validateStatusChanged = true;
        }

        this.activeFilters['status'] = statusNameToId(this.oldStatusFiltered, this.possibleStatus);

      }

      //Si la columna type existe y la key 'type' no esta entre los filtros activos
      //                            O
      //Si la columna type existe, la key 'type' esta entre los filtros activos y el
      //tipo del primer ticket es distinto al valor de la columna type

      if (columns.type && !('type' in this.activeFilters)
        ||
        (columns.type && ('type' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.type && 'type' in this.activeFilters
          &&
          this.oldTypeFiltered !== columns.type.value)) {

        this.oldTypeFiltered = columns.type.value;
        this.activeFilters['type'] = typeNameToId(this.oldTypeFiltered, this.possibleTypes);
      }

      if (columns.author && !('author' in this.activeFilters)
        ||
        (columns.author && ('author' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.author && 'author' in this.activeFilters
          &&
          this.oldAuthorFiltered !== columns.author.value)) {

        this.oldAuthorFiltered = columns.author.value;
        this.activeFilters['author'] = authorNameToId(this.oldAuthorFiltered, this.possibleAuthors);

      }

      if (columns.company && !('company' in this.activeFilters)
        ||
        (columns.company && ('company' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.company && 'company' in this.activeFilters
          &&
          this.oldCompanyFiltered !== columns.company.value)) {

        const departmentsTmp = [];
        const columnDepartment = this.columns.find((column) => column.field === 'department');
        this.companyFiltered = columns.company.value;

        this.possibleDepartments.forEach(dep => {
          if (dep.company_name === this.companyFiltered) {
            departmentsTmp.push(dep.dept_name)
          }
        });

        columnDepartment.filterOptions.filterDropdownItems = departmentsTmp;

        if (this.filtersPreset.department === undefined || this.filtersPreset.department === null) {

          columnDepartment.select = '';
          columnDepartment.input = null;

          delete columns.department;
        }

        columns = this.deleteNotActiveFilters(columns, this.activeFilters);


        this.oldCompanyFiltered = columns.company.value;
        this.validateFilter = true;
        this.leaderDepartmentsNames = departmentsFromCompany(this.oldCompanyFiltered, this.possibleDepartments);
        this.activeFilters['company'] = companyNameToId(this.oldCompanyFiltered, this.possibleCompanys);

      }


      if (columns.department && !('department' in this.activeFilters)
        ||
        (columns.department && ('department' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.department && 'department' in this.activeFilters
          &&
          this.oldDepartmentFiltered !== columns.department.value)) {


        this.oldDepartmentFiltered = columns.department.value;
        this.validateFilter = true;
        this.activeFilters['department'] = departmentNameToId(this.oldDepartmentFiltered, this.possibleDepartments);

      }

      //Si la columna status created_at y la key 'created_at' no esta entre los filtros activos
      //                            O
      //Si la columna created_at existe, la key 'created_at' esta entre los filtros activos y el
      //created_at del primer ticket es distinto al valor de la columna created_at

      if (columns.created_at && !('created_at' in this.activeFilters)
        ||
        (columns.created_at && ('created_at' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.created_at && 'created_at' in this.activeFilters
          &&
          this.oldDateCreatedAtFiltered !== [...Object.values(columns.created_at)])) {

        if (columns.created_at.type === 'between'
          && (this.oldDateFromCreated !== columns.created_at.value[0] || this.oldDateToCreated !== columns.created_at.value[1])) {

          this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)]

          //if (dateStringDDMMYYYYToTimestamp(columns.created_at.value[0]) < dateStringDDMMYYYYToTimestamp(columns.created_at.value[1])) {
          this.oldDateFromCreated = columns.created_at.value[0];
          this.oldDateToCreated = columns.created_at.value[1];
          this.oldIntervalCreated = columns.created_at.type;


          this.activeFilters['created_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateFromCreated),
            to: dateStringDDMMYYYYToTimestamp(this.oldDateToCreated),
            interval: this.oldIntervalCreated,
          }

        } else if (columns.created_at.type === 'LT'
          && (this.oldIntervalCreated !== columns.created_at.type
            || this.oldDateBeforeCreated !== columns.created_at.value)) {

          this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
          this.oldDateBeforeCreated = columns.created_at.value;
          this.oldIntervalCreated = columns.created_at.type;

          this.activeFilters['created_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeCreated),
            interval: 'before',
          };


        } else if (columns.created_at.type === 'GT'
          && (this.oldIntervalCreated !== columns.created_at.type
            || this.oldDateAfterCreated !== columns.created_at.value)) {

          this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
          this.oldDateAfterCreated = columns.created_at.value;
          this.oldIntervalCreated = columns.created_at.type;

          this.activeFilters['created_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterCreated),
            interval: 'after',
          };
        }
      }

      //Si la columna status existe y la key 'status' no esta entre los filtros activos
      //                            O
      //Si la columna status existe, la key 'status' esta entre los filtros activos y el
      //status del primer ticket es distinto al valor de la columna status

      if (columns.validated_at && !('validated_at' in this.activeFilters)
        ||
        (columns.validated_at && ('validated_at' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.validated_at && 'validated_at' in this.activeFilters
          &&
          this.oldDateValidatedAtFiltered !== [...Object.values(columns.validated_at)])) {

        if (columns.validated_at.type === 'between'
          && (this.oldDateFromValidated !== columns.validated_at.value[0] || this.oldDateToValidated !== columns.validated_at.value[1])) {

          this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];

          if (dateStringDDMMYYYYToTimestamp(columns.validated_at.value[0]) < dateStringDDMMYYYYToTimestamp(columns.validated_at.value[1])) {
            
            this.oldDateFromValidated = columns.validated_at.value[0];
            this.oldDateToValidated = columns.validated_at.value[1];
            this.oldIntervalValidated = columns.validated_at.type;

            this.activeFilters['validated_at'] = {
              from: dateStringDDMMYYYYToTimestamp(this.oldDateFromValidated),
              to: dateStringDDMMYYYYToTimestamp(this.oldDateToValidated),
              interval: this.oldIntervalValidated,
            };
          }

        } else if (columns.validated_at.type === 'LT'
          && (this.oldIntervalValidated !== columns.validated_at.type
            || this.oldDateBeforeValidated !== columns.validated_at.value)) {

          this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];
          this.oldDateBeforeValidated = columns.validated_at.value;
          this.oldIntervalValidated = columns.validated_at.type;

          this.activeFilters['validated_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeValidated),
            interval: 'before',
          };


        } else if (columns.validated_at.type === 'GT'
          && (this.oldIntervalValidated !== columns.validated_at.type
            || this.oldDateAfterValidated !== columns.validated_at.value)) {

          this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];
          this.oldDateAfterValidated = columns.validated_at.value;
          this.oldIntervalValidated = columns.validated_at.type;

          this.activeFilters['validated_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterValidated),
            interval: 'after',
          };

        }

      }

      if (!this.isValidating) {

        this.filteredTickets = await this.getFilteredTicketsTeam();

      }
      else {

        this.filteredTickets = await this.getFilteredTicketsValidate();
      }


      return this.filteredTickets
    },

    deleteNotActiveFilters(columns) {

      if (!columns.status && ('status' in this.activeFilters)) {
        delete this.activeFilters['status'];
      }

      if (!columns.type && ('type' in this.activeFilters)) {
        delete this.activeFilters['type'];
      }

      if (!columns.author && ('author' in this.activeFilters)) {
        delete this.activeFilters['author'];
      }

      if (!columns.department && ('department' in this.activeFilters)) {
        delete this.activeFilters['department'];
      }

      if (!columns.company && ('company' in this.activeFilters)) {

        delete this.activeFilters['company'];
        delete this.activeFilters['department'];

        const columnDepartment = this.columns.find((column) => column.field === 'department');
        columnDepartment.filterOptions.filterDropdownItems = [];
        columnDepartment.select = '';
        columnDepartment.input = null;

        delete columns.department;
      }

      if (!columns.created_at && ('created_at' in this.activeFilters)) {
        delete this.activeFilters['created_at'];
        this.oldDateCreatedAtFiltered = [];
      }

      if (!columns.validated_at && ('validated_at' in this.activeFilters)) {
        delete this.activeFilters['validated_at'];
        this.oldDateValidatedAtFiltered = [];
      }

      return columns;
    },

    checkNullsFiltersPreset() {

      let allNull = true;

      for (let field in this.filtersPreset) {

        if (this.filtersPreset[field] != null) {
          allNull = false;
          break;
        }
      }

      return allNull;
    },

    compareFiltersPresetAndColumns(filters) {

      // filters preset

      let comparingArrayPresets = [];
      let comparingArrayColumns = [];
      let equals = true;

      for (let preset in this.filtersPreset) {

        if (this.filtersPreset[preset] != null) {

          comparingArrayPresets[preset] = this.filtersPreset[preset];
        }
      }

      for (let filter in filters.columns) {

        comparingArrayColumns[filter] = filters.columns[filter].value;
      }
      
      if (arraysAreEqual(Object.keys(comparingArrayColumns).sort(), Object.keys(comparingArrayPresets).sort())) {

        for (let field in this.filtersPreset) {

          if (comparingArrayPresets[field] != comparingArrayColumns[field]) {

            equals = false;
            break;
          }
        }

      } else {

        equals = false;
      }

      return equals;
    },

    pushColumns() {

      const authorSize = '10%';
      const titleSize = '20%';
      const typeSize = '10%';
      const createdSize = '7%';
      const statusSize = '10%';
      const validatedSize = '7%';
      const departmentSize = '10%';
      const companySize = '10%';

      if (this.isValidating 
            && !this.validateStatusChanged 
            && !this.filtersPreset.status 
            && Object.keys(this.filtersPreset).length === 0) {

        this.filtersPreset.status = 'Pendiente';
        this.loadingPending = true;
      }

      const columns = [{

        label: 'Autor',
        field: 'author',
        width: authorSize,
        filterable: true,
        filterOptions: {
          enabled: true,
          single: true,
          searchable: true,
          customFilterSelect: true,
          filterDropdownItems: this.ticketAuthorsNames,
          preset: this.filtersPreset.author
        },
        select: '',
        input: null,
      },
      {
        label: 'Empresa',
        field: 'company',
        width: companySize,
        filterable: true,
        filterOptions: {
          enabled: true,
          single: true,
          searchable: true,
          customFilterSelect: true,
          filterDropdownItems: this.leaderCompanysNames,
          preset: this.filtersPreset.company
        },
        select: '',
        input: null,
      },
      {
        label: 'Departamento',
        field: 'department',
        width: departmentSize,
        filterable: true,
        filterOptions: {
          enabled: true,
          single: true,
          searchable: true,
          customFilterSelect: true,
          filterDropdownItems: this.leaderDepartmentsNames,
          preset: this.filtersPreset.department
        },
        select: '',
        input: null,
      },
      {
        label: 'Título',
        field: 'title',
        width: titleSize,
        filterable: false,
        filterOptions: {},
      },
      {
        label: 'Tipo',
        field: 'type',
        width: typeSize,
        filterable: true,
        filterOptions: {
          enabled: true,
          single: true,
          searchable: true,
          customFilterSelect: true,
          filterDropdownItems: this.ticketTypesNames,
          preset: this.filtersPreset.type
        },
        select: '',
        input: null,
      },
      {
        label: 'Enviado',
        field: 'created_at',
        type: 'date',
        width: createdSize,
        dateInputFormat: 't',
        dateOutputFormat: 'dd/MM/yyyy',
        filterable: false,
        filterOptions: {
          enabled: true,
          customFilterDate: true,
          iconDate: true
        },
        select: '',
        input: null
      },

      {
        label: 'Estado',
        field: 'status',
        tdClass: 'center-column',
        width: statusSize,
        sortable: true,
        filterable: true,
        filterOptions: {
          enabled: true,
          single: true,
          customFilterSelect: true,
          filterDropdownItems: this.ticketStatusNames,
          preset: this.filtersPreset.status
        },
        select: '',
        input: null,

      },
      {
        label: 'Validado',
        field: 'validated_at',
        type: 'date',
        dateInputFormat: 't',
        dateOutputFormat: 'dd/MM/yyyy',
        width: validatedSize,
        filterable: false,
        filterOptions: {
          enabled: true,
          customFilterDate: true,
          iconDate: true
        },
        select: '',
        input: null
      },
      {
        label: '',
        labelHTML: '<img class="nodricoin-svg" src=""/>',
        tdClass: 'center-column',
        field: 'amount',
        type: 'number',
        filterable: false,
        filterOptions: {},
      },
      {
        label: '',
        field: 'button',
        type: "button",
        sortable: false,
        width: '10%',
        tdClass: 'center-column',
        filterable: false,
        filterOptions: {},
      },
      {
        label: '',
        field: 'actions',
        width: '1%',
        filterable: false,
        clearFilter: true,
        filterOptions: {},
        sortable: false,
        type: 'button',
        input: null
      }];

      this.columns = [...columns];
    },

    async getFilteredTicketsTeam() {

      let ticketFiltered = [];
      let retrievedTicket = [];
      let url = '/filter-team/query?';

      url = constructURLDates(url, this.activeFilters);

      if (this.activeFilters.company) {

        url += `&company=${this.activeFilters.company}`;
        this.leaderDepartmentsNames = departmentsFromCompany(this.activeFilters.company, this.possibleDepartments);
      }

      url = url.replace('?&', '?');


      const options = {
        method: 'GET',
        url: url
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.rows = [];
        retrievedTicket = Object.values(response.data['tickets']);
        retrievedTicket = retrievedTicket.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        ticketFiltered = getLeaderTicketsRows(retrievedTicket, this.possibleAuthors, this.ticketAuthorsNames, this.possibleStatus);

      } else {

        this.error = true;
        this.errorMessage = "Ha ocurrido un error en el filtrado";
      }

      this.loaded = true;

      let arrayLength = Object.keys(ticketFiltered).length;

      for (let i = arrayLength; i < 10; i++) {
        ticketFiltered.push({ id: null })
      }

      return ticketFiltered;

    },

    async getFilteredTicketsValidate() {

      let ticketFiltered = [];
      let url = '/filter-validate/query?';

      url = constructURLDates(url, this.activeFilters);

      if (this.activeFilters.company) {

        url += `&company=${this.activeFilters.company}`;
        this.leaderDepartmentsNames = departmentsFromCompany(this.activeFilters.company, this.possibleDepartments);
      }

      url = url.replace('?&', '?');

      ticketFiltered = await this.callValidateTickets(url);

      this.loaded = true;

      let arrayLength = Object.keys(ticketFiltered).length;

      for (let i = arrayLength; i < 10; i++) {
        ticketFiltered.push({ id: null })
      }

      return ticketFiltered;

    },

    async callValidateTickets(url) {

      let ticketFiltered = [];
      let retrievedTicket = [];

      const options = {
        method: 'GET',
        url: url
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.rows = [];
        retrievedTicket = Object.values(response.data['tickets']);
        retrievedTicket = retrievedTicket.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        ticketFiltered = getLeaderTicketsRows(retrievedTicket, this.possibleAuthors, this.ticketAuthorsNames, this.possibleStatus);

        this.filterUrlStorage.setStorageFilterUrl(url);

      } else {

        this.error = true
        this.errorMessage = "Ha ocurrido un error en el filtrado";
      }

      return ticketFiltered;
    },

    goToTicket(id) {
      this.localStorage.setActiveFilters({ ...this.activeFilters });
      this.$router.push(`/leader/ticket?id=${id}&view=${this.view}`);
    },

    goToTicketRow(params) {
      this.goToTicket(params.row.id);
    },
  },
}


</script>

<style lang="scss" scoped>

  @import '@/styles/TableStyles/TableNTStyles.scss';
  @import '@/styles/CommonStyles/BasicElementsStyle.scss';

  a {
    color: #ed3f7c;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: auto 10px auto auto;
    padding: 5px;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }

  div.alert-validated {
    color: rgb(255, 255, 255);
    background-color: #92ba83f6;
    border-radius: 7px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    margin-left: 35%;
    margin-right: 35%;
    padding: 20px;
    -moz-animation: fadeOut 2s;
    -webkit-animation: fadeOut 2s;
    animation: fadeOut 2s;
    z-index: 2000;
    position: absolute;
    width: 32%;
  }

  @keyframes fadeOut {
    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }


  h1 {
    color: #444444;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    padding-top: 30px;
    margin: 0 0 20px;
  }

  p {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }

  .rocket {
    transform: scale(0.75);
    margin-top: 5%;
  }

  .motivater {
    height: 500px;
    margin: auto;
  }

  .table {
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
    user-select: none
  }

  .error {
    margin-top: 20px;
    margin-left: 25%;
    margin-right: 25%;
  }
</style>