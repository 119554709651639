<template>

    <div class="body-list">

        <router-link class="returnLink" to="/admin-panel">
            &lt; Volver
        </router-link>

        <div v-if="!this.loaded">
            <h1 class="register-list"> Lista NODRIZERS &#x1F4DD; </h1>
            <p class="register-list">Aquí puedes consultar todos los NODRIZERS registrados y editar sus datos</p>
        </div>
        
        <div v-if="this.loaded" class="loader">
            <LoaderComponent />
        </div>

        <div class="error-edit" v-if="!this.loaded && this.error">
            <ErrorDialogComponent v-bind:error="this.errorMessage"  v-bind:closeOn="true"/>
        </div>

        <div class="alert-edit" v-if="!this.loaded && this.editCorrect">
            <span class="alert-edit">¡Edición de datos correcta!</span>
        </div>

        <div v-show="this.loadingPending && !this.loaded">
            <SkeletonTable />
        </div>

        <div id="nt-table" class="table" v-if="!this.loaded && !this.error && !this.loadingPending">
            <NodrizaTable ref="table"
                        :mode="'local'"
                        :columns="this.columns"
                        :rows="this.rows"
                        :totalRows="this.rows.length"
                        :perPage="10"
                        :selectable="true"
                        :paginationOptions="{dropdownAllowAll: false, perPage: 10, perPageDropdown: [10,20,50,100,200,500]}"
                        :filterDialogOptions="{dialogFilterDate: {aT: true, bT: true, between: true}}"
                        @requestData="getRows"
                        @select-change="selectChange"/>
        </div>

        <div class="slide-edit" v-if="this.selectedRows.length > 0">
            <SlideMenuEdit 
                v-bind:selectedRows="this.selectedRows" 
                v-bind:adminData="this.adminData" 
                v-bind:managerDepartments="this.managerDepartments" 
                v-bind:managerCompanyDepartments="this.managerCompanyDepartments" 
                v-bind:rolesTranslated="this.rolesTranslated" 
                @sendChanges="getChangesFromSlide"
                @edit-close="onEditClose"
            />
        </div>

    </div>

</template>

<script>

    import axios from '@/services/storage/axios';
    import LocalStorageService from '@/services/storage/LocalStorageService';
    import LoaderComponent from '@/components/LoadersComponents/LoaderComponent.vue';
    import NodrizaTable from '@/components/NodrizaTable/nodriza/table/NodrizaTable';
    import SlideMenuEdit from "@/components/AdminComponents/SlideMenuEditComponent";
    import ErrorDialogComponent from '@/components/GeneralComponents/ErrorDialogComponent';
    import SkeletonTable from '@/components/NodrizaTable/skeletons/SkeletonTable';

    import {dateFromEndpointToDDMMYYYY, dateStringDDMMYYYYToTimestamp} from '@/AppNT/utils/dateConverter';
    import {arraysAreEqual} from "@/AppNT/utils/compareArrays";
    import {deleteNotActiveFilters, isEmpty} from "@/AppNT/utils/tableUtils";
    import { companyNameToId, managerNameToId, departmentNameToId } from '@/AppNT/utils/nameToIdConverter';

    export default {
        components: {
            ErrorDialogComponent,
            LoaderComponent,
            LocalStorageService,
            SlideMenuEdit,
            NodrizaTable,
            SkeletonTable
        },

        data() {
            return {

                adminData: [],

                allSelected: {
                    value: false
                },
                loaded: false,
                loadingPending: true,
                localStorage: null,

                columns: [
                ],
                rows: [
                ],

                formData: null,

                editCorrect: false,

                rolesTranslated: [],
                departmentsByCompany: null,

                error: false,
                errorMessage: "",
                closeError: true,

                selectedRows: [],
                users: [],
                usersData: [],
                possibleUsers: [],

                managerCompanyDepartments: [],
                managerDepartments: [],

                companiesNames: [],
                departmentsNames: [],
                managersNames: [],
                usersNames: [],

                oldColumnsFiltered: [],
                filtered: false,
                activeFilters: [],
                oldDateCreatedAtFiltered: [],
                oldDateUpdatedAtFiltered: [],
                oldCompanyFiltered: null,
                oldDepartmentFiltered: null,
                oldManagerFiltered: null,
                oldUserFiltered: null,

                oldDateToCreated: null,
                oldDateFromCreated: null,
                oldDateAfterCreated: null,
                oldDateBeforeCreated: null,
                oldIntervalCreated: null,

                oldDateToUpdated: null,
                oldDateFromUpdated: null,
                oldDateAfterUpdated: null,
                oldDateBeforeUpdated: null,
                oldIntervalUpdated: null,
            }
        },

        async created() {

            this.loaded = true;

            await this.getAdminData();
            await this.getRows();

            this.loaded = false;

            this.localStorage = LocalStorageService.getService();
        },

        methods: {

            async getAdminData() {

                this.adminData = [];
                this.rolesTranslated = [];

                const options = {
                    method: 'GET',
                    url: '/admin/admin-data',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }

                const response = await axios(options);

                if (!response.errorMessage) {

                    this.adminData = response.data.data;

                    for (let i = 0; i < this.adminData.roles.length; i++) {

                        if (this.adminData.roles[i] === 'ROLE_MANAGER')
                            this.rolesTranslated.push('Responsable');

                        else if (this.adminData.roles[i] === 'ROLE_ADMIN')
                            this.rolesTranslated.push('Administrador');
                    }

                    // Get cfilter names:

                    this.getFilterNames();

                } else {

                    this.error = true
                    this.errorMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo.";
                }

                this.loaded = false;
            },

            getFilterNames() {

                this.companiesNames = [];
                this.departmentsNames = [];
                this.managersNames = [];
                
                for (let i = 0; i < this.adminData.companies.length; i++) {
                    this.companiesNames.push(this.adminData.companies[i].company_name)
                }

                for (let i = 0; i < this.adminData.departments.length; i++) {
                    this.departmentsNames.push(this.adminData.departments[i].dept_name)
                }

                for (let i = 0; i < this.adminData.managers.length; i++) {
                    this.managersNames.push(this.adminData.managers[i].name)
                }

                for (let i = 0; i < this.adminData.users.length; i++) {
                    this.usersNames.push(this.adminData.users[i].first_name)
                } 
            },

            pushColumns() {

                const columns = [

                    {
                        label: 'Nombre',
                        field: 'first_name',
                        filterable: true,
                        sortable: true,
                        input: null,
                        filterOptions: {
                            enabled: true,
                            single: true,
                            searchable: true,
                            customFilterSelect: true,
                            filterDropdownItems: this.usersNames
                        },
                        width: "10%"
                    },
                    {
                        label: 'Apellidos',
                        field: 'last_name',
                        filterable: false,
                        sortable: true,
                        input: null,
                        filterOptions: {},
                        width: "10%",
                    },

                    {
                        label: 'Email',
                        field: 'email',
                        sortable: true,
                        filterable: false,
                        input: null,
                        filterOptions: {},
                        width: "5%",
                    },
                    {
                        label: 'Empresa',
                        field: 'company',
                        filterOptions: {
                            enabled: true,
                            searchable: true,
                            customFilterSelect: true,
                            filterDropdownItems: this.companiesNames
                        },
                        select: '',
                        input: null,
                        width: "10%",
                    },
                    {
                        label: 'Departamento',
                        field: 'department',
                        filterable: false,
                        filterOptions: {
                            enabled: true,
                            searchable: true,
                            customFilterSelect: true,
                            filterDropdownItems: this.departmentsNames
                        },
                        select: '',
                        input: null,
                        width: "10%",
                    },
                    {
                        label: 'Responsable/s',
                        field: 'managers',
                        filterable: false,
                        filterOptions: {
                            enabled: true,
                            searchable: true,
                            customFilterSelect: true,
                            filterDropdownItems: this.managersNames
                        },
                        select: '',
                        sortable: false,
                        input: null,
                        width: "10%",
                    },
                    {
                        label: 'Creado',
                        type: 'date',
                        dateInputFormat: 't',
                        dateOutputFormat: 'dd/MM/yyyy',
                        field: 'created_at',
                        filterable: false,
                        filterOptions: {
                            enabled: true,
                            customFilterDate: true,
                            iconDate: true
                        },
                        select: '',
                        input: null,
                        width: "5%",
                    },
                    {
                        label: 'Actualizado',
                        type: 'date',
                        dateInputFormat: 't',
                        dateOutputFormat: 'dd/MM/yyyy',
                        field: 'updated_at',
                        filterable: false,
                        filterOptions: {
                            enabled: true,
                            customFilterDate: true,
                            iconDate: true
                        },
                        select: '',
                        input: null,
                        width: "5%",
                    },
                    {
                        label: 'Roles',
                        field: 'roles',
                        sortable: false,
                        filterable: false,
                        input: null,
                        width: "13%",
                        filterOptions: {},
                    },
                    {
                        label: 'Activo',
                        field: 'active',
                        filterable: false,
                        sortable: true,
                        input: null,
                        filterOptions: {},
                        width: "2%",
                    },
                    {
                        label: '',
                        field: 'actions',
                        width: '1%',
                        filterable: false,
                        clearFilter: true,
                        sortable: false,
                        type: 'button',
                        input: null,
                        filterOptions: {},
                    }
                ]

                this.columns = [...columns];
            },

            async getRows(filters) {

                try {

                    if (!filters) {

                        this.rows = await this.getNODRIZERS();
                    }

                    else if (Object.entries(filters.columns).length > 0
                        && arraysAreEqual(this.oldColumnsFiltered, Object.values(filters.columns)) === false) {

                        this.oldColumnsFiltered = [...Object.values(filters.columns)];
                        this.filtered = true;
                        await deleteNotActiveFilters(filters.columns, this.activeFilters);

                        this.rows = Object.values(await this.callFilters(filters.columns));
                    }

                    
                    else if (Object.entries(filters.columns).length === 0 && this.filtered) {

                        this.oldColumnFiltered = [];
                        await deleteNotActiveFilters(filters.columns, this.activeFilters);
                        this.rows = await this.getNODRIZERS();
                        this.filtered = false;
                    }

                    this.loadingPending = false;

                }
                catch (e) {
                    console.error('Filter request error ', e);

                }

            },

            async getNODRIZERS() {

                let nodrizers = []

                const options = {
                    method: 'GET',
                    url: '/admin/users',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }

                const response = await axios(options);

                if (!response.errorMessage) {

                    this.rows = []

                    //Get tickets from response
                    this.users = Object.values(response.data['users'])

                    this.users = this.users.sort((a, b) => parseInt(a.id) - parseInt(b.id))

                    let individualUser

                    for (let i = 0; i < this.users.length; i++) {

                        individualUser = this.users.slice(i, i + 1)

                        let exist = false;
                        let active = 'Activo';
                        let roles = [];
                        let managers = "";;
                        let managersIds = [];
                        let created_at = null;
                        let updated_at = null;

                        let user = {
                            id: individualUser[0]['id']
                        }

                        for (let i = 0; i < this.possibleUsers.length; i++) {
                            if (this.possibleUsers[i].id === user.id) {
                                exist = true
                            }
                        }

                        if (!exist) {
                            this.possibleUsers.push(user)
                        }

                        if (!individualUser[0]['active']) {
                            active = 'No activo'
                        }

                        let totalManagers = individualUser[0]['managers'].length

                        for (let i = 0; i < individualUser[0]['managers'].length; i++) {

                            if (i < totalManagers - 1) {

                                managers = managers + individualUser[0]['managers'][i].name + ", "

                            } else {

                                managers = managers + individualUser[0]['managers'][i].name
                            }

                            managersIds.push(individualUser[0]['managers'][i].id);
                        }

                        for (let i = 0; i < individualUser[0]['roles'].length; i++) {

                            if (individualUser[0]['roles'][i] === 'ROLE_USER')
                                roles.push('Usuario')

                            else if (individualUser[0]['roles'][i] === 'ROLE_MANAGER')
                                roles.push('Responsable')

                            else if (individualUser[0]['roles'][i] === 'ROLE_ADMIN')
                                roles.push('Administrador')
                        }

                        let writeRoles = ""

                        for (let i = 0; i < roles.length; i++) {

                            if (writeRoles != "")
                                writeRoles = writeRoles + ", " + roles[i]
                            else
                                writeRoles = writeRoles + roles[i]
                        }

                        // Get created and updated at dates

                        if (individualUser[0]['updated_at'] != null) {

                            updated_at = dateFromEndpointToDDMMYYYY(individualUser[0]['updated_at']);
                        }

                        created_at = dateFromEndpointToDDMMYYYY(individualUser[0]['created_at']);


                        nodrizers.push({
                            'id': individualUser[0]['id'],
                            'company': individualUser[0]['company'].name,
                            'company_id': individualUser[0]['company'].id,
                            'department': individualUser[0]['department'].name,
                            'department_id': individualUser[0]['department'].id,
                            'managers': managers,
                            'managers_ids': managersIds,
                            'first_name': individualUser[0]['first_name'],
                            'last_name': individualUser[0]['last_name'],
                            'email': individualUser[0]['email'],
                            'active': active,
                            'roles': writeRoles,
                            'created_at': created_at,
                            'updated_at': updated_at
                        })

                    }

                    this.pushColumns();

                } else {

                    this.error = true
                    this.errorMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo.";
                }

                return nodrizers;
            },

            callFilters(columns) {

                // User column

                if (columns.first_name && !('first_name' in this.activeFilters)
                    ||
                    (columns.first_name && 'first_name' in this.activeFilters
                        &&
                        this.oldUserFiltered !== columns.first_name.value)) {

                    this.oldUserFiltered = columns.first_name.value;

                    this.activeFilters['first_name'] = columns.first_name.value;
                }

                // Company column

                if (columns.company && !('company' in this.activeFilters)
                    ||
                    (columns.company && 'company' in this.activeFilters
                        &&
                        this.oldCompanyFiltered !== columns.company.value)) {

                    this.oldCompanyFiltered = columns.company.value;

                    this.activeFilters['company'] = companyNameToId(this.oldCompanyFiltered, this.adminData.companies)
                }


                // Department column

                if (columns.department && !('department' in this.activeFilters)
                    ||
                    (columns.department && 'department' in this.activeFilters
                        &&
                        this.oldDepartmentFiltered !== columns.department.value)) {

                    this.oldDepartmentFiltered = columns.department.value

                    this.activeFilters['department'] = departmentNameToId(this.oldDepartmentFiltered, this.adminData.departments)
                }

                // Managers column

                if (columns.managers && !('managers' in this.activeFilters)
                    ||
                    (columns.managers && 'managers' in this.activeFilters
                        &&
                        this.oldManagerFiltered !== columns.managers.value)) {

                    this.oldManagerFiltered = columns.managers.value

                    this.activeFilters['managers'] = managerNameToId(this.oldManagerFiltered, this.adminData.managers)
                }


                // Created at column

                if (columns.created_at && !('created_at' in this.activeFilters)
                    ||
                    (columns.created_at && ('created_at' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.created_at && 'created_at' in this.activeFilters
                        &&
                        this.oldDateCreatedAtFiltered !== [...Object.values(columns.created_at)])) {

                    if (columns.created_at.type === 'between'
                        && (this.oldDateFromCreated !== columns.created_at.value[0] || this.oldDateToCreated !== columns.created_at.value[1])) {

                        this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)]

                        this.oldDateFromCreated = columns.created_at.value[0]
                        this.oldDateToCreated = columns.created_at.value[1]

                        this.oldIntervalCreated = columns.created_at.type


                        this.activeFilters['created_at'] = {

                            from: dateStringDDMMYYYYToTimestamp(this.oldDateFromCreated),
                            to: dateStringDDMMYYYYToTimestamp(this.oldDateToCreated),
                            interval: this.oldIntervalCreated,

                        }


                    } else if (columns.created_at.type === 'LT'
                        && (this.oldIntervalCreated !== columns.created_at.type
                        || this.oldDateBeforeCreated !== columns.created_at.value)) {

                        this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)]

                        this.oldDateBeforeCreated = columns.created_at.value

                        this.oldIntervalCreated = columns.created_at.type

                        this.activeFilters['created_at'] = {

                            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeCreated),
                            interval: 'before',

                        }


                    } else if (columns.created_at.type === 'GT'
                        && (this.oldIntervalCreated !== columns.created_at.type
                        || this.oldDateAfterCreated !== columns.created_at.value)) {

                        this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)]

                        this.oldDateAfterCreated = columns.created_at.value

                        this.oldIntervalCreated = columns.created_at.type

                        this.activeFilters['created_at'] = {

                            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterCreated),
                            interval: 'after',

                        }

                    }
                }

                // Updated at column

                if (columns.updated_at && !('updated_at' in this.activeFilters)
                    ||
                    (columns.updated_at && ('updated_at' in this.activeFilters) && this.rows.length === 0)
                    ||
                    (columns.updated_at && 'updated_at' in this.activeFilters
                        &&
                        this.oldDateUpdatedAtFiltered !== [...Object.values(columns.updated_at)])) {

                    if (columns.updated_at.type === 'between'
                        && (this.oldDateFromUpdated !== columns.updated_at.value[0] 
                        || this.oldDateToUpdated !== columns.updated_at.value[1])) {

                        this.oldDateUpdatedAtFiltered = [...Object.values(columns.updated_at)]

                        this.oldDateFromUpdated = columns.updated_at.value[0]
                        this.oldDateToUpdated = columns.updated_at.value[1]

                        this.oldIntervalUpdated = columns.updated_at.type


                        this.activeFilters['updated_at'] = {

                            from: dateStringDDMMYYYYToTimestamp(this.oldDateFromUpdated),
                            to: dateStringDDMMYYYYToTimestamp(this.oldDateToUpdated),
                            interval: this.oldIntervalUpdated,
                        }


                    } else if (columns.updated_at.type === 'LT'
                            && (this.oldIntervalUpdated !== columns.updated_at.type
                            || this.oldDateBeforeUpdated !== columns.updated_at.value)) {

                        this.oldDateUpdatedAtFiltered = [...Object.values(columns.updated_at)]

                        this.oldDateBeforeUpdated = columns.updated_at.value

                        this.oldIntervalUpdated = columns.updated_at.type

                        this.activeFilters['updated_at'] = {

                            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeUpdated),
                            interval: 'before',

                        }


                    } else if (columns.updated_at.type === 'GT'
                        && (this.oldIntervalUpdated !== columns.updated_at.type
                            || this.oldDateAfterUpdated !== columns.updated_at.value)) {

                        this.oldDateUpdatedAtFiltered = [...Object.values(columns.updated_at)]

                        this.oldDateAfterUpdated = columns.updated_at.value

                        this.oldIntervalUpdated = columns.updated_at.type

                        this.activeFilters['updated_at'] = {

                            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterUpdated),
                            interval: 'after',

                        }

                    }
                }

                return this.callFilterEndpoint()
            },

            async callFilterEndpoint() {

                let nodrizers = []

                let url = '/admin/filter-users/query?'

                if (this.activeFilters.created_at) {
                    if (this.activeFilters.created_at.interval === 'between') {
                        url += `&created_date=${this.activeFilters.created_at.from}-${this.activeFilters.created_at.to}&created_itv=${this.activeFilters.created_at.interval}`;
                    }

                    else if (this.activeFilters.created_at.interval === 'after' || 'before') {
                        url += `&created_date=${this.activeFilters.created_at.from}&created_itv=${this.activeFilters.created_at.interval}`;
                    }
                }

                if (this.activeFilters.updated_at) {
                    if (this.activeFilters.updated_at.interval === 'between') {
                        url += `&updated_date=${this.activeFilters.updated_at.from}-${this.activeFilters.updated_at.to}&updated_itv=${this.activeFilters.updated_at.interval}`;
                    }

                    else if (this.activeFilters.updated_at.interval === 'after' || 'before') {
                        url += `&updated_date=${this.activeFilters.updated_at.from}&updated_itv=${this.activeFilters.updated_at.interval}`;
                    }
                }

                if (this.activeFilters.department) {
                    url += `&department=${this.activeFilters.department}`;
                }

                if (this.activeFilters.company) {
                    url += `&company=${this.activeFilters.company}`;
                }

                if (this.activeFilters.managers) {
                    url += `&managers=${this.activeFilters.managers}`;
                }

                if (this.activeFilters.first_name) {
                    url += `&user=${this.activeFilters.first_name}`;
                }

                url = url.replace('?&', '?');

                const options = {
                    method: 'GET',
                    url: url
                }

                const response = await axios(options);

                if (!response.errorMessage) {

                    this.rows = [];

                    //Get tickets from response
                    this.users = Object.values(response.data['users']);
                    this.users = this.users.sort((a, b) => parseInt(a.id) - parseInt(b.id));

                    let individualUser;

                    for (let i = 0; i < this.users.length; i++) {

                        individualUser = this.users.slice(i, i + 1)

                        let exist = false;
                        let active = 'Activo';
                        let roles = [];
                        let managers = "";;
                        let managersIds = [];
                        let created_at = null;
                        let updated_at = null;

                        let user = {
                            id: individualUser[0]['id']
                        }

                        for (let i = 0; i < this.possibleUsers.length; i++) {
                            if (this.possibleUsers[i].id === user.id) {
                                exist = true;
                            }
                        }

                        if (!exist) {
                            this.possibleUsers.push(user)
                        }

                        if (!individualUser[0]['active']) {
                            active = 'No activo';
                        }

                        let totalManagers = individualUser[0]['managers'].length

                        for (let i = 0; i < individualUser[0]['managers'].length; i++) {

                            if (i < totalManagers - 1) {

                                managers = managers + individualUser[0]['managers'][i].name + ", "

                            } else {

                                managers = managers + individualUser[0]['managers'][i].name
                            }

                            managersIds.push(individualUser[0]['managers'][i].id);
                        }

                        for (let i = 0; i < individualUser[0]['roles'].length; i++) {

                            if (individualUser[0]['roles'][i] === 'ROLE_USER')
                                roles.push('Usuario');

                            else if (individualUser[0]['roles'][i] === 'ROLE_MANAGER')
                                roles.push('Responsable');

                            else if (individualUser[0]['roles'][i] === 'ROLE_ADMIN')
                                roles.push('Administrador');
                        }

                        let writeRoles = "";

                        for (let i = 0; i < roles.length; i++) {

                            if (writeRoles != "")
                                writeRoles = writeRoles + ", " + roles[i];
                            else
                                writeRoles = writeRoles + roles[i];
                        }

                        // Get created and updated at dates

                        if (individualUser[0]['updated_at'] != null) {
                            updated_at = dateFromEndpointToDDMMYYYY(individualUser[0]['updated_at']);
                        }

                        created_at = dateFromEndpointToDDMMYYYY(individualUser[0]['created_at']);


                        nodrizers.push({
                            'id': individualUser[0]['id'],
                            'company': individualUser[0]['company'].name,
                            'company_id': individualUser[0]['company'].id,
                            'department': individualUser[0]['department'].name,
                            'department_id': individualUser[0]['department'].id,
                            'managers': managers,
                            'managers_ids': managersIds,
                            'first_name': individualUser[0]['first_name'],
                            'last_name': individualUser[0]['last_name'],
                            'email': individualUser[0]['email'],
                            'active': active,
                            'roles': writeRoles,
                            'created_at': created_at,
                            'updated_at': updated_at
                        });
                    }

                } else {

                    this.error = true;
                    this.errorMessage = "Ha ocurrido un error inesperado. Inténtalo de nuevo.";
                }

                this.loaded = false;

                return nodrizers
            },

            selectChange(usersSelected) {

                this.selectedRows = usersSelected

                // Get manager departaments if only one row and manager is selected:
                // Get manager's company departments if only one row is selected// Get manager's company departments if only one row is selected

                if (this.selectedRows.length === 1 && this.selectedRows[0].roles.includes('Responsable')) {

                    this.managerDepartments = []
                    this.managerCompanyDepartments = []

                    for (let i = 0; i < this.adminData.departments.length; i++) {

                        if (this.adminData.departments[i].dept_manager === this.selectedRows[0].id) {

                            this.managerDepartments.push(this.adminData.departments[i])
                        }

                        if (this.adminData.departments[i].company.company_id === this.selectedRows[0].company_id) {

                            this.managerCompanyDepartments.push(this.adminData.departments[i])
                        }
                    }
                }
            },

            async getChangesFromSlide(formData) {

                this.formData = formData

                let selectedRowsForSend = this.selectedRows

                await this.getRows()

                // Check if all form is null, if not, calls to server

                if (!this.checkAllNullForm())
                    await this.sendChangesToServer(selectedRowsForSend)

                this.getRows()
            },

            async sendChangesToServer(selectedRowsForSend) {

                let dataChanged = []

                for (let i = 0; i < selectedRowsForSend.length; i++) {

                    let userDataChanged = {
                        user_id: null,
                        active: null,
                        company: null,
                        department: null,
                        managers: {
                            action: null,
                            manager: null,
                            replaceForManager: null
                        },
                        department_manager: {
                            action: null,
                            departmentManager: null,
                            replaceForDepartmentManager: null
                        },
                        role: {
                            action: null,
                            role: null
                        },
                        personal_data: {
                            first_name: null,
                            last_name: null,
                            email: null
                        }
                    }

                    userDataChanged.user_id = selectedRowsForSend[i].id

                    if (this.formData.changeActive === true || this.formData.changeActive === false) {

                        userDataChanged.active = this.formData.changeActive

                    } else {

                        userDataChanged.active = null
                    }


                    if (this.formData.changeCompany.company != null) {

                        userDataChanged.company = this.formData.changeCompany.company.company_id

                    } else {

                        userDataChanged.company = this.checkNullData(this.formData.changeCompany.company)
                    }

                    if (this.formData.changeDepartment != null) {

                        userDataChanged.department = this.checkNullData(this.formData.changeDepartment.dept_id)

                    } else {

                        userDataChanged.department = this.checkNullData(this.formData.changeDepartment)
                    }

                    if (this.formData.changeManager != null && this.formData.changeManager.action != 'keep') {

                        userDataChanged.managers.action = this.checkNullData(this.formData.changeManager.action)
                        userDataChanged.managers.manager = this.checkNullData(this.formData.changeManager.valueManager)

                        if (this.formData.changeManager.action === 'change') {

                            userDataChanged.managers.replaceForManager = this.checkNullData(this.formData.changeManager.changeValueManager)
                        }
                    }

                    if (this.formData.changeManagerDepartment != null && this.formData.changeManagerDepartment.action != 'keep') {

                        userDataChanged.department_manager.action = this.checkNullData(this.formData.changeManagerDepartment.action)
                        userDataChanged.department_manager.departmentManager = this.checkNullData(this.formData.changeManagerDepartment.valueDepartmentManager.dept_id)

                        if (this.formData.changeManagerDepartment.action === 'change') {

                            userDataChanged.department_manager.replaceForDepartmentManager = this.checkNullData(this.formData.changeManagerDepartment.changeValueDepartmentManager.dept_id)
                        }
                    }

                    if (this.formData.changeRole != null && this.formData.changeRole.action != 'keep') {

                        userDataChanged.role.action = this.checkNullData(this.formData.changeRole.action)
                        userDataChanged.role.role = this.checkNullData(this.formData.changeRole.valueRole)
                    }

                    dataChanged.push(userDataChanged)
                }

                const options = {
                    method: 'PUT',
                    url: '/admin/edit-users',
                    data: dataChanged
                }

                console.log('options to edit: ', options);

                const response = await axios(options);

                if (!response.errorMessage) {

                    this.editCorrect = true;
                    setTimeout(() => this.editCorrect = false, 2100);
                    this.getAdminData();

                } else {

                    this.error = true;
                    this.errorMessage = "Ha ocurrido un error al editar los datos";
                }

                this.loaded = false;
            },

            checkNullData(field) {

                let newValue

                if (field === '-' || field === null)
                    newValue = null

                else
                    newValue = field


                return newValue
            },

            checkAllNullForm() {

                let allNull = false;
                let activeNull = false;
                let companyNull = false;
                let departmentNull = false;
                let managerNull = false;
                let managerDeptNull = false;


                managerNull = this.formData.changeManager.valueManager === null && this.formData.changeManager.changeValueManager === null ? true : false
                managerDeptNull = this.formData.changeManagerDepartment.valueDepartmentManager === null && this.formData.changeManagerDepartment.changeValueDepartmentManager === null ? true : false
                activeNull = this.formData.changeActive.active === null ? true : false
                companyNull = this.formData.changeCompany.company === null ? true : false
                departmentNull = this.formData.changeDepartment === null ? true : false

                allNull = managerNull && managerDeptNull && activeNull && companyNull && departmentNull ? true : false


                return allNull;
            },

            onEditClose() {

                this.selectedRows = []
                this.getRows()
            }
        }

    }
</script>

<style scoped>

    div.alert-edit {
        color: rgb(255, 255, 255);
        background-color: #92ba83;
        border-radius: 7px;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 600;
        margin-top: 40px;
        margin-left: 30%;
        margin-right: 30%;
        padding: 20px;
        -moz-animation: inAndOut var(--duration) ease-in forwards;
        -webkit-animation: inAndOut var(--duration) ease-in forwards;
        animation: inAndOut var(--duration) ease-in forwards;
        --duration: 2s;
    }

    .body-list {
        height: 90%;
    }


    .slide-edit {
        background-color: #f7fafc;
        border-left: 1px solid #e3e8ee;
        top: 76px;
        right: 0;
        height: 910px;
        max-height: 100%;
        overflow-y: auto;
        position: fixed;
        width: 25%;
    }

    .slide-edit::-webkit-scrollbar {
        background-clip: padding-box;
        background-color: #e7e4e4;
        border: 4px solid transparent;
        border-radius: 8px;
        width: 16px;
    }

    .slide-edit::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: #ED3F7C;
        border: 4px solid transparent;
        border-radius: 16px;
        width: 10px;
    }

    .table {
        font-family:'Montserrat', sans-serif;
        margin-top: 30px;
        margin-left: 5%;
        margin-right: 5%;
        user-select:none;
    }

    /* animation of edit correct */

    @keyframes inAndOut {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

</style>