
import { createRouter, createWebHistory } from 'vue-router'

import SendTicketView from '../views/UserViews/SendTicketView'
import TicketReceivedView from '../views/UserViews/TicketReceivedView'
import NoPageFound from '../views/UserViews/NoPageFound'
import UserTicketsView from "@/views/UserViews/UserTicketsView"
import UserDetailedTicketView from "@/views/UserViews/UserDetailedTicketView"
import HomepageView from "@/views/HomePage/HomepageView"
import LoginView from "@/views/Login/LoginView"
import LoginStoreView from "@/views/Login/LoginStoreView"
import LocalStorageService from '@/services/storage/LocalStorageService'
import { expireDate } from '@/services/utils'
import LeaderTicketsView from "@/views/LeaderViews/LeaderTicketsView"
import DetailTicketManagerView from "@/views/LeaderViews/DetailTicketManagerView"
import TicketValidatedView from "@/views/LeaderViews/TicketValidatedView"
import AdminPanelView from "@/views/AdminViews/AdminPanelView"
import RegisterNODRIZERSView from "@/views/AdminViews/RegisterNODRIZERSView"
import ListNODRIZERSView from "@/views/AdminViews/ListNODRIZERSView"
import AdminTicketsView from "@/views/AdminViews/AdminTicketsView"
import DetailTicketAdminView from "@/views/AdminViews/DetailTicketAdminView"
import UserTransactionView from "@/views/UserViews/UserTransactionsView"

const managerRoutes = [
    'LeaderTickets',
    'DetailManagerTicket',
    'TicketValidated'
]

const adminRoutes = [
    'AdminPanelView',
    'RegisterNODRIZERSView',
    'ListNODRIZERSView',
    'AdminTicketsView',
    'DetailAdminTicketView'
]

const routes = [
    {
        path: '/send',
        name: 'SendTicket',
        component: SendTicketView,
        props: (route) => {
            let ticketType = route.query.type;

            switch (ticketType) {
                case 'lean':
                    ticketType = 'Lean'
                    break;

                case 'smartDecision':
                    ticketType = 'Smart Decision'
                    break;

                case 'sc1':
                    ticketType = 'SC1'
                    break;

                case 'sc2':
                    ticketType = 'SC2'
                    break;

                case 'pulpoIMI':
                    ticketType = 'Pulpo IMI'
                    break;

                case '4e':
                    ticketType = '4E'
                    break;

                case 'sugerencia':
                    ticketType = 'Sugerencia'    
                    break;

                case 'gracias':
                    ticketType = 'Gracias'
                    break;

                case 'autoformacion':
                    ticketType = 'Autoformación'
                    break;

                default:
                    ticketType = 'notFound'
                    break;

            }

            return {
                ticketType 
            }
        }
    },
    {
        path: '/received',
        name: 'TicketReceived',
        component: TicketReceivedView,
        props: (route) => {
            const ticketType = route.query.type;
            const notification = route.query.notification;

            return (!ticketType || !notification) ? router.push('notFound') :  {

                ticketType,
                notification
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: NoPageFound
    },
    {
        path: '/user/tickets',
        name: 'UserTickets',
        component: UserTicketsView
    },
    {
        path: '/user/transactions',
        name: 'UserTransactions',
        component: UserTransactionView
    },
    {
        path: '/user/ticket',
        name: 'DetailedTicket',
        component: UserDetailedTicketView,
        props: (route) => {
            const ticketId = route.query.id;

            return (!ticketId) ? router.push('notFound') : {

                ticketId
            }
        }
    },
    {
        path: '/user/transactions/ticket',
        name: 'DetailedTicketTransaction',
        component: UserDetailedTicketView,
        props: (route) => {
            const ticketId = route.query.id;

            return (!ticketId) ? router.push('notFound') : {

                ticketId
            }
        }
    },
    {
        path: '/leader/tickets',
        name: 'LeaderTickets',
        component: LeaderTicketsView,
        props: (route) => {
            const view = route.query.view;

            return (!view || view !== 'team' && view !== 'validate') ? router.push('notFound') : {

                view
            }
        }
    },
    {
        path: '/leader/ticket',
        name: 'DetailManagerTicket',
        component: DetailTicketManagerView,
        props: (route) => {

            const ticketId = route.query.id;
            const view = route.query.view;

            return (!ticketId || !view || view !== 'team' && view !== 'validate') ? router.push('notFound') : {

                ticketId,
                view
            }
        }
    },
    {
        path: '/validated',
        name: 'TicketValidated',
        component: TicketValidatedView,
        props: (route) => {
            const author = route.query.author
            const notification = route.query.notification

            return (!notification || !author) ? router.push('notFound') :  {

                notification,
                author
            }
        }
    },
    {
        path:'/admin-panel',
        name: 'AdminPanelView',
        component: AdminPanelView
    },
    {
        path:'/admin-panel/register',
        name: 'RegisterNODRIZERSView',
        component: RegisterNODRIZERSView
    },
    {
        path:'/admin-panel/list-nodrizers',
        name: 'ListNODRIZERSView',
        component: ListNODRIZERSView
    },
    {
        path: '/admin/tickets',
        name: 'AdminTicketsView',
        component: AdminTicketsView
    },
    {
        path: '/admin/ticket',
        name: 'DetailAdminTicketView',
        component: DetailTicketAdminView,
        props: (route) => {

            const ticketId = route.query.id;

            return (!ticketId) ? router.push('notFound') : {
                ticketId
            }
        }
    },
    {
        path:'/',
        name: 'HomepageView',
        component: HomepageView
    },
    {
        path: '/login',
        name: 'LoginView',
        component: LoginView
    },
    {
        path: '/login_store',
        name: 'LoginStoreView',
        component: LoginStoreView,
        props: (route) => {
            const data = route.query.data;

            return (!data) ? router.push('notFound') :  {
                data
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {

    const localStorageService = LocalStorageService.getService();
    const token = localStorageService.getAccessToken();

    const tokenExpired = token && expireDate(token);
    const isLoginView = to.name === 'LoginView' || to.name === 'LoginStoreView';

    const mustRedirectToHomeFromLogin = isLoginView && (token && !expireDate(token));
    
    if (mustRedirectToHomeFromLogin) {
        router.push({ name: 'HomepageView' });
        return;
    }

    const mustRedirectToLogin = (!isLoginView && !token) || (!isLoginView && tokenExpired);

    if (mustRedirectToLogin) {
        router.push({ name: 'LoginView' });
        return;
    }

    let roles = localStorageService.getUserRoles();

    const isManagerRoute = managerRoutes.find(element => element === to.name);

    if (isManagerRoute && !roles.includes('ROLE_MANAGER')) {
        router.push({ name: 'HomepageView' });
        return;
    }

    const isAdminRoute = adminRoutes.find(element => element === to.name);

    if (isAdminRoute && !roles.includes('ROLE_ADMIN')) {
        router.push({ name: 'HomepageView' });
        return;
    }

    next();
})

export default router;