<template>

  <div class="alert" v-if="this.error">
    <span v-if="this.closeOn" class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
    <strong>{{this.error}}</strong>
  </div>

</template>

<script>
export default {
  name: "ErrorDialogComponent",

  props: {
    error: null,
    closeOn: null
  }

}
</script>

<style scoped>

.alert {
  padding: 30px;
  background-color: #ff6c61;
  color: white;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
}
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 35px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.closebtn:hover {
  color: black;
}

</style>