<template>


  <div class="body">

    <router-link class="returnLink" to="/">
      &lt; Volver
    </router-link>

    <div v-if="this.loaded && this.userTickets.length >= 0">
      <h1>Consulta de tickets</h1>
      <p>Aquí puedes consultar todo sobre los tickets que has enviado como NODRIZER</p>
    </div>

    <div v-if="this.loaded && this.userTickets.length === 0">
      <h1> ¡Vaya! ¡Todavía no has introducido ningún ticket!</h1>
      <h3>¡Seguro que tienes ideas geniales que poner en práctica!</h3>
    </div>

    <div class="error" v-if="this.error">
      <ErrorDialogComponent v-bind:error="this.errorMessage" v-bind:closeOn="true" />
    </div>

    <div class="motivater" v-if="this.loaded && this.userTickets.length === 0">
      <MotivaterShipComponent class="rocket" />
    </div>

    <!-- ME HE QUEDADO EN REDIRECCIÓN EN ROW CLICK -->

    <div class="table" v-if="this.loaded && (this.userTickets.length > 0)">
      <NodrizaTable ref="table" :mode="'local'" :columns="columns" :rows="rows" :totalRows="rows.length" :perPage="10"
        :selectable="false"
        :paginationOptions="{ dropdownAllowAll: false, perPage: 10, perPageDropdown: [10, 20, 50, 100, 200, 500] }"
        :filterDialogOptions="{ dialogFilterDate: { aT: true, bT: true, between: true } }" @on-row-click="goToTicketRow" @requestData="getRows">
        <template v-slot:change-field="props">
          <RedirectButtonComponent class="button" text="Ver ticket" render="ticket"
            v-if="props.props.column.field === 'button' && props.props.row.id !== null"
            @click="goToTicket(props.props.row.id)" />
          <p v-if="props.props.column.field === 'type'" class="pink_mark">
            {{ props.props.row.type }}
          </p>
          <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 1" class="orange_mark">
            {{ props.props.row.status }}
          </p>
          <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 2" class="green_mark">
            {{ props.props.row.status }}
          </p>
          <p v-if="props.props.column.field === 'status' && props.props.row.statusId === 3" class="red_mark">
            {{ props.props.row.status }}
          </p>
        </template>
      </NodrizaTable>
    </div>
    <div class="loader" v-if="!this.loaded && !this.error">
      <LoaderComponent />
    </div>

  </div>


</template>

<script>

import LoaderComponent from '@/components/LoadersComponents/LoaderComponent'
import ErrorDialogComponent from "@/components/GeneralComponents/ErrorDialogComponent";
import MotivaterShipComponent from "@/components/GeneralComponents/MotivaterShipComponent";
import axios from '@/services/storage/axios'
import RedirectButtonComponent from "@/components/PersonalizedTableElements/RedirectButtonComponent";
import NodrizaTable from "@/components/NodrizaTable/nodriza/table/NodrizaTable.vue";
import LocalStorageService from "@/services/storage/LocalStorageService"

import { statusNameToId, typeNameToId } from "@/AppNT/utils/nameToIdConverter";
import { arraysAreEqual } from "@/AppNT/utils/compareArrays";
import { getPossibleFilters } from "@/AppNT/endpointsCalls/getPossibleFilters";
import { dateStringDDMMYYYYToTimestamp } from "@/AppNT/utils/dateConverter";
import { deleteNotActiveFilters, getUserTicketsRows, constructURLDates } from '@/AppNT/utils/tableUtils/util';



export default {

  name: "UserTicketsView",
  components: {
    LoaderComponent,
    ErrorDialogComponent,
    MotivaterShipComponent,
    NodrizaTable,
    RedirectButtonComponent

  },


  data() {
    return {

      userTickets: [],

      ticketTypesNames: [],
      possibleTypes: [],

      ticketStatusNames: [],
      possibleStatus: [],

      activeFilters: [],
      filteredTickets: [],

      oldTypeFiltered: null,
      oldStatusFiltered: null,
      oldColumnFiltered: [],

      oldDateToCreated: null,
      oldDateFromCreated: null,
      oldDateAfterCreated: null,
      oldDateBeforeCreated: null,
      oldIntervalCreated: null,

      oldDateToValidated: null,
      oldDateFromValidated: null,
      oldDateAfterValidated: null,
      oldDateBeforeValidated: null,
      oldIntervalValidated: null,

      oldDateCreatedAtFiltered: null,
      oldDateValidatedAtFiltered: null,

      error: false,
      errorMessage: null,
      loaded: false,
      date: null,
      localStorage: null,
      loadingFilter: false,
      filtered: false,


      columns: [
      ],
      rows: [
      ],
    }
  },
  async created() {
    this.localStorage = LocalStorageService.getService()
    await this.getFilters()
    await this.getRows()


  },
  methods: {

    async getFilters() {

      let possibleFiltersData = await getPossibleFilters('user-manager')

      this.ticketTypesNames = possibleFiltersData.types_data.types_names;
      this.possibleTypes = possibleFiltersData.types_data.types;

      this.ticketStatusNames = possibleFiltersData.status_data.status_names;
      this.possibleStatus = possibleFiltersData.status_data.status;

    },

    async getRows(filters) {

      try {

        if (!filters) {

          this.rows = await this.getUserTickets();
        }

        else if (Object.values(filters.columns).length > 0
          && !arraysAreEqual(this.oldColumnFiltered, Object.values(filters.columns))) {

          this.oldColumnFiltered = [...Object.values(filters.columns)];

          this.loadingFilter = true;
          this.filtered = true;

          await deleteNotActiveFilters(filters.columns, this.activeFilters);
          this.rows = Object.values(await this.callFilters(filters.columns));
          this.loadingFilter = false;
        }

        else if (Object.entries(filters.columns).length === 0 && this.filtered) {

          this.oldColumnFiltered = [];
          await deleteNotActiveFilters(filters.columns, this.activeFilters);
          this.rows = await this.getUserTickets();
          this.filtered = false;
        }


      } catch (e) {
        console.error('Filter request error ', e);

      }
    },

    async getUserTickets() {

      let tickets = [];

      const options = {
        method: 'GET',
        url: '/user/tickets'
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.rows = [];

        this.userTickets = Object.values(response.data['tickets']);
        this.userTickets = this.userTickets.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        tickets = getUserTicketsRows(this.userTickets, this.possibleStatus);

        this.pushColumns();

      } else {

        this.error = true;
        this.errorMessage = response.errorMessage;
      }

      this.loaded = true;

      let arrayLength = Object.keys(tickets).length;

      for (let i = arrayLength; i < 10; i++) {
        tickets['ticket_' + i] = { id: null };
      }

      return tickets;

    },

    async callFilters(columns) {

      //Si la columna status existe y la key 'status' no esta entre los filtros activos
      //                            O
      //Si la columna status existe, la key 'status' esta entre los filtros activos y el
      //status del primer ticket es distinto al valor de la columna status

      if (columns.status && !('status' in this.activeFilters)
        ||
        (columns.status && ('status' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.status && 'status' in this.activeFilters
          &&
          this.oldStatusFiltered !== columns.status.value)) {

        this.oldStatusFiltered = columns.status.value;
        this.activeFilters['status'] = statusNameToId(this.oldStatusFiltered, this.possibleStatus);

      }

      //Si la columna type existe y la key 'type' no esta entre los filtros activos
      //                            O
      //Si la columna type existe, la key 'type' esta entre los filtros activos y el
      //tipo del primer ticket es distinto al valor de la columna type

      if (columns.type && !('type' in this.activeFilters)
        ||
        (columns.type && ('type' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.type && 'type' in this.activeFilters
          &&
          this.oldTypeFiltered !== columns.type.value)) {

        this.oldTypeFiltered = columns.type.value;
        this.activeFilters['type'] = typeNameToId(this.oldTypeFiltered, this.possibleTypes);
      }

      //Si la columna status created_at y la key 'created_at' no esta entre los filtros activos
      //                            O
      //Si la columna created_at existe, la key 'created_at' esta entre los filtros activos y el
      //created_at del primer ticket es distinto al valor de la columna created_at

      if (columns.created_at && !('created_at' in this.activeFilters)
        ||
        (columns.created_at && ('created_at' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.created_at && 'created_at' in this.activeFilters
          &&
          this.oldDateCreatedAtFiltered !== [...Object.values(columns.created_at)])) {

        if (columns.created_at.type === 'between'
          && (this.oldDateFromCreated !== columns.created_at.value[0] || this.oldDateToCreated !== columns.created_at.value[1])) {

          this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
          this.oldDateFromCreated = columns.created_at.value[0];
          this.oldDateToCreated = columns.created_at.value[1];
          this.oldIntervalCreated = columns.created_at.type;

          this.activeFilters['created_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateFromCreated),
            to: dateStringDDMMYYYYToTimestamp(this.oldDateToCreated),
            interval: this.oldIntervalCreated,
          };


        } else if (columns.created_at.type === 'LT'
          && (this.oldIntervalCreated !== columns.created_at.type
            || this.oldDateBeforeCreated !== columns.created_at.value)) {

          this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
          this.oldDateBeforeCreated = columns.created_at.value;
          this.oldIntervalCreated = columns.created_at.type;

          this.activeFilters['created_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeCreated),
            interval: 'before',
          };


        } else if (columns.created_at.type === 'GT'
          && (this.oldIntervalCreated !== columns.created_at.type
            || this.oldDateAfterCreated !== columns.created_at.value)) {

          this.oldDateCreatedAtFiltered = [...Object.values(columns.created_at)];
          this.oldDateAfterCreated = columns.created_at.value;
          this.oldIntervalCreated = columns.created_at.type;

          this.activeFilters['created_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterCreated),
            interval: 'after',
          };

        }
      }

      //Si la columna status existe y la key 'status' no esta entre los filtros activos
      //                            O
      //Si la columna status existe, la key 'status' esta entre los filtros activos y el
      //status del primer ticket es distinto al valor de la columna status

      if (columns.validated_at && !('validated_at' in this.activeFilters)
        ||
        (columns.validated_at && ('validated_at' in this.activeFilters) && this.rows.length === 0)
        ||
        (columns.validated_at && 'validated_at' in this.activeFilters
          &&
          this.oldDateValidatedAtFiltered !== [...Object.values(columns.validated_at)])) {

        if (columns.validated_at.type === 'between'
          && (this.oldDateFromValidated !== columns.validated_at.value[0] || this.oldDateToValidated !== columns.validated_at.value[1])) {

          this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];

          if (dateStringDDMMYYYYToTimestamp(columns.validated_at.value[0]) < dateStringDDMMYYYYToTimestamp(columns.validated_at.value[1])) {
            
            this.oldDateFromValidated = columns.validated_at.value[0];
            this.oldDateToValidated = columns.validated_at.value[1];
            this.oldIntervalValidated = columns.validated_at.type;

            this.activeFilters['validated_at'] = {
              from: dateStringDDMMYYYYToTimestamp(this.oldDateFromValidated),
              to: dateStringDDMMYYYYToTimestamp(this.oldDateToValidated),
              interval: this.oldIntervalValidated,
            };
          }

        } else if (columns.validated_at.type === 'LT'
          && (this.oldIntervalValidated !== columns.validated_at.type
            || this.oldDateBeforeValidated !== columns.validated_at.value)) {

          this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];
          this.oldDateBeforeValidated = columns.validated_at.value;
          this.oldIntervalValidated = columns.validated_at.type;

          this.activeFilters['validated_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateBeforeValidated),
            interval: 'before',
          };


        } else if (columns.validated_at.type === 'GT'
          && (this.oldIntervalValidated !== columns.validated_at.type
            || this.oldDateAfterValidated !== columns.validated_at.value)) {

          this.oldDateValidatedAtFiltered = [...Object.values(columns.validated_at)];
          this.oldDateAfterValidated = columns.validated_at.value;
          this.oldIntervalValidated = columns.validated_at.type;

          this.activeFilters['validated_at'] = {
            from: dateStringDDMMYYYYToTimestamp(this.oldDateAfterValidated),
            interval: 'after',
          };

        }

      }

      this.filteredTickets = await this.getFilteredTickets();

      return this.filteredTickets;
    },

    async getFilteredTickets() {

      let ticketFiltered = [];

      let url = "/filter-user/query?";

      url = constructURLDates(url, this.activeFilters);

      url = url.replace('?&', '?');

      let filteredUserTickets = [];

      const options = {
        method: 'GET',
        url: url,
      }

      const response = await axios(options);

      this.rows = [];

      if (!response.errorMessage) {

        filteredUserTickets = Object.values(response.data['tickets']);
        filteredUserTickets = filteredUserTickets.sort((a, b) => parseInt(b.id) - parseInt(a.id));

        ticketFiltered = getUserTicketsRows(filteredUserTickets, this.possibleStatus);

      } else {

        this.error = true;
        this.errorMessage = 'Ha ocurrido un error en el filtrado';
      }

      this.loaded = true;

      let arrayLength = Object.keys(ticketFiltered).length;

      for (let i = arrayLength; i < 10; i++) {
        ticketFiltered['ticket_' + i] = { id: null };
      }

      return ticketFiltered;

    },

    pushColumns() {

      this.columns = [];
      const titleSize = '40%';
      const typeSize = '15%';
      const createdSize = '7%';
      const statusSize = '10%';
      const validatedSize = '7%';
      const amountSize = '5%';
      const buttonSize = '10%';

      this.columns.push(
        {
          label: 'Título',
          field: 'title',
          width: titleSize,
          filterable: false,
          filterOptions: {},
        },
        {
          label: 'Tipo',
          field: 'type',
          width: typeSize,
          filterable: true,
          filterOptions: {
            enabled: true,
            multiple: false,
            searchable: true,
            customFilterSelect: true,
            filterDropdownItems: this.ticketTypesNames
          },
          select: '',
          input: null,
        },
        {
          label: 'Enviado',
          field: 'created_at',
          type: 'date',
          width: createdSize,
          dateInputFormat: 't',
          dateOutputFormat: 'dd/MM/yyyy',
          filterable: false,
          filterOptions: {
            enabled: true,
            customFilterDate: true,
            iconDate: true
          },
        },

        {
          label: 'Estado',
          field: 'status',
          tdClass: 'center-column',
          width: statusSize,
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true,
            multiple: false,
            customFilterSelect: true,
            filterDropdownItems: this.ticketStatusNames
          },
          select: '',
          input: null,

        },
        {
          label: 'Validado',
          field: 'validated_at',
          type: 'date',
          dateInputFormat: 't',
          dateOutputFormat: 'dd/MM/yyyy',
          width: validatedSize,
          filterable: false,
          filterOptions: {
            enabled: true,
            customFilterDate: true,
            iconDate: true
          },
        },
        {
          label: '',
          labelHTML: '<img class="nodricoin-svg" src=""/>',
          tdClass: 'center-column',
          field: 'amount',
          type: 'number',
          width: amountSize,
          filterable: false,
          filterOptions: {},
        },
        {
          label: '',
          field: 'button',
          type: 'button',
          sortable: false,
          width: buttonSize,
          tdClass: 'center-column',
          filterable: false,
          filterOptions: {},
        },
        {
          label: '',
          width: '1%',
          field: 'actions',
          filterable: false,
          clearFilter: true,
          filterOptions: {},
          sortable: false,
          type: 'button',
          input: null
        }
      );

    },

    goToTicket(id) {
      this.$router.push(`/user/ticket?id=${id}`);
    },

    goToTicketRow(params) {
      this.goToTicket(params.row.id);
    }
  }
}

</script>

<style lang="scss" scoped>
  
    @import '@/styles/TableStyles/TableNTStyles.scss';
    @import '@/styles/CommonStyles/BasicElementsStyle.scss';

    a {
      color: #ed3f7c;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: bold;
      margin: auto 10px auto auto;
      padding: 5px;
    }

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    a:active {
      text-decoration: underline;
    }

    h1 {
      color: #444444;
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      padding-top: 30px;
      margin: 0 0 20px;
    }

    p {
      font-size: 18px;
      margin: 0;
      padding: 0;
    }

    .body {
      min-height: 90%;
    }

    .center {
      margin-left: 2%;
    }

    .error {
      margin-top: 20px;
      margin-left: 25%;
      margin-right: 25%;
    }

    .rocket {
      transform: scale(0.75);
      margin-top: 5%;
    }

    .motivater {
      height: 500px;
      margin: auto;
    }

    .table {
      margin: 30px 5% 5%;
      user-select: none
    }


</style>