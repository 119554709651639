import axios from "@/services/storage/axios";

// export const storeAutologin = async() => {
//     await autologinStore();
// }

// export const storeAutologinOrder = async(order_id) => {
//     await autologinStore(order_id);
// }

export async function autologinStore(order_id) {

    const options = {
        method: "GET",
        url: "/store/autologin"
    }

    const response = await axios(options);

    if (!response.errorMessage) {

        const jwt = response.data.jwt;
        redirectAutologin(order_id, jwt);

    } else {
        // If user cannot be autologged, redirect to store login page
        window.open(`${process.env.VUE_APP_STORE}`);
    }
}

async function redirectAutologin(order_id, jwt) {

    if (order_id === undefined) {
        return window.open(`${process.env.VUE_APP_STORE}?rest_route=/tribuk-store-login/v1/autologin&JWT=${jwt}`);
    }

    let order_url = new URL(`https://pre.store.tribuk.app/mi-cuenta/view-order/${order_id}/`);
    let final_url = new URL(`${process.env.VUE_APP_STORE}?rest_route=/tribuk-store-login/v1/autologin&JWT=${jwt}`);

    final_url.searchParams.set('redirectUrl', order_url);

    window.open(final_url.toString());
}