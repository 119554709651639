<template>

    <body>
        
        <div class="content">
            <div class="loginInfo">

                <img id="logoApp" src="../../img/logo-nt.png" alt="" @click="redirectLogin">

                <div class="loaderLogin" v-if="loaded">
                    <LoaderComponent />
                </div>

                <p v-if="!this.errors.loginError && !this.loaded">Acceso restringido a quienes <strong>vienen de propio</strong> a transformar el mundo.</p>
                
                <div class="errorLogin" v-if="this.errors.loginError && !this.loaded">
                    <p class="errorMsgLogin">{{this.errors.loginError}}</p>
                </div>

                <p id="access" v-if="!this.errors.loginError">Inicia sesión con tu cuenta profesional de NODRIZA tech</p>
                <p id="access" v-if="this.errors.loginError">También puedes volver a intentarlo...</p>

                <div id="buttonDiv"/>
            </div>
            
        </div>

    </body>
  
</template>

<script>

    import LoaderComponent from '../../components/LoadersComponents/LoaderComponent.vue'
    import axios from 'axios'
    import LocalStorageService from '../../services/storage/LocalStorageService';

    export default {

        components: {
            LoaderComponent
        },

        mounted() {
            if (google) {
                this.loadGoogleButton();
            } else {
                window.onload = this.loadGoogleButton;
            }
        },

        data() {

            return {
                jwt: "",
                googleID: "899849143873-s5dvg4ivnr5l0gsj0aua6vnubn9am4r8.apps.googleusercontent.com",
                email: '',
                loaded: false,
                localStorage: LocalStorageService.getService(),
                errors: {
                    loginError: ''
                }
            }
        },
        methods: {

            loadGoogleButton() {
                                
                google.accounts.id.initialize({
                    client_id:
                    "899849143873-s5dvg4ivnr5l0gsj0aua6vnubn9am4r8.apps.googleusercontent.com",
                    callback: this.handleCredentialResponse,
                });

                google.accounts.id.renderButton(
                    document.getElementById("buttonDiv"),
                    { theme: "white", size: "medium" }// customization attributes
                );
            },

            async handleCredentialResponse(response) {

                this.jwt = this.parseJWT(response.credential);
                await this.callRegisterEndpoint();
            },

            parseJWT(token) {

                let base64Url = token.split('.')[1];
                let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

                let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                }).join(''));

                return JSON.parse(jsonPayload)
            },

            async callEndpointLogin() {

                this.email = this.jwt.email;

                const options = {
                    method: 'post',
                    url: `${process.env.VUE_APP_BACK_HOST}/api/login_check`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        email: this.jwt.email,
                        password: this.jwt.sub
                    }
                }

                this.loaded = true

                await axios(options)
                .then((response) => {

                    if (response.data.token) {

                        let tokenObj = {
                            'access_token': response.data.token,
                            'refresh_token': ''
                        };

                        this.localStorage.setToken(tokenObj);

                        if (this.localStorage.getAccessToken() != null)
                            this.callEndpointUserData();
                    }

                }, (error) => {

                    if (error.response.status === 401) {

                        this.errors.loginError = 'Parece que todavía no estás dado de alta en NODRIZA app. \n Ponte en contacto con tu líder para que pueda ayudarte.';
                    }

                    this.loaded = false;
                })
            },
            async callRegisterEndpoint() {

                const options = {
                    method: 'post',
                    url: `${process.env.VUE_APP_BACK_HOST}/api/register`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        email: this.jwt.email,
                        password: this.jwt.sub
                    }
                }

                this.loaded = true

                await axios(options)
                .then((response) => {

                    this.callEndpointLogin();

                }, (error) => {

                    if (error.response.status != 200) {

                        this.errors.loginError = 'Parece que todavía no estás dado de alta en NODRIZA app. \n Ponte en contacto con tu líder para que pueda ayudarte.';
                    }

                    this.loaded = false;
                })
            },
            async callEndpointUserData() {

                const options = {
                    method: 'get',
                    url: `${process.env.VUE_APP_BACK_HOST}/api/user-data`,
                    headers: {
                        'Authorization': 'Bearer ' + this.localStorage.getAccessToken(),
                        'Content-Type': 'application/json'
                    }
                }

                await axios(options)
                .then((response) => {

                    if (response.data) {

                        this.email = response.data.email;
                        this.localStorage.setUserData(response.data);
                        this.loaded = false;

                        this.$router.push(`/`);
                    }

                }, (error) => {

                    this.errors.loginError = 'Ha ocurrido un error inesperado, intentalo más tarde';
                })
            }
        }
    }

</script>

<style scoped>

    img#logoApp {
        margin: 10px auto auto;
        max-width: 380px;
        height: auto;
    }

    #logoApp:hover {
        cursor: pointer;
    }

    p {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        margin-top: 10px;
    }

    p#access {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        margin-top: 60px;
    }

    #buttonDiv {
        box-sizing: border-box;
        width: 220px;
        height: 45px;
        margin: 20px auto auto;
        user-select: none;
    }

    .errorLogin {
        background-color: #ff6c61;
    }

    .errorMsgLogin {
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-weight: bolder;
        margin-left: 30px;
        margin-right: 30px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    .google-icon-wrapper {
        position: absolute;
        margin-top: 2px;
        margin-left: 2px;
        width: 60px;
        height: 55px;
        border-radius: 5px;
        background-color: #fff;
    }

    .google-icon {
        margin-top: 12px;
        width: 30px;
        height: 30px;
    }

    .btn-text {
        float: right;
        margin: 17px 15px 0 0;
        color: #fff;
        font-size: 23px;
        letter-spacing: 0.2px;
        font-family: "Roboto",serif;
    }

    .content {
        width: 100%;
        margin: auto;
    }

    .loginInfo {
        text-align: center;
        justify-content: center;
        width: 90%;
        max-width: 500px;
        margin: auto; 
    }

    .loaderLogin{
        width: 100px;
        height: 100px;
        position: relative;
        display: block;
        margin: 0 auto;
        transform: scale(0.8);
    }

    @media (max-width: 425px) {

        img#logoApp {
            margin-top: 20px;
            max-width: 280px;
        }
        
        p {
            font-size: 26px;
        }

        p#access {
            font-size: 20px;
        }

        .errorLogin {
            height: 200px;
        }

        .errorMsgLogin {
            font-size: 18px;
        }
    }


</style>