<template>
  
    <div class="feedbackContainer">
        <h3 class="detail">Añadir feedback al ticket</h3>
        <DescriptionComponent class="feedbackComponent" @text-change="setFeedback" v-bind:placeholder="this.placeholder" :loaded_saved="loaded_saved"/>
    </div>
    
    <div class="buttonsContainer">
        <a href="#popup1" class="rejectedTicket" @click="onRejectClick">
            <span>Rechazar</span>
        </a>
        <a href="#popup1" class="acceptedTicket" @click="onAcceptClick">
            <span>Aceptar</span>
        </a>
    </div>

    <div id="popup1" class="overlay" @click="onCancelValidate">
        <div class="popup" @click="onPopupClick">
            <div class="content">
                <h2>¿Quieres marcar como <span style="color:#ED3F7C">{{ this.decision }}</span> este ticket?</h2>
                <div class="decideValidate">
                    <button class="cancelValidate" @click="onCancelValidate" tabindex="-1">
                        <span>Cancelar</span>
                    </button>
                    <button class="confirmValidate" @click="onConfirmValidate" tabindex="-1">
                        <span>Sí</span>
                    </button> 
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>

    import DescriptionComponent from '../SendTicketComponents/DescriptionComponent.vue';
    import axios from '../../services/storage/axios';
    import LocalStorageService from '../../services/storage/LocalStorageService';

    export default {
        props: {
            ticketId: null,
            author: null
        },
        components: {
            DescriptionComponent
        },
        emits: ['loading', 'errors'],
        data() {
            return {
                decision: null,
                validated: null,
                feedback: null,
                closeError: false,
                placeholder: 'Escribe aquí el feedback que quieras trasladar a este NODRIZER sobre su ticket...',
                popupClicked: false,
                errors: {
                    back: null
                },
                loaded_saved: false
            }
        },
        methods: {
            emitLoading(value) {

                this.$emit('loading', value)
            },
            emitError(value, closeOn) {

                document.getElementById("popup1").hidden = true

                this.$emit('errors', value, closeOn)
            },
            onRejectClick() {

                this.validated = false
                this.decision = 'rechazado'

                if (document.getElementById("popup1").hidden) {

                    document.getElementById("popup1").style.transition =  "opacity 500ms"
                    document.getElementById("popup1").style.opacity =  1
                    document.getElementById("popup1").hidden = false
                }
            },
            onAcceptClick() {

                this.validated = true
                this.decision = 'aceptado'

                if (document.getElementById("popup1").hidden) {

                    document.getElementById("popup1").style.transition =  "opacity 500ms"
                    document.getElementById("popup1").style.opacity =  1
                    document.getElementById("popup1").hidden = false
                }
            },
            onCancelValidate() {

                if (this.popupClicked) {

                    this.popupClicked = false

                } else {

                    document.getElementById("popup1").hidden = true
                }
            },
            onPopupClick() {

                this.popupClicked = true
            },
            onConfirmValidate() {

                this.emitLoading(false)
                this.validateTicket()
            },
            setFeedback(value) {
                this.feedback = value
            },
            async validateTicket() {

                const options = {
                    method: "PUT",
                    url: "/validate",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: {
                        id: this.ticketId,
                        validate: this.validated,
                        feedback: this.feedback
                    },
                }

                await axios(options).then(

                    (response) => {

                        if (response.status) {

                            switch (response.status) {

                                case 200:

                                    this.emitLoading(true);
                                    //this.notification = response.data.message

                                    const localStorageService = LocalStorageService.getService();
                                    const notification_text = `¡Gracias por validar el ticket de ${this.author}!`;

                                    const notification_info = {
                                        'validated': true,
                                        'notification_text': notification_text
                                    }

                                    localStorageService.setValidated(true);

                                    localStorageService.setNotificationInfo(notification_info);

                                    this.$router.push(`/leader/tickets?view=validate`);

                                    break;

                                case 401:

                                    this.$router.push("/login")
                                    
                                    break

                                case 403: 

                                    this.emitLoading(true)
                                    this.closeError = false
                                    this.errors.back = "No tienes acceso a este ticket."
                                    this.emitError(this.errors.back, this.closeError)
                                    

                                    break;

                                case 404:

                                    this.emitLoading(true)
                                    this.closeError = true
                                    this.errors.back = "Ha ocurrido un error al validar el ticket. Inténtalo más tarde."
                                  this.emitError(this.errors.back, this.closeError)
                                    
                                    break

                                default:

                                    this.emitLoading(true)
                                    this.closeError = true
                                    this.errors.back = "Ha ocurrido un error al validar el ticket. Inténtalo más tarde."
                                    this.emitError(this.errors.back, this.closeError)
                                    
                                    break
                            }

                        } else if (response.response.status) {

                            switch (response.response.status) {

                                case 401:

                                    this.$router.push("/login")

                                    break

                                case 403: 

                                    this.emitLoading(true)
                                    this.closeError = false
                                    this.errors.back = "No tienes acceso a este ticket."
                                    this.emitError(this.errors.back, this.closeError)

                                    break;

                                case 404:
                                    this.emitLoading(true)
                                    this.closeError = true
                                    this.errors.back = "Ha ocurrido un error al validar el ticket. Inténtalo más tarde."
                                    this.emitError(this.errors.back, this.closeError)
                                        
                                    break

                                default:

                                    this.emitLoading(true)
                                    this.closeError = true
                                    this.errors.back = "Ha ocurrido un error al validar el ticket. Inténtalo más tarde."
                                    this.emitError(this.errors.back, this.closeError)
                                        
                                    break
                            }
                        }

                    },
                    (error) => {

                        console.log(error)
                    }
                )

            }
        },
        mounted() {
            setTimeout(() => {
                this.loaded_saved = true;
            });
        }
    }

</script>

<style scoped>

    a.rejectedTicket, a.acceptedTicket {
        color: white;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: 0.05em;
        margin-bottom: 30px;
        margin-left: 15px;
        margin-top: 30px;
        margin-right: 30px;
        padding: 0.8em 1.6em 0.8em 1.6em;
        text-decoration: none;
        transition: 0.3s;
        user-select: none;
    }

    a:hover {
        cursor: pointer;
    }

    a.rejectedTicket {
        background: #ff6c61;
    }

    a.rejectedTicket:hover {
        background: #d56a62;
    }

    a.acceptedTicket {
        background: #85b47b;
    }

    a.acceptedTicket:hover {
        background: #6f9367;
    }

    button.cancelValidate, button.confirmValidate {
        color: white;
        border: none;
        border-radius: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: bolder;
        font-size: 18px;
        letter-spacing: 0.05em;
        margin-bottom: 20px;
        margin-left: 15px;
        margin-top: 30px;
        margin-right: 30px;
        padding: 0.8em 1.6em 0.8em 1.6em;
        text-decoration: none;
        transition: 0.3s;
        user-select: none;
        width: 145px;
    }

    button:hover {
        cursor: pointer;
    }

    button.cancelValidate {
        background: #9e9e9e;
    }

    button.cancelValidate:hover {
        background: #7f7f7f;
    }

    button.confirmValidate {
        background: #ed3f7c;
    }

    button.confirmValidate:hover {
        background: #d6497b;
    }

    /*classes*/

    .buttonsContainer {
        margin-bottom: 30px;
        margin-left: 40px;
    }

    .decideValidate {
        display: flex;
        margin-left: 20%;
        margin-top: 0;
    }

    .feedbackContainer {
        margin-bottom: 60px;
        margin-right: 25px;
    }

    /* POPUP */

    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        transition: opacity 500ms;
        visibility: hidden;
        opacity: 0;
        z-index: 999999;
    }
    .overlay:target {
        visibility: visible;
        opacity: 1;
    }

    .popup {
        margin: 250px auto;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        width: 30%;
        position: relative;
    }

    .popup h2 {
        margin-left: 10px;
        margin-right: 10px;
        margin-right: 10px;
        margin-top: 10px;
        color: #333;
        font-family: "Montserrat", sans-serif;
        font-size: 28px;
        text-align: center;
    }

    .popup .content {
        max-height: 60%;
        overflow: auto;
    }

    /*media queries*/

    @media (min-width: 310px) {
        .popup{
            margin-top: 100px;
            width: 70%;
        }

        button.cancelValidate, button.confirmValidate {
            font-size: 12px;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-top: 30px;
        }

        .decideValidate {
            margin-left: 5px;
        }
    }

    @media (min-width: 420px) {
        .popup{
            margin-top: 100px;
            width: 70%;
        }

        button.cancelValidate, button.confirmValidate {
            font-size: 16px;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-top: 30px;
        }

        .decideValidate {
            margin-left: 5px;
            align-items: center;
            justify-content: center;
        }
    }

    @media (min-width: 420px) {
        .popup{
            margin-top: 100px;
            width: 70%;
        }

        button.cancelValidate, button.confirmValidate {
            font-size: 16px;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-top: 30px;
        }

        .decideValidate {
            margin-left: 5px;
            align-items: center;
            justify-content: center;
        }
    }

    @media (min-width: 660px) {
        .popup{
            margin-top: 140px;
            width: 40%;
        }

        button.cancelValidate, button.confirmValidate {
            font-size: 18px;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-top: 30px;
        }
    }

    @media (min-width: 1025px) {
        .popup{
            margin-top: 140px;
            width: 30%;
        }
    }


    @media (max-width: 520px) {
    
        a.acceptedTicket, a.rejectedTicket {
            font-size: 17px;
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 15px;
            padding-left: 1.6em;
            padding-right: 1.6em;
        }

        .buttonsContainer {
            margin-left: 5px;
        }
    }

    @media (max-width: 410px) {
    
        a.acceptedTicket, a.rejectedTicket {
            font-size: 17px;
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 15px;
            padding-left: 1.0em;
            padding-right: 1.0em;
        }

        .buttonsContainer {
            margin-left: 5px;
        }
    }

    @media (max-width: 326px) {
    
        a.acceptedTicket, a.rejectedTicket {
            font-size: 17px;
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 20px;
            padding-left: 0.6em;
            padding-right: 0.6em;
        }

        .buttonsContainer {
            margin-left: 0;
        }
    
    }

</style>