<template>
    <div class="filter-input"
         @click="showFilter($event)">
        <input type="text"
               class="vgt-input"
               disabled="disabled"
               :value="getFilterLabel()"/>
    </div>
    <svg class="filter-img"
         :class="{active: isActive()}"
         viewBox="0 0 64 64"
         @click="clearFilter">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M43,48 L43,56 L21,56 L21,48 L43,48 Z M53,28 L53,36 L12,36 L12,28 L53,28 Z M64,8 L64,16 L0,16 L0,8 L64,8 Z"
                fill="currentColor"></path>
        </g>
    </svg>
</template>

<script>
import {useI18n} from "vue-i18n/index";

export default {
    name: 'FilterText',
    props: ['columnName', 'filterSelect', 'filterInput'],
    emits: ['show-filter', 'clear-filter'],
    setup(props, {emit}) {
        const i18n = useI18n();
        const selectFilterTranslator = {
            'eq': i18n.t('nodrizaTable.eq'),
            'notEq': i18n.t('nodrizaTable.notEq'),
            'contains': i18n.t('nodrizaTable.contains'),
            'notContains': i18n.t('nodrizaTable.notContains'),
            'empty': i18n.t('nodrizaTable.empty'),
            'begins': i18n.t('nodrizaTable.begins'),
            'ends': i18n.t('nodrizaTable.ends')
        };
        const getFilterLabel = () => {
            const select = props.filterSelect;
            const input = props.filterInput;
            if (!select && !input) {
                return i18n.t('nodrizaTable.filter');
            }
            const selectLabel = select && select.length ? selectFilterTranslator[(select)] : '';
            const inputLabel = input && input.length ? `: ${input}` : '';
            return `${selectLabel}${inputLabel}`;
        };
        const isActive = () => {
            return props.filterSelect && props.filterSelect.length;
        };
        const showFilter = ($event) => emit('show-filter', {event: $event, columnName: props.columnName});
        const clearFilter = () => {
            if (!isActive()) {
                return;
            }
            emit('clear-filter', props.columnName);
        };
        return {
            getFilterLabel,
            showFilter,
            clearFilter,
            isActive
        };
    }
}
</script>
