<template>
    <div class="dialog-filter dialog-filter-number"
         :style="`left:${currentX-5}px;top:${currentY-5}px`">
        <div class="dialog-filter-item">{{ $t('nodrizaTable.filterByCondition') }}</div>
        <div class="dialog-filter-item">
            <select class="vgt-select dialog-filter-item"
                    v-model="filterDialogSelect"
                    @change="checkIfInputMustBeVisible">
                <option value="" selected>{{ $t('nodrizaTable.none') }}</option>
                <option value="eq" v-if="checkEnabledOption('eq')">{{ $t('nodrizaTable.eq') }}</option>
                <option value="aT" v-if="checkEnabledOption('aT')">{{ $t('nodrizaTable.aT') }}</option>
                <option value="bT" v-if="checkEnabledOption('bT')">{{ $t('nodrizaTable.bT') }}</option>
                <option value="empty" v-if="checkEnabledOption('empty')">{{ $t('nodrizaTable.empty') }}</option>
                <option value="between" v-if="checkEnabledOption('between')">{{ $t('nodrizaTable.between') }}</option>
            </select>
            <input type="date"
                   class="vgt-input dialog-filter-item"
                   v-model="filterDialogInput.from"
                   v-if="showInputFrom"
                   @keyup.enter="saveFilter"/>
            <input type="date"
                   class="vgt-input dialog-filter-item"
                   v-model="filterDialogInput.to"
                   v-if="showInputTo"
                   @keyup.enter="saveFilter"/>
        </div>
        <div class="dialog-filter-buttons dialog-filter-item">
            <button class="btn--admin btn--small"
                    @click="saveFilter">
                {{ $t('buttons.save') }}
            </button>
            <button class="btn--default btn--small"
                    @click="cancelFilter">
                {{ $t('buttons.cancel') }}
            </button>
        </div>
    </div>
</template>

<script>
import {ref, reactive, onMounted}                                        from "vue";
//import {convertYYYYMMDDToDDMMYYYY, convertDDMMYYYYToYYYYMMDD}            from "../../../../oniad/utils/dateConverter";
import {convertYYYYMMDDToDDMMYYYY, convertDDMMYYYYToYYYYMMDD} from "@/AppNT/utils/dateConverter";
import {isEnabledOption, howManyOptionsAreAvailable, getOptionAvailable} from "../utils/dialog";

export default {
    name: 'DialogFilterDate',
    props: ['dialogSelect', 'dialogInput', 'dialogOptions', 'currentX', 'currentY'],
    emits: ['save-filter', 'cancel-filter'],
    setup(props, {emit}) {
        let filterDialogSelect = ref('');
        let filterDialogInput = reactive({
            from: '',
            to: ''
        });
        let showInputFrom = ref(false);
        let showInputTo = ref(false);
        const checkIfInputMustBeVisible = () => {
            if (filterDialogSelect.value === 'between') {
                showInputFrom.value = true;
                showInputTo.value = true;
                return;
            }
            if (filterDialogSelect.value !== '' && filterDialogSelect.value !== 'empty') {
                filterDialogInput.to = '';
                showInputFrom.value = true;
                showInputTo.value = false;
                return;
            }
            filterDialogInput.from = '';
            filterDialogInput.to = '';
            showInputFrom.value = false;
            showInputTo.value = false;
        };
        const checkEnabledOption = (optionToEvaluate) => {
            return isEnabledOption(optionToEvaluate, props.dialogOptions);
        };
        const initByProps = () => {
            filterDialogSelect.value = props.dialogSelect;
            filterDialogInput.from = props.dialogInput ? convertDDMMYYYYToYYYYMMDD(props.dialogInput.from) : '';
            filterDialogInput.to = props.dialogInput ? convertDDMMYYYYToYYYYMMDD(props.dialogInput.to) : '';
            checkIfInputMustBeVisible();
        };
        const initByAvailableOptions = () => {
            const optionsAvailableCount = howManyOptionsAreAvailable(props.dialogOptions);
            if (optionsAvailableCount > 1) {
                return;
            }
            filterDialogSelect.value = getOptionAvailable(props.dialogOptions);
            checkIfInputMustBeVisible();
        };
        onMounted(() => {
            initByProps();
            if (filterDialogSelect.value && filterDialogSelect.value.length > 0) {
                return;
            }
            initByAvailableOptions();
        });
        const saveFilter = () => emit('save-filter', {
            select: filterDialogSelect.value,
            input: {
                from: convertYYYYMMDDToDDMMYYYY(filterDialogInput.from),
                to: convertYYYYMMDDToDDMMYYYY(filterDialogInput.to)
            }
        });
        const cancelFilter = () => emit('cancel-filter', {
            select: filterDialogSelect.value,
            input: {
                from: convertYYYYMMDDToDDMMYYYY(filterDialogInput.from),
                to: convertYYYYMMDDToDDMMYYYY(filterDialogInput.to)
            }
        });
        return {
            filterDialogSelect,
            filterDialogInput,
            showInputFrom,
            showInputTo,
            saveFilter,
            cancelFilter,
            checkIfInputMustBeVisible,
            checkEnabledOption
        }
    }
}
</script>
