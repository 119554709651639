<template>

<div class="body">
    <router-link class="returnLink" to="/">&lt; Volver</router-link>

    <h1 class="ticketValidated">¡Gracias por validar el ticket de {{this.author}}! &#127915;</h1> <!---->

    <div class="confirmText">

        <h2 class="ticketValidated" v-html="this.notificationHTML"></h2>

        <h3 class="ticketValidated">Puedes <router-link class="ticketValidated" to="/leader/tickets?view=validate">validar más tickets</router-link> si quieres.</h3>

    </div>
</div>

   

</template>

<script>
    export default {
        props: {
            notification: null,
            author: null
        },
        data() {
            return {
                notificationHTML: null
            }
        },
        created() {

            if (this.notification.includes('aceptado')) {

                this.notificationHTML = this.notification.replace('aceptado', '<span style="color:#ED3F7C; font-weight:800">aceptado</span>')
            
            } else if (this.notification.includes('rechazado')) {

                this.notificationHTML = this.notification.replace('rechazado', '<span style="color:#ED3F7C; font-weight:800">rechazado</span>')

            } else {

                this.notificationHTML = this.notification
            }
        }
    }
</script>

<style scoped>

    /* labels */

    a {
        color: #ED3F7C;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
    }

    a:link.returnLink {
        text-decoration: none;
    }

    a:visited.returnLink {
        text-decoration: none;
    }

    a:hover:not(.returnLink) {
        color: #ff669c;
    }

    a:hover.returnLink {
        text-decoration: underline;
    }

    a:active.returnLink {
        text-decoration: underline;
    }

    /* hs */

    h1.ticketValidated {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 35px;
        font-weight: bolder;
        margin-top: 100px;
        padding: 20px;
    }

    h2.ticketValidated {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: 300;
        padding: 20px;
    }

    h3.ticketValidated {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 500;
        padding: 50px;
    }


    /* ids */

    .confirmText {
        margin-left: 25%;
        margin-right: 25%;
    }

    .returnLink {
        margin-left: -50px;
    }

    .body {
      min-height: 90%;
    }

</style>