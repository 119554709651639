<template>
  <head>
    <!-- links for importing Montserrat font in 300 -->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap" rel="stylesheet">
    <!-- links for importing Montserrat font in 600 -->
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet">
    <!-- links for importing Font Awesome -->
    <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
    <!--Google sign in font-->
    <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
  </head>

  <div id="header" v-if="this.$route.path !== '/login' && this.$route.path !== '/login_store'">
    <HeaderComponent/>
  </div>

  <div class="version">
    <p>v {{this.version}}</p>
  </div>

  <router-view />

</template>

<script>

  import { version }        from '../package.json';
  import HeaderComponent    from "@/components/HeaderComponents/HeaderComponent";
  import { upBalance }  from "@/services/utils.js";

  export default {
    name: 'App',
    components: {
      HeaderComponent,
    },

    data() {
      return {
        loaded: false,
        version: version
      }
    },

    beforeUnmount() {
      this.loaded = true;
    },

    watch: {
      $route: function(to, from) {
        if (this.loaded)
          upBalance();
      }
    }
  }

</script>

<style>

  #app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: #444444;

  }

  #header{
    height: 75px;
    margin-bottom: 10px;
  }

  html{
    min-height: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }


  html::-webkit-scrollbar{
    background-clip: padding-box;
    background-color: #eeeeee;
    border: 4px solid transparent;
    border-radius: 8px;
    width: 17px;
  }

  html::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 16px;
    width: 10px;

  }

  body , #app{
    min-height: 99%;
    height: 99%;
  }

  .version { 
    color: #8a8989;
    font-family: 'Montserrat', sans-serif;
    position: fixed; 
    bottom: 0; 
    right: 1%; 
  }



</style>
