export const ticketTypesEnum = {
    'lean': 'Lean',
    'smart_decision': 'Smart Decision',
    'sc1': 'SC1',
    'sc2': 'SC2',
    '4e': '4E',
    'gracias': 'Gracias',
    'pulpo_imi': 'Pulpo IMI',
    'autoformacion': 'Autoformación',
    'sugerencia': 'Sugerencia',
    'ahorro': 'Ahorro de Costes',
    'aumento': 'Aumento de Facturación',
    'upselling': 'Upselling',
    'crosselling': 'Crosselling'
};