import {createI18n} from 'vue-i18n/index';
import es           from './locales/es.json';
// import en from './locales/en.json';
// import fr from './locales/fr.json';
let locale = 'es';
//TODO activar getLangFromNavigator cuando habilitemos mas idiomas
// let navigatorLang = getLangFromNavigator() || locale;
let navigatorLang = locale;
let localStorageLang = getLangFromStorage();
locale = ['es', 'en', 'fr'].includes(localStorageLang) ? localStorageLang : navigatorLang;
const numberFormats = {
    'en': {
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
        },
        number: {
            style: 'decimal', notation: 'compact', compactDisplay: 'short'
        },
        percent: {
            style: 'percent', signDisplay: 'exceptZero'
        }
    },
    'es': {
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
        },
        number: {
            style: 'decimal', notation: 'compact', compactDisplay: 'short'
        },
        percent: {
            style: 'percent', signDisplay: 'exceptZero'
        }
    },
    'fr': {
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
        },
        number: {
            style: 'decimal', notation: 'compact', compactDisplay: 'short'
        },
        percent: {
            style: 'percent', signDisplay: 'exceptZero'
        }
    }
};
const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        },
        yearAndMonth: {
            year: 'numeric', month: 'short'
        },
        onlyYear: {
            year: 'numeric'
        }
    },
    'es': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        },
        yearAndMonth: {
            year: 'numeric', month: 'short'
        },
        onlyYear: {
            year: 'numeric'
        }
    },
    'fr': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        },
        yearAndMonth: {
            year: 'numeric', month: 'short'
        },
        onlyYear: {
            year: 'numeric'
        }
    }
}
function getLangFromNavigator() {
    return (window.navigator.language.includes('es') || window.navigator.language.includes('es-')) ? 'es' : 'en';
}
function getLangFromStorage() {
    // let languageDef = window.localStorage.getItem('oniad_lang');
    // if (languageDef){
    //     languageDef = languageDef
    // }
    // if (languageDef && languageDef.value) {
    //     return languageDef.value;
    // }
    return null;
}
export const i18n = createI18n({
    numberFormats,
    dateTimeFormats,
    locale: locale,
    fallbackLocale: 'es',
    messages: {
        'es': es
        // 'en': en,
        // 'fr': fr
    }
});
