import { createApp } from 'vue'
import App from './App.vue'

import router from '../src/router/router'
import {i18n}        from './i18n/i18n';
import VueGtag from "vue-gtag";

createApp(App)
    .use(router)
    .use(i18n)
    .use(VueGtag, {
        pageTrackerTemplate(to) {
            return {
                page_title: to.name + ' ' + to.fullPath,
                page_path: to.path
            }
        },
        pageTrackerExcludedRoutes: [
            '/login',
            'LoginView'
        ],
        pageTrackerScreenviewEnabled: true,
        config: {
            id: `${process.env.VUE_APP_GTAG}`,
        },
    }, router)
    .mount('#app')
