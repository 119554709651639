<template>

  <div class="wrapper">
    <h3 class="is-required">¿A quién quieres agradecer?</h3>

    <div class="search-container">
      <div class="input-wrapper">
        <span class="icon"><i class="fa fa-search"></i></span>
        <input
            v-model="searchName"
            id="input-search"
            type="text"
            placeholder="Buscar por nombre..."
            autocomplete="off"
            @input="this.selectedEmployee = ''"
            @click="$event.target.select()"
        />
      </div>
      <div class="box">
        <div class="search">
          <ul id="search" v-if="searchName.length >= 2">
            <li v-for="emp in searchEmployees" :key="emp.firstName" @click="selectEmployee(emp)">{{emp['firstName']}} {{emp['lastName']}} ({{emp['email']}})</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {ref} from 'vue'
import axios from '@/services/storage/axios'

export default {
  
  data() {
    return {
      email: '',
      employees: '',
      selectedEmployee: ''
    }
  },

  setup() {

    let searchName = ref('')

    return {
      searchName
    }
  },

  emits: ['emailchanged'],

  created() {
    this.getNames();
  },

  methods: {

    async getNames() {

      const options = {
        method: 'GET',
        url: '/names',
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios(options)
        .then((response) => {
            this.employees = response.data.employees;
        }, (error) => {

          if (error.response.status === 401) {
              this.loaded = false;
          }
        })
    },

    selectEmployee(employee) {

      this.selectedEmployee = ref('');
      this.selectedEmployee = employee;

      document.getElementById('input-search').value = `${employee.firstName} ${employee.lastName} (${employee.email})`;
      this.email = employee.email;

      document.getElementById('search').hidden = true;

      let data = {
        email: this.email
      }

      this.$emit("emailchanged", data);
    }
  },

  computed: {
    searchEmployees() {

      if (this.searchName === '') {
        return this.employees;
      }

      let matches = 0;

      return this.employees.filter(employee => {
        const employee_found_normalized = employee.firstName.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase();
        const employee_searched_normalized = this.searchName.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase();

        if (employee_found_normalized.startsWith(employee_searched_normalized) 
            && matches < 10) {
          matches++;
          return employee
        }
      })
    }
  },

  watch: {
    searchName(newValue, oldValue) {
      let data = {
        email: newValue
      }
      this.$emit("emailchanged", data);
    }
  }
}
</script>

<style scoped>

h3 {
  color: #444444;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  margin-top: 15px;
}

input {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  content: '\f274';
  background: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-radius: 5px;
  border-width: thin;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 36px;
  margin-left: 1%;
  margin-top: 15px;
  outline: none;
  text-overflow: ellipsis;
  text-indent: 40px;
  width: 85%;
}

input:hover {
  border-color: #ed3f7c;
  border-width: thin;
}

li {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-left: 0px;
  text-indent: 20px;
}

li.selected {
  background-color: #ED3F7C;
  color: #ffffff;
  cursor: default;
  font-weight: bolder;
}

li:not(.selected):hover {
  background-color: #ffe6fa;
}

ul {
  background-color: #ffffff;
  border: 2px solid #444444;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-color: #ffffff;
  border-width: thin;
  height: auto;
  list-style-type: none;
  margin-left: -0.5%;
  margin-top: 0;
  overflow-y: auto;
  padding: 0;
  width: 92%;
}

ul:empty {
  display: none;
}

ul::-webkit-scrollbar {
  background-clip: padding-box;
  background-color: #e7e4e4;
  border: 4px solid transparent;
  border-radius: 8px;
  width: 16px;
}

ul::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #ED3F7C;
  border: 4px solid transparent;
  border-radius: 16px;
  width: 10px;
}

p.liName {
  margin-top: 0;
}

span {
  margin-left: 15px;
}

.icon{
  color: #ED3F7C;
  left: 36px;
  position: relative;
  transform: translateY(-55%);
  width: 20px;
  z-index: 1;
}

.is-required:after {
  color: #ff0000;
  content: '*';
  font-weight: bold;
  margin-left: 3px;
}

.search {
  margin-left: 40px;
  margin-bottom: 40px;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.search-container {
  background-color: #f4f2f2;
  border: #f4f2f2 0.2em solid;
  border-radius: 5px;
  height: 70px;
  margin-bottom: 15px;
  width: 100%;
}

.wrapper {
  display: grid;
  text-align: left;
  justify-content: center;
}

@media (max-width: 424px) {

  input {
    margin-left: -4%;
  }

  ul {
    margin-left: -20px;
    width: 100%;
  }

  .icon{
    position: relative;
    left: 22px;
  }

  .wrapper {
    grid-template-columns: 280px;
  }
}

@media (min-width: 425px) {

  input {
    margin-left: -2%;
  }

  ul {
    margin-left: -18px;
    width: 96.5%;
  }

  .icon{
    position: relative;
    left: 25px;
  }

  .wrapper {
    grid-template-columns: 370px;
  }
}

@media (min-width: 750px) {

  input {
    margin-left: 1%;
  }

  ul {
    margin-left: -5px;
    width: 93%;
  }

  .icon{
    position: relative;
    left: 35px;
  }

  .wrapper {
    grid-template-columns: 500px;
  }
}

.select-nodricoins{
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 35px 35px 35px 35px 35px;
  margin-left: 20px;
  text-align: left;
  justify-content: left;

}

.nc{
  width: 25px;
  cursor: pointer;
}

</style>