<template>

  <div class="body">

    <LoaderComponent v-if="this.loading" class="loader"/>

    <div class="lema" v-if="!this.loading">
      <h3 id="lema">
        Somos una empresa en la que <strong>todos remamos en la misma dirección</strong>, como si no hubiera un mañana. Con rasmia, con ilusión y con ganas de crecer como personas, como profesionales y como equipo. Con talento, con maña y con picardía, siempre con una sonrisa en la boca y respetando tanto a los demás como a lo que nos rodea. Sin pisar a nadie.
      </h3>
    </div>

    <div v-show="!this.loading" v-bind:class="{ 'container-top': this.roles.includes('ROLE_MANAGER') || this.roles.includes('ROLE_ADMIN'), 'container-top-user': !this.roles.includes('ROLE_MANAGER') && !this.roles.includes('ROLE_ADMIN') }">

      <div class="ce column">
        <CulturaEmpresaComponent/>
      </div>

      <div class="column">
        <div class="nz">
          <NodrizerComponent @loading="redirectLoading"/>
        </div>
      </div>

      <div class="ce column" v-if="this.roles.includes('ROLE_MANAGER') || this.roles.includes('ROLE_ADMIN')">
        <LiderComponent/>
      </div>

      <div class="column">
        <div id="nodritools">
          <NODRItoolsComponent/>
        </div>
      </div>

    </div>

  </div>

</template>

<script>

import LoaderComponent from '@/components/LoadersComponents/LoaderComponent';
import CulturaEmpresaComponent from "@/components/HomePageComponents/CulturaEmpresaComponent"
import NodrizerComponent from "@/components/HomePageComponents/NodrizerComponent"
import LiderComponent from "@/components/HomePageComponents/LiderComponent"
import NODRItoolsComponent from "@/components/HomePageComponents/NODRItoolsComponent"
import LocalStorageService from "@/services/storage/LocalStorageService"

export default {

  components: {
    CulturaEmpresaComponent,
    NodrizerComponent,
    LiderComponent,
    NODRItoolsComponent,
    LoaderComponent
  },

  data() {
    return {
      loading: false,
      roles: LocalStorageService.getService().getUserRoles()
    }
  },

  methods: {
    redirectLoading(isLoading) {
      this.loading = isLoading;
    }
  }
}

</script>

<style scoped>

a {
  color: #ED3F7C;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: bold;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

h3 {
  color: #444444;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin-right: 15%;
  margin-left: 15%
}

.lema {
  margin: 30px 15% 10px;
}

#lema {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  margin-right: 10%;
  margin-left: 10%;
  font-style: italic;
}


.container-top, .container-top-user {
  display: grid;
  grid-template-columns: auto;
  margin-left: 2%;
  margin-left: 2%;
  text-align: center;
  justify-content: center;
  padding-bottom: 20px;
}


.column {
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 10px;
}


.body {
  min-height: 86%;
}


@media (max-width:320px){
  .container-top{
    grid-template-columns: auto;
  }
  
  .container-top-user {
    grid-template-columns: auto;
  }

}

@media (max-height: 750px){
  .body {
    min-height: 70%;
  }
}



@media (min-width: 764px) {

  .container-top {
    grid-template-columns: repeat(2, 350px);

  }

  .container-top-user {
    grid-template-columns: repeat(2, 350px);
  }

}


@media (min-width: 1024px) {

  .container-top {
    grid-template-columns: repeat(2, 350px);
  }

}

@media (min-width: 1300px) {
  .container-top {
    grid-template-columns: repeat(4, 300px);
  }

  .container-top-user {
    grid-template-columns: repeat(3, 425px);
  }

}

@media (min-width: 1700px) {
  .container-top {
    grid-template-columns: repeat(4, 400px);
  }

  .container-top-user {
    grid-template-columns: repeat(3, 425px);
  }

}

</style>