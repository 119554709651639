<template>
    <div class="loaderLogin">
        <LoaderComponent />
    </div>
</template>

<script>

    import axios from '@/services/storage/axios';
    import LoaderComponent from '../../components/LoadersComponents/LoaderComponent.vue';
    import LocalStorageService from '../../services/storage/LocalStorageService';

    export default {
        components: {
            LoaderComponent
        },
        props: {
            data: null
        },
        data() {
            return {
                localStorage: LocalStorageService.getService()
            }
        },
        mounted() {
            this.authenticateUser();            
        },

        methods: {
            async authenticateUser() {

                const options = {
                    method: "POST",
                    url: "/login_store",
                    data: {
                        data: this.data
                    }
                }

                const response = await axios(options);

                if (!response.errorMessage) {

                    let tokenObj = {
                        'access_token': response.data.token,
                        'refresh_token': ''
                    };

                    this.localStorage.setToken(tokenObj);

                    if (this.localStorage.getAccessToken() != null)
                        this.callEndpointUserData();

                } else {

                    this.$router.push(`/login`);
                }
            },
            async callEndpointUserData() {

                const options = {
                    method: 'get',
                    url: `${process.env.VUE_APP_BACK_HOST}/api/user-data`,
                    headers: {
                        'Authorization': 'Bearer ' + this.localStorage.getAccessToken(),
                        'Content-Type': 'application/json'
                    }
                }

                await axios(options)
                .then((response) => {

                    if (response.data) {
                        this.localStorage.setUserData(response.data);
                        this.$router.push(`/`);
                    }

                }, (error) => {
                    this.$router.push(`/login`);
                })
            }
        }
    }
</script>

<style scoped>
</style>