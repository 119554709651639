<template>

  <div class="container">
  <h4>Para ti, NODRIZER</h4>

  <ul class="colum-list">
    <li class="column-line">
      <router-link to="/user/tickets">Consultar <strong>tickets enviados</strong></router-link>
    </li>
    <li class="column-line">
      <router-link to="/user/transactions">Consultar <strong>mis transacciones</strong></router-link>
    </li>
  </ul>
  </div>
</template>

<script>

  import { storeAutologin } from "@/AppNT/utils/storeUtils";

  export default {
    name: "NodrizerComponent",
    emits: ['loading']
  }
</script>

<style scoped>

  @import '@/styles/HomepageStyles/ColumnsHomepage.scss';

</style>