<template>
    <div class="dialog-filter dialog-filter-text"
         :style="`left:${currentX-5}px;top:${currentY-5}px`">
        <div class="dialog-filter-item">{{ $t('nodrizaTable.filterByCondition') }}</div>
        <div class="dialog-filter-item">
            <select class="vgt-select dialog-filter-item"
                    v-model="filterDialogSelect"
                    @change="checkIfInputMustBeVisible">
                <option value="" selected>{{ $t('nodrizaTable.none') }}</option>
                <option value="eq" v-if="checkEnabledOption('eq')">{{ $t('nodrizaTable.eq') }}</option>
                <option value="notEq" v-if="checkEnabledOption('notEq')">{{ $t('nodrizaTable.notEq') }}</option>
                <option value="contains" v-if="checkEnabledOption('contains')">{{ $t('nodrizaTable.contains') }}</option>
                <option value="notContains" v-if="checkEnabledOption('notContains')">{{ $t('nodrizaTable.notContains') }}</option>
                <option value="empty" v-if="checkEnabledOption('empty')">{{ $t('nodrizaTable.empty') }}</option>
                <option value="begins" v-if="checkEnabledOption('begins')">{{ $t('nodrizaTable.begins') }}</option>
                <option value="ends" v-if="checkEnabledOption('ends')">{{ $t('nodrizaTable.ends') }}</option>
            </select>
            <input type="text"
                   class="vgt-input dialog-filter-item"
                   v-model="filterDialogInput"
                   v-if="showInput"
                   @keyup.enter="saveFilter"/>
        </div>
        <div class="dialog-filter-buttons dialog-filter-item">
            <button class="btn--admin btn--small"
                    @click="saveFilter">
                {{ $t('buttons.save') }}
            </button>
            <button class="btn--default btn--small"
                    @click="cancelFilter">
                {{ $t('buttons.cancel') }}
            </button>
        </div>
    </div>
</template>

<script>
import {ref, onMounted}                                                  from "vue";
import {isEnabledOption, howManyOptionsAreAvailable, getOptionAvailable} from "../utils/dialog";

export default {
    name: 'DialogFilterText',
    props: ['dialogSelect', 'dialogInput', 'dialogOptions', 'currentX', 'currentY'],
    emits: ['save-filter', 'cancel-filter'],
    setup(props, {emit}) {
        let filterDialogSelect = ref('');
        let filterDialogInput = ref('');
        let showInput = ref(false);
        const checkIfInputMustBeVisible = () => {
            if (filterDialogSelect.value !== '' && filterDialogSelect.value !== 'empty') {
                showInput.value = true;
                return;
            }
            filterDialogInput.value = '';
            showInput.value = false;
        };
        const checkEnabledOption = (optionToEvaluate) => {
            return isEnabledOption(optionToEvaluate, props.dialogOptions);
        };
        const initByProps = () => {
            filterDialogSelect.value = props.dialogSelect;
            filterDialogInput.value = props.dialogInput;
            checkIfInputMustBeVisible();
        };
        const initByAvailableOptions = () => {
            const optionsAvailableCount = howManyOptionsAreAvailable(props.dialogOptions);
            if (optionsAvailableCount > 1) {
                return;
            }
            filterDialogSelect.value = getOptionAvailable(props.dialogOptions);
            checkIfInputMustBeVisible();
        };
        onMounted(() => {
            initByProps();
            if (filterDialogSelect.value && filterDialogSelect.value.length > 0) {
                return;
            }
            initByAvailableOptions();
        });
        const saveFilter = () => emit('save-filter', {select: filterDialogSelect.value, input: filterDialogInput.value});
        const cancelFilter = () => emit('cancel-filter', {select: filterDialogSelect.value, input: filterDialogInput.value});
        return {
            filterDialogSelect,
            filterDialogInput,
            showInput,
            saveFilter,
            cancelFilter,
            checkIfInputMustBeVisible,
            checkEnabledOption
        }
    }
}
</script>
