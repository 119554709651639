export const statusIdToName = (statusId, possibleStatus) => {

    let statusName = null;

    for (let i = 0; i < possibleStatus.length; i++) {

        if (possibleStatus[i].id === statusId) {

            statusName = possibleStatus[i].name;
        }
    }

    return statusName;

}

export const typeIdToName = (typeId, possibleTypes) => {

    let typeName = null;

    for (let i = 0; i < possibleTypes.length; i++) {

        if (possibleTypes[i].id === typeId) {

            typeName = possibleTypes[i].name;
        }
    }

    return typeName;

}

export const  authorIdToName = (authorId, possibleAuthors) => {

    let authorName = null;

    for (let i = 0; i < possibleAuthors.length; i++) {

        if (possibleAuthors[i].author_id === authorId) {

            authorName = possibleAuthors[i].author_name;
        }
    }

    return authorName;
}

export const  companyIdToName = (companyId, possibleCompanies) => {

    let compName = null;

    for (let i = 0; i < possibleCompanies.length; i++) {

        if (possibleCompanies[i].company_id === companyId) {

            compName = possibleCompanies[i].company_name;
        }
    }

    return compName;
}

export const  departmentIdToName = (departmentId, possibleDepartments) => {

    let depName = null;

    for (let i = 0; i < possibleDepartments.length; i++) {

        if (possibleDepartments[i].dept_id === departmentId) {

            depName = possibleDepartments[i].dept_name;
        }
    }

    return depName;
}

export const  managerIdToName = (managerId, possibleManagers) => {

    let managerName = null;

    possibleManagers.map((manager) => {

        if (manager.id === managerId) {

            managerName = manager.name;
        }
    })

    return managerName;
}
