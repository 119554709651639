<template>

  <div class="body-detailed">

    <div class="back">

      <router-link v-if="this.view === 'team'" class="returnLink" to="/leader/tickets?view=team">
        &lt; Volver
      </router-link>

      <router-link v-if="this.view === 'validate'" class="returnLink" to="/leader/tickets?view=validate">
        &lt; Volver
      </router-link>

    </div>

    <div class="loader" v-if="!this.loaded">
      <LoaderComponent/>
    </div>

    <div class="error" v-if="this.error">
      <ErrorDialogComponent v-bind:error="this.errorMessage"
                            v-bind:closeOn="true"
                            v-if="this.loaded && this.error"/>
    </div>

    <div class="container" v-if="this.loaded && !this.error" >

      <div id="background">

          <h1 class="detail">Ticket en detalle</h1>
          <div class="text">

            <div class="author">
              <h3 class="detail">Autor</h3>
              <p class="detail">{{ this.ticketDetail['author_first_name'] }} {{ this.ticketDetail['author_last_name'] }}</p>
            </div>

            <div class="title">
              <h3 class="detail">Título</h3>
              <p class="detail">{{ this.ticketDetail['title'] }}</p>
            </div>

            <div class="type">
              <h3 class="detail">Tipo</h3>
              <p class="detail" v-if="this.ticketDetail['type'] ==='SC1'">&#128170; SC1</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='SC2'">&#128169; SC2</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Pulpo IMI'">&#128025; Pulpo IMI</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Lean'">	&#9201;&#65039; Lean</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Smart Decision'">&#128161; Smart Decision</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='4E'">&#129304; 4E</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Autoformación'">&#128214; Autoformación</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Gracias'">&#129303; Gracias</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Ahorro de Costes'">&#128201; Ahorro de costes</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Aumento de Facturación'">&#128200; Aumento de Facturación</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Crosselling'">&#128279; Cross-selling</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Upselling'">&#129513; Up-selling</p>
              <p class="detail" v-if="this.ticketDetail['type'] ==='Sugerencia'">&#128270; Sugerencia </p>

            </div>

            <div class="description">
              <h3 class="detail">Descripción</h3>
              <div id="desc" v-html="this.description" />
            </div>

            <div class="created_at">
              <h3 class="detail">Fecha de envío</h3>
              <p class="detail">{{new Date(Date.parse(this.ticketDetail['created_at'])).toLocaleDateString()}}</p>
            </div>

            <div v-if="this.ticketDetail['type'] === 'Gracias'" class="thanks_to">
              <h3 class="detail">Enviado a</h3>
              <p class="detail">{{ this.ticketDetail['thanks_to']['first_name']}} {{this.ticketDetail['thanks_to']['last_name']}} ({{this.ticketDetail['thanks_to']['email']}})</p>
            </div>

            <div v-if="this.sugSubtypes.find(element => element === this.ticketDetail['type'])" class="company">
              <h3 class="detail">Sugerencia enviada a</h3>
              <p class="detail">{{ this.ticketDetail['sug_data']['department']}} ({{ this.ticketDetail['sug_data']['company_name']}})</p>
            </div>

            <div class="status">
              <h3 class="detail">Estado</h3>
              <p v-if="this.ticketDetail['status'] === 1" class="pendingDetail">Pendiente</p>
              <p v-if="this.ticketDetail['status'] === 2" class="validDetail">Válido</p>
              <p v-if="this.ticketDetail['status'] === 3" class="notValidDetail">No Válido</p>
            </div>


            <div v-if="this.ticketDetail['status'] !== 1 && this.ticketDetail['type'] !== 'Gracias'" class="validated_at" >
              <h3 class="detail">Fecha de validación</h3>
              <p class="detail" v-if="this.ticketDetail['validated_at']">{{new Date(Date.parse(this.ticketDetail['validated_at'])).toLocaleDateString()}}</p>
              <p class="detail" v-if="!this.ticketDetail['validated_at']"> - </p>
            </div>

            <div v-if="this.view === 'team' && this.ticketDetail['type'] !== 'Gracias' && !this.sugSubtypes.includes(this.ticketDetail['type'])" class="feedback" >
              <h3 class="detail">Feedback del validador </h3>
              <div id="feedP" v-if="this.ticketDetail['feedback']" v-html="this.feedback" />
              <p class= "feedP" v-if="!this.ticketDetail['feedback']"> - </p>
            </div>

            <div class="amount" v-if="this.view === 'team'">
              <h3 class="detail">NODRICOINS ganados</h3>
              <p class="detail" v-if="this.view === 'team' && this.ticketDetail['amount'] != null"> {{ this.ticketDetail['amount'] }} </p>
              <p class="detail" v-if="this.view === 'team' && (this.ticketDetail['amount'] == null)"> - </p>
            </div>

            <div class="amount" v-if="this.view === 'validate'">
              <h3 class="detail">Nodricoins del ticket</h3>
              <p class="detail" v-if="this.view === 'validate' && this.ticketDetail['type_amount']"> {{ this.ticketDetail['type_amount'] }} </p>
              <p class="detail" v-if="this.view === 'validate' && !this.ticketDetail['type_amount']"> - </p>
            </div>

            <div v-if="this.view === 'validate' && this.ticketDetail !== null && this.ticketDetail['status'] === 1" class="validate-btn">
              <div class="advert-feedback">
                <p>Por favor, <strong style="color: #ED3F7C">dedica un minuto a añadir un feedback para este ticket</strong>, ¡la persona que lo ha enviado ha dedicado tiempo a enviarlo!</p>
              </div>
              <ValidateButtonsFeedbackComponent
                                                v-bind:ticketId="this.ticketId"
                                                v-bind:author="this.ticketDetail['author_first_name']"
                                                @loading="validatingLoading"
                                                @errors="getMessageErrors"
              />
            </div>

          </div>
      </div>

    </div>

    <div class="paginate" v-if="this.loaded && !this.error">
      <PaginateComponent v-bind:totalPages="this.totalPages"
                         v-bind:currentPage="this.currentPage"
                         @page-changed="onPageChange"
      />
    </div>

  </div>


</template>

<script>
import PaginateComponent from "@/components/TicketDetailsComponents/PaginateComponent"
import LoaderComponent from '../../components/LoadersComponents/LoaderComponent'
import axios from "@/services/storage/axios"
import LocalStorageService from "../../services/storage/LocalStorageService"
import ErrorDialogComponent from "@/components/GeneralComponents/ErrorDialogComponent"
import ValidateButtonsFeedbackComponent from "@/components/ValidateTicketsComponents/ValidateButtonsFeedbackComponent.vue"

export default {
  name: "DetailTicketManagerView",

  components: {
    PaginateComponent,
    LoaderComponent,
    ErrorDialogComponent,
    ValidateButtonsFeedbackComponent
  },

  props: {
    ticketId: null,
    view: null
  },

  data(){
    return{
      leaderTicketsIds: null,
      ticketDetail: null,
      currentPage: null,
      totalPages: null,
      id: null,
      isValidating: false,
      loaded: false,
      error: false,
      errorMessage: null,
      closeError: false,
      localStorage: null,
      description: null,
      feedback: null,
      sugSubtypes: [
        'Sugerencia',
        'Ahorro de Costes',
        'Aumento de Facturacion',
        'Upselling',
        'Crosselling'
      ]
    }
  },

  created(){
    this.isValidating = this.view === 'validate';
  },

  beforeMount(){
    this.id = parseInt(this.ticketId);
    this.localStorage = LocalStorageService.getService();

    this.getTicketDetail();
  },

  watch: {
    '$route' (to, from){
      this.getTicketDetail();
    }
  },


  methods: {

    async getTicketDetail(){

      const options = {
        method: 'GET',
        url: `/team/tickets/${this.id}/query?mode=${this.view}`
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.ticketDetail = response['data']['ticket'];

        this.description = this.ticketDetail['description'];
        this.feedback = this.ticketDetail['feedback'];

        if (!this.leaderTicketsIds && !this.isValidating) {

          this.leaderTicketsIds = [];
          await this.getLeaderTicketsIds();

        }
        else if (!this.leaderTicketsIds && this.isValidating) {

          this.leaderTicketsIds = [];
          await this.getLeaderPendingTicketsIds();
        }

      } else {

        if (response.response.status === 403) {

          this.$router.push(`/leader/tickets?view=${this.view}`);

        } else {

          this.error = true;
          this.closeError = false;
          this.errorMessage = response.errorMessage;
        }
      }

      this.loaded = true;
    },

    async getLeaderTicketsIds() {

      this.leaderTicketsIds = [];

      const options = {
        method: 'GET',
        url: '/team/tickets'
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        let leaderTickets = Object.values(response.data['team_tickets']);
        leaderTickets = leaderTickets.sort((a, b) => parseInt(a.id) - parseInt(b.id));

        for (let i = 0; i < leaderTickets.length; i++) {

          this.leaderTicketsIds.push(leaderTickets[i]['id']);

          if (leaderTickets[i]['id'] === this.id) {
            this.currentPage = i;
          }
        }

        this.totalPages = this.leaderTicketsIds.length;

      } else {

          this.error = true;
          this.errorMessage = response.data['failed']['message'];
      }

      this.loaded = true;
    },

    async getLeaderPendingTicketsIds() {

      this.leaderTicketsIds = []

      const options = {
        method: 'GET',
        url: '/validate/tickets'
      }

      const response = await axios(options);

      if (!response.errorMessage) {

        this.rows = [];
        let leaderTickets = Object.values(response.data['tickets']);
        leaderTickets = leaderTickets.sort((a, b) => parseInt(a.id) - parseInt(b.id));

        for (let i = 0; i < leaderTickets.length; i++) {

          this.leaderTicketsIds.push(leaderTickets[i]['id']);

          if (leaderTickets[i]['id'] === this.id) {
            this.currentPage = i;
          }
        }

        this.totalPages = this.leaderTicketsIds.length;

      } else {

          this.error = true
          this.errorMessage = response.errorMessage;
      }

      this.loaded = true;
    },

    onPageChange(page) {

      this.currentPage = page;
      this.loaded = false;
      this.id = this.leaderTicketsIds[this.currentPage];
      this.$router.push(`/leader/ticket?id=${this.leaderTicketsIds[this.currentPage]}&view=${this.view}`);
    },

    validatingLoading(loading) {

      this.loaded = loading
    },

    getMessageErrors(messageError, closeOn) {

      this.error = true;
      this.closeError = closeOn;
      this.errorMessage = messageError;
    }

  }
}
</script>

<style lang="scss">

/*elements*/

a.returnLink {
  color: #ED3F7C;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
}
a.returnLink:link {
  text-decoration: none;
}
a.returnLink:visited {
  text-decoration: none;
}
a.returnLink:hover {
  text-decoration: underline;
}
a.returnLink:active {
  text-decoration: underline;
}

h1.detail {
  font-size: 24px;
  margin-bottom: 20px;
}

h3.detail {
  color: #444444;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  text-align: left;
  text-indent: 3px;
}

h3.detail:after {
  content: "";
  border-bottom: 1px solid #f5c9d9;
  display: block;
  padding-top: 5px;
  width: 93%;
}

p.detail {
  font-size: 16px;
  padding-right: 30px;
  text-indent: 3px;
  padding-bottom: 7px;
}

/*classes*/

.advert-feedback {
  background-color: #ffd8e6;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 20px;
  padding: 5px;
  padding-left: 20px;
}

.back {
  margin: auto;
  padding-bottom: 25px;
  width: 100%;
}

.body-detailed {
  min-height: 90%;
  padding-bottom: 100px;
}

.container {
  margin: auto;
  min-width: 90%;
}

.description {
  white-space: pre-wrap;
}

.error {
  margin: 20px 37% 2%;
}

.paginate {
  margin: auto;
  max-width: 500px;
  padding-top: 50px;
}

.text {
  padding-bottom: 20px;
}

.validated_at {
  padding-top: 7px;
}

/* status css */

.validDetail {
  padding: 2px 21px 3px;
  background: transparent;
  color: rgba(0, 143, 57, 0.65);
  border: 2px solid rgba(0, 143, 57, 0.65);
}

.notValidDetail {
  padding: 2px 9px 3px;
  background: transparent;
  color: rgba(244, 67, 54, 0.65);
  border: 2px solid rgba(244, 67, 54, 0.65);
}

.pendingDetail {
  background: transparent;
  color: rgba(255, 136, 0, 0.65);
  border: 2px solid rgba(255, 136, 0, 0.65);
  padding: 2px 5px 3px;
}

.pendingDetail, .validDetail, .notValidDetail {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}


/*ids*/

#background {
  background-color: transparent;
  border: #b7b7b7 0.1em solid;
  border-radius: 0.4em;
  margin: 15px auto auto;
  max-width: 500px;
  width: 90%;
}

#desc {
  overflow-wrap: break-word;
  padding-bottom: 10px;
  padding-right: 30px;
}

/* detail format */

code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

pre {
  background: #262525;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
  code {
    color: inherit;
    margin-left: 10px;
    background: none;
    font-size: 16px;
  }
}

blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#262626, 0.1);
}

hr {
  border: none;
  border-top: 2px solid rgba(#0D0D0D, 0.1);
  margin: 2rem 2rem 2rem 0;
}
.validate-btn{
  padding-bottom: 20px;
  text-align: left;
}


</style>