export const dateToString = (sDate) => {
    if(!sDate) {
        return '';
    }
    const d = new Date(sDate);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [day, month, year].join('/');
};
const convert = (date, splitter, separator) => {
    if(!date || !date.length) {
        return '';
    }
    const dateSplitted = date.split(splitter);
    return `${dateSplitted[2]}${separator}${dateSplitted[1]}${separator}${dateSplitted[0]}`;
};
export const convertYYYYMMDDToDDMMYYYY = (date) => {
    return convert(date, '-', '/');
};
export const convertDDMMYYYYToYYYYMMDD = (date) => {
    return convert(date, '/', '-');
};

export const  dateStringDDMMYYYYToTimestamp = (dateString) => {

    return  new Date (convertDDMMYYYYToYYYYMMDD(dateString)).getTime()

};

export const dateFromEndpointToDDMMYYYY = (dateString) => {

    return (new Date (dateString)) / 1000;
}

