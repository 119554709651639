import { isEmpty } from '@/AppNT/utils/tableUtils';
import { ticketTypesEnum } from '@/AppNT/enum/TicketTypesEnum';

const LocalStorageService = (function(){

    let _service;

    function _getService() {
        if(!_service) {
          _service = this;
          return _service
        }
      return _service
    }

    function _setToken(tokenObj) {
      localStorage.setItem('access_token', tokenObj.access_token);
      localStorage.setItem('refresh_token', tokenObj.refresh_token);
    }

    function _getAccessToken() {
      return localStorage.getItem('access_token');
    }

    function _getRefreshToken() {
      return localStorage.getItem('refresh_token');
    }

    function _clearToken() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }

    function _setUserData(data) {
      localStorage.setItem('name', data.name);
      localStorage.setItem('email', data.email);
      localStorage.setItem('balance', data.balance);
      localStorage.setItem('roles', data.roles);
    }

    function _clearUserData() {
      localStorage.removeItem('name');
      localStorage.removeItem('balance');
      localStorage.removeItem('roles');
    }

    function _getUserName() {
      return localStorage.getItem('name');
    }

    function _getUserEmail() {
      return localStorage.getItem('email');
    }

    function _getUserBalance() {
      return localStorage.getItem('balance');
    }

    function _setUserBalance(balance) {
      localStorage.setItem('balance', balance);
    }

    function _getUserRoles() {
      return localStorage.getItem('roles');
    }
    
    /* notification info */

    function _setNotificationInfo(notification_info) {
      localStorage.setItem('validated', notification_info.validated);
      localStorage.setItem('notification_text', notification_info.notification_text);
    }

    function _setNotificationText(notification_text) {
      return localStorage.setItem('notification_text', notification_text);
    }

    function _getNotificationText() {
      return localStorage.getItem('notification_text');
    }

    function _setValidated(validated) {
      return localStorage.setItem('validated', validated);
    }

    function _getValidated() {
      return localStorage.getItem('validated');
    }

    function _clearNotificationInfo() {
      localStorage.removeItem('validated');
      localStorage.removeItem('notification_text');
    }

    /* tickets draft */

    function _setTicketsDraft() {
      localStorage.setItem('draft', JSON.stringify({}));
    }

    function _setOneTicketDraft(ticket_data) {

      const ticket_type = Object.keys(ticketTypesEnum).find(key => ticketTypesEnum[key] === ticket_data.ticket_type);

      if (ticketTypesEnum.hasOwnProperty(ticket_type) && !localStorage.getItem('draft').hasOwnProperty(ticket_type)) {

        let tickets_draft = JSON.parse(_getTicketsDraft());

        tickets_draft[ticket_type] = {
          'type': ticket_data.ticket_type,
          'title': ticket_data.ticket_title,
          'description': ticket_data.ticket_description
        }

        localStorage.setItem('draft', JSON.stringify(tickets_draft));
      }
    }

    function _clearTicketsDraft() {
      localStorage.removeItem('draft');
    }

    function _clearOneTicketDraft(type_clear) {
      const clear = JSON.parse(localStorage.getItem('draft'));
      delete clear[type_clear];
      localStorage.setItem('draft', JSON.stringify(clear));
    }

    function _getTicketsDraft() {
      return localStorage.getItem('draft');
    }

    function _getOneTicketDraft(type_get) {

      const ticket_type = JSON.parse(localStorage.getItem('draft'))[type_get];

      if (ticket_type) {
        return ticket_type;
      } else {
        return null;
      }
    }


    /* save filters active */

    function _setActiveFilters(activeFilters) {

      /*localStorage.setItem('status', activeFilters.status);
      localStorage.setItem('author', activeFilters.author);
      localStorage.setItem('company', activeFilters.company);
      localStorage.setItem('department', activeFilters.department);
      localStorage.setItem('type', activeFilters.type);
      localStorage.setItem('created_at', activeFilters.created_at);
      localStorage.setItem('validated_at', activeFilters.validated_at);*/

      if (isEmpty(activeFilters)) {
        localStorage.setItem('activeFilters', null);
      } else {
        localStorage.setItem('activeFilters', JSON.stringify(activeFilters));
      }
    }

    function _getActiveFilters() {

      /*const activeFilters = {
        'status': localStorage.getItem('status'),
        'author': localStorage.getItem('author'),
        'company': localStorage.getItem('company'),
        'department': localStorage.getItem('department'),
        'type': localStorage.getItem('type'),
        'created_at': localStorage.getItem('created_at'),
        'validated_at': localStorage.getItem('validated_at'),
      }*/

      return localStorage.getItem('activeFilters');
    }

    function _clearActiveFilters() {
      localStorage.setItem('activeFilters', null);
    }

   return {
      getService : _getService,
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken,
      setUserData: _setUserData,
      getUserName: _getUserName,
      getUserEmail: _getUserEmail,
      getUserBalance: _getUserBalance,
      setUserBalance: _setUserBalance,
      getUserRoles: _getUserRoles,
      clearUserData: _clearUserData,
      setNotificationInfo: _setNotificationInfo,
      getValidated: _getValidated,
      setValidated: _setValidated,
      getNotificationText: _getNotificationText,
      setNotificationText: _setNotificationText,
      clearNotificationInfo: _clearNotificationInfo,
      getActiveFilters: _getActiveFilters,
      setActiveFilters: _setActiveFilters,
      clearActiveFilters: _clearActiveFilters,
      setTicketsDraft: _setTicketsDraft,
      setOneTicketDraft: _setOneTicketDraft,
      clearTicketsDraft: _clearTicketsDraft,
      clearOneTicketDraft: _clearOneTicketDraft,
      getTicketsDraft: _getTicketsDraft,
      getOneTicketDraft: _getOneTicketDraft
    }

})();

export default LocalStorageService;