<template>
  <div class="containerSelect">
        <div class="sugType">
            <h3 class="is-required">¿Qué tipo de sugerencia vas a enviar?</h3>

            <select class="minimal" v-model="subtype" name="subtype" id="subtype" @change="onSubtypeChange" >
                <option v-for="sub in this.subtypes" v-bind:key="sub">{{sub}}</option>
            </select>
        </div>
  </div>
</template>

<script>

import axios from '../../services/storage/axios'

export default {

    data() {
        return {
            subtype: '',
            subtypes: '',
        }
    },
    emits: ['datachanged'],
    methods: {
        async getSubtypes() {

            const options = {
                method: 'GET',
                url: '/subtypes',
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            axios(options)
            .then((response) => {

                this.subtypes = response.data.subtypes

                this.subtypes.splice(this.subtypes.indexOf('Sugerencia'), 1)
                this.subtypes.splice(0, 0, 'Sugerencia')

                this.subtype = 'Sugerencia'

            }, (error) => {

                if (error.response.status === 401) {

                    this.loaded = false
                    
                }
            })
        },
        onSubtypeChange() {

            let data = {
                subtype: this.subtype
            }

            this.$emit("datachanged", data)
        }

    },
    created() {

        this.getSubtypes();
    }
}
</script>

<style scoped>

    .containerSelect {
        justify-content: center;
        text-align: left;
    }

    .is-required:after {
        content: '*';
        color: #ff0000;
        font-weight: bold;
        margin-left: 3px;
    }

    h3 {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        margin-top: 15px;
    }

    h3.dept{
        margin-top: 2%;
    }

    select.minimal {
        appearance: none;
        background-color: #FFFFFF;
        border-radius: 5px;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        height: 50px;
        margin-bottom: 25px;
        outline: none;
        text-indent: 10px;
        width: 100%;
    }

    select.minimal:hover, input:hover {
        border-color: #ED3F7C;
        cursor: pointer;
    }

    /*minimal*/

    select.minimal {
        background-image:
            linear-gradient(45deg, transparent 50%, #f92871 50%),
            linear-gradient(135deg, #f92871 51%, transparent 50%),
            linear-gradient(to right, #dfdedf, #dfdedf);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    }

    select.minimal:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

</style>