<template>

  <div class="containerThSug">
        <div class="company">
            <h3 class="is-required">¿A qué empresa va dirigido?</h3>

            <select class="minimal" v-model="company" name="company" id="company" @change="onCompanyChange" >
                <option v-for="com in this.companies" v-bind:key="com">{{com}}</option>
            </select>
        </div>
        
        <div class="department">
                <h3 class="dept">¿A qué departamento va dirigido?</h3>

                <select class="minimal" v-model="department" name="department" id="department" @change="onDepartmentChange" >
                    <option v-for="dep in this.departments" v-bind:key="dep">{{dep}}</option>
                </select>
        </div>
  </div>
    
</template>

<script>

import axios from '../../services/storage/axios'

export default {

    data() {
        return {
            company: '',
            companies: '',
            department: '',
            departments: '',
        }
    },
    emits: ['datachanged'],
    methods: {
        async getCompanies() {

            const options = {
                method: 'GET',
                url: '/companies',
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            axios(options)
            .then((response) => {

                this.companies = response.data.companies

            }, (error) => {

                if (error.response.status === 401) {

                    this.loaded = false
                    
                }
            })
        },
        async getDepartments() {

            const options = {
                method: 'GET',
                url: `/departments/${this.company}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            axios(options)
            .then((response) => {

                this.departments = response.data.departments

                // Department Generico first position

                const others = this.departments.splice(this.departments.findIndex(element => element.includes('Otros')), 1)[0]

                this.departments.splice(0, 0, others)

                this.department = others 

                this.onDepartmentChange()

            }, (error) => {

                if (error.response.status === 401) {

                    this.loaded = false
                    
                }
            })

        },
        onCompanyChange() {

            let data = {
                company: this.company
            }

            this.$emit("datachanged", data)

            this.getDepartments();
        },
        onDepartmentChange() {

            let data = {
                department: this.department
            }

            this.$emit("datachanged", data)
        }

    },
    created() {

        this.getCompanies();
    }
}
</script>

<style scoped>

    .containerThSug {
        justify-content: center;
        text-align: left;
    }

    .is-required:after {
        content: '*';
        color: #ff0000;
        font-weight: bold;
        margin-left: 3px;
    }

    h3 {
        color: #444444;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        margin-top: 15px;
    }

    h3.dept{
        margin-top: 2%;
    }

    select {
        appearance: none;
        background-color: #FFFFFF;
        border-radius: 5px;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        height: 50px;
        margin-bottom: 25px;
        outline: none;
        text-indent: 10px;
        width: 100%;
    }

    select:hover, input:hover, textarea:hover {
        border-color: #ED3F7C;
        cursor: pointer;
    }

    /*minimal*/

    select.minimal {
        background-image:
            linear-gradient(45deg, transparent 50%, #f92871 50%),
            linear-gradient(135deg, #f92871 51%, transparent 50%),
            linear-gradient(to right, #dfdedf, #dfdedf);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

</style>